export default {
    "DataGrid": {
        "child": [
            {
                "LogicalEntityOperation": null
            },
            {
                "DataGridColumn": null
            },
            {
                "ButtonPanel": {
                    "child": [
                        {
                            "Button": {
                                "child": [
                                    {
                                        "ActionFlow": {
                                            "child": [
                                                {
                                                    "MicroFlow": null
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "MenuButton": {
                                "child": [
                                    {
                                        "Button":{
                                            "child": [
                                                {
                                                    "ActionFlow": {
                                                        "child": [
                                                            {
                                                                "MicroFlow": null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    }
}