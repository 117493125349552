// eslint-disable-next-line 
import { InfoAuth } from 'info-auth-client';
import React, { useState, useEffect } from 'react';
import { createStorage } from '../storage';
import config from '../config';

export const useFetchInfoAuth = () => {
    let user: any = {}
    const loginStatusInitial = { isAuthenticated: false, loading: true, user: user }
    const [loginStatus, setLoginStatus] = useState(loginStatusInitial);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userDetails = await InfoAuth.currentAuthenticatedUser();
                const currentSession = await InfoAuth.currentSession();
                const storage = createStorage(config);
                storage.setItem('AUTH_TOKEN', currentSession.accessToken.token)
                setLoginStatus({ isAuthenticated: true, loading: false, user: userDetails })
            } catch (e) {
                setLoginStatus({ isAuthenticated: false, loading: false, user: {} })
            }
        };
        fetchData();
    }, []);
    return loginStatus;
}
