import { call, put, takeEvery, all, fork, select, takeLatest } from 'redux-saga/effects';
import {
    GET_SCREEN_DETAILS,
    GO_BACK,
    setScreenDetails,
    CREATE_CONFIG_ITEM,
    setErrorData,
    setToggleMessages,
    setFormDatas,
    GET_CONFIG_DATA_BY_ID,
    GET_CONFIG_DATA_FOR_DELETE_BY_ID,
    setSideTree,
    getScreenDetails,
    GET_DATA_SOURCE_IN_SELECT_OPTION, setDataSourceInSelectOption,
    setFormDataInSelectOption, GET_FORM_DATA_IN_SELECT_OPTION,
    GET_GRID_DATA_IN_SELECT_OPTION, setGridDataInSelectOption,
    removeSideTree,
    removeScreenDetailsHistory,
    GET_GRID_DATA,
    setGridData,
    GET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION,
    setRootCompositeEntityNodeInSelectOption,
    setPortalInSelectOption,
    setPortalCardInSelectOption,
    GET_PORTAL_IN_SELECT_OPTION,
    GET_PORTAL_CARD_IN_SELECT_OPTION,
    GET_COMPOSITE_ENTITY_IN_SELECT_OPTION,
    setCompositeEntityInSelectOption,
    setToastMessage,
    GET_PROJECT_OPTIONS, setProjectOption, GET_USER_PROFILE, setStore,
    DEPLOY_META,
    setConfigReleasesInSelectOption, SWITCH_PROJECT, LOG_OUT
} from '../actions/index';
import { InfoAuth } from 'info-auth-client';
import { mapKeys } from 'lodash';
import { CardType, SECRET_KEY, USER_SESSION_LOCAL_STORAGE_KEY } from '../constants/appeng.enum';
import CardData from '../models/carddata.model';
import * as base from './base';
import { cloneDeep } from 'lodash';
import { createConfigData, getConfigData } from '../utils/ConfigUtils';
import projectJson from "../components/mockData/project.json";
import {
    getPropertyByConfigType, getPropertyByFormFieldType,
    getPropertyByPortalCardType
} from "../utils/PropertyUtils";
import { createStorage } from '../storage';
import * as jwt from 'jsonwebtoken';
import config from '../config';
import { tokenHandler } from "../utils/aws.token.validator";
import { useSelector } from 'react-redux';
import sortableTreeData, { getRelation } from '../config-json/relations';
import { UpsertMessage } from '../constants/appeng.enum';
import userJson from '../assets/user.json';
import rolesJson from '../assets/roles.json';
import { push, replace } from 'connected-react-router';
import ApplicationRole from '../models/applicationRole.model';

const uuid = require('uuid/v4');
const getCard = (state) => state.cardsData;
const getScreenDetailsData = (state) => state.screenDetails;
const getTreeData = (state) => state.sideNodeTree.treeData;
const getUserDetails = (state) => state.userDetails;
const getProjectId = (state) => state.projectId;
const getOptionDetails = (state) => state.selectOptionDetails;
const getApplications = state => state.applications;
// Register all your watchers
export default function* rootSaga() {
    yield all([
        fork(watchGetScreenData),
        fork(watchCreateConfigItem),
        fork(watchGoBack),
        fork(watchGetConfigDataById),
        fork(watchDeleteConfigItemById),
        fork(watchGetGridData),
        fork(watchGetCompositeEntityInSelectOption),
        fork(watchGetDataSourceInSelectOption),
        fork(watchGetPortalInSelectOption),
        fork(watchGetFormDataInSelectOption),
        fork(watchGetGridDataInSelectOption),
        fork(watchGetPortalCardInSelectOption),
        fork(watchGetRootCompositeEntityNodeInSelectOption),
        fork(watchGetProjectOption),
        fork(watchGetUserProfile),
        fork(watchDeployMeta),
        fork(watchSwitchProjectSaga),
        fork(watchLogoutSaga)
    ])
}

function* getUserProfile(action) {
    try {
        yield put(setToastMessage([]));
        const storage = createStorage(config);
        let userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
        let userSession: any = {};
        const authProvider: string = JSON.parse(process.env.AUTH_DETAILS!).provider.type;
        let loggedInUserId = "";
        let appId = "";
        let currentApplicationAD = "";
        const userDetailsMap1 = JSON.parse(userProfile ? userProfile : "{}");
        const isUserLoggedIn = userDetailsMap1 && userDetailsMap1.APP_LOGGED_IN_ROLE_ID && userDetailsMap1.AdditionalDetails && userDetailsMap1.AdditionalDetails.UserContext && userDetailsMap1.AdditionalDetails.UserContext.APP_LOGGED_IN_ROLE_ID ? true : false;

        switch (authProvider) {
            case 'aws': {
                loggedInUserId = action.user.userInfo.oid;
                break;
            }
            case 'okta': {
                const userInfo = yield call(base.getOktaUserInfo);
                Object.assign(userSession, userInfo);
                loggedInUserId = action.user.userInfo.oid;
                break;
            }
            case 'microsoft-ad': {
                loggedInUserId = action.user.userInfo.oid;
                currentApplicationAD = action.user.userInfo.customAttributes.appId;
            }
                break;
            case 'microsoft-ad-info-apps':
                loggedInUserId = action.user.userInfo.oid;
                const routerLocation: any = window.location.search;
                if (routerLocation && routerLocation.includes("&")) {
                    let searchURL = routerLocation.replace("?", "")
                    let pathList = searchURL.split('&');
                    pathList.map((pathOptions) => {
                        let path = pathOptions.split("=");
                        if (path[0] && path[0] === "TENANT_UUID" && !userSession.TENANT_UUID && !appId) {
                            userSession.TENANT_UUID = path[1].replace("#");
                        } else if (path[0] && path[0] === "APPLICATION_UUID" && userSession.TENANT_UUID && !appId) {
                            appId = path[1].replace("#");
                        }
                    })
                }
                break;
        }
        const projectList = yield call(getProject, authProvider, userSession.aeRoleApplication, loggedInUserId, currentApplicationAD);
        const authRole = userSession.groups ? userSession.groups : action.user
            ? action.user.userInfo.role : undefined;
        const roleList: any = yield call(getRole, authProvider, loggedInUserId, authRole);
        const allReleaseVersions = yield call(base.getConfigRelease);
        const options = yield select(getOptionDetails);
        const updatedOptions = { ...options }
       
        if (!userSession && Object.keys(userSession).length === 0 || !(userDetailsMap1 && userDetailsMap1.APP_LOGGED_IN_ROLE_ID && userDetailsMap1.AdditionalDetails.APP_LOGGED_IN_ROLE_ID)) {
            if (!appId) {
                appId = getAppId(action.user, authProvider);
            }
            const defaultProject = yield call(getDefaultProject, authProvider,
                userSession.aeRoleApplication, userSession.tenantId, loggedInUserId, roleList, appId, projectList);

            const userDetails = {};
            userDetails['APP_LOGGED_IN_PROFILE_STATUS'] = action.user ? action.user.userInfo.profile : action.userStatus;

            userSession.AdditionalDetails = {};

            setDefaultProjectAndRole(defaultProject, roleList,
                userDetails, userSession, projectList, action.user, loggedInUserId);
            let activeRelease = getActiveReleaseName(allReleaseVersions, defaultProject.applicationId);
            setUserNameAfterLogin(authProvider, userSession, action, defaultProject, userDetails, storage, isUserLoggedIn, activeRelease, roleList);

            userProfile = JSON.stringify(userSession);
            storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, userProfile);
        } else {
            let userDetails = action.user ? action.user.userInfo : {};
            //console.log('else part entered')
            if (!Object.keys(action.user)) {
                //console.log('else inner if')
                userDetails = JSON.parse(JSON.stringify(userJson))
            } else {
                //console.log('else inner else')
                userDetails['APP_LOGGED_IN_PROFILE_STATUS'] = action.user ? action.user.userInfo.profile : action.userStatus;
                // userDetailsMap1.AdditionalDetails['APP_LOGGED_IN_CURRENT_RELEASE'] = activeRelease;
                //if (action.user.userInfo.profile !== userDetailsMap1.AdditionalDetails.APP_LOGGED_IN_PROFILE_STATUS) {
                const currentProjectMap = projectList.filter(project => project.applicationId === userDetailsMap1.APP_LOGGED_IN_PROJECT_ID);
                appId = appId ? appId : currentProjectMap[0]["aeApplicationUuid"];
                const defaultProject = yield call(getDefaultProject, authProvider, userSession.aeRoleApplication, userSession.tenantId, loggedInUserId, roleList, appId, projectList);

                userSession = cloneDeep(userDetailsMap1);
                setDefaultProjectAndRole(defaultProject, roleList,
                    userDetails, userSession, projectList, action.user, loggedInUserId);
                userSession.AdditionalDetails.APP_LOGGED_IN_PROFILE_STATUS = action.user ? action.user.userInfo.profile : action.userStatus;
                let activeRelease = getActiveReleaseName(allReleaseVersions, userSession.APP_LOGGED_IN_PROJECT_ID);
                setUserNameAfterLogin(authProvider, userSession, action, defaultProject, userDetails, storage, isUserLoggedIn, activeRelease, roleList);
                userProfile = JSON.stringify(userSession);
                storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, userProfile);
                // }
            }

        }

        let applicationId: any = getApplicationId(userSession, projectList);
        let applicationRoleList: any = yield call(getRoleViaApplicationId,
           JSON.parse(process.env.AUTH_DETAILS!).provider.type, applicationId);

        let parsedProjectList = projectParser(projectList);
        //console.log('userDetailsMap1...', storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY))
        const updateStore: any = {
            currentProject: userSession && Object.keys(userSession).length > 0 ? userSession["APP_LOGGED_IN_PROJECT_NAME"] ? userSession["APP_LOGGED_IN_PROJECT_NAME"] : "Application not assigned" : projectList.length > 0 ? projectList[0].applicationName : "Application not assigned",
            userDetails: userProfile,
            applications: parsedProjectList,
            currentApplicationRoleList: applicationRoleList
        }
        yield put(setStore(updateStore));
        let roleIdArray = userSession.AdditionalDetails["APP_LOGGED_IN_ROLE_ID"].split(',');
        if ((projectList.length === 0 && roleList.length === 0)
            || (userSession.AdditionalDetails && (!roleIdArray.includes('2') && !roleIdArray.includes('16')))) {
            const messages: string[] = ["Application or Role not assigned"];
            yield put(setToastMessage(messages));
        }
    } catch (e) {
        console.log('exception getUserProfile', e);
        const messages: string[] = [];
        messages.push(UpsertMessage.UNSUCCESSFUL);
        yield put(setToastMessage(messages));
    }

}

const getActiveReleaseName = (allReleaseVersions, projectId) => {
    let activeRelease;
    if (allReleaseVersions && allReleaseVersions.data && allReleaseVersions.data.ConfigReleases.length) {
        //updatedOptions.configReleases = allReleaseVersions.data.ConfigReleases;
        allReleaseVersions.data.ConfigReleases.map(release => {
            if (release.status === 'Active' && release.projectId == projectId) {
                activeRelease = release.configReleaseName;
            }
        })
    }
    return activeRelease;
}

const setUserNameAfterLogin = (authProvider, userSession, userProfile, defaultProject, userDetails, storage, isUserLoggedIn, activeRelease, roleList) => {
    let userName = "";
    switch (authProvider) {
        case 'okta': {
            userName = userSession.name;
            break;
        }
        case 'microsoft-ad-info-apps':
        case 'microsoft-ad': {
            userName = userProfile.user.userInfo.name;
            break;
        }
        default: {
            userName = defaultProject.firstName + ' ' + defaultProject.lastName
        }
    }
    userDetails['APP_LOGGED_IN_USER_NAME'] = userName;

    userSession.AdditionalDetails['APP_LOGGED_IN_PROFILE_STATUS'] = userProfile.user ? userProfile.user.userInfo.profile : userProfile.userStatus;
    userSession.AdditionalDetails['APP_LOGGED_IN_CURRENT_RELEASE'] = activeRelease;
    userSession.TENANT_UUID = 'okta' === authProvider ? userSession.tenantId : roleList[0].tenantId;

    Object.entries(userDetails).forEach(([key, value]) => {
        if (key.includes("APP_LOGGED_IN")) {
            userSession.AdditionalDetails[key] = value;
        }
    })
    storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(userSession));
}

const getAppId = (user, authProvider) => {
    switch (authProvider) {
        case 'okta': {
            return undefined;
        }
        case 'microsoft-ad': {
            return user.userInfo.customAttributes.appId;
        }
        case 'microsoft-ad-info-apps': {
            return undefined;
        }
        default: {
            return undefined
        }
    }
}

function* getRole(provider: string, userId: string, groups?: any) {
    let roleList: any = [];
    switch (provider) {
        case 'okta': {
            const allRoles = yield call(base.getAllRoleOption);
            allRoles.map(role => {
                if (groups.includes(role.roleName)) {
                    roleList.push(role);
                }
            });
            break;
        }
        case 'microsoft-ad': {
            const { data } = yield call(base.getConfigData, 'RoleQuery', "");
            const allRoles = data.Roles;
            allRoles.map(role => {
                if (role.roleName && (groups.includes(role.roleName.replace(/ +/g, "")) || groups.includes(role.aeRoleUuid))) {
                    roleList.push(role);
                }
            });
            break;
        }
        case 'microsoft-ad-info-apps': {
            roleList = yield call(base.getRoleOption, userId);
            break;
        }
        default: {
            roleList = yield call(base.getRoleOption, userId);
        }
    }
    return roleList;
}

function* getRoleViaApplicationId(provider: string, appId: string) {
    let roleList: any = [];
    roleList = yield call(base.getRoleOptionViaApplicationId, appId);
    return roleList;
}


const getApplicationId = (apploggedInProjectId: any, projectList: any) => {
    let applicationUuid;
    for (let list of projectList) {
        if (apploggedInProjectId && apploggedInProjectId.APP_LOGGED_IN_PROJECT_ID === list.applicationId) {
            applicationUuid = list.aeApplicationUuid
        }
    }
    return applicationUuid;
}


function* getProject(provider: string, aeRoleApplication: any, userId: string, appId?: string) {
    let projectList: any = [];
    switch (provider) {
        case 'okta': {
            let aeapplicationsList: any = [];
            for (let i = 0; i < aeRoleApplication.length; i++) {
                let aeapp: any = aeRoleApplication[i].split(":");
                aeapplicationsList.push(aeapp[1]);
            }
            const allApplications = yield call(base.getAllApplications);
            allApplications.map(application => {
                if (aeapplicationsList.includes(application.aeApplicationUuid)) {
                    projectList.push(application);
                }
            });
            break;
        }
        case 'microsoft-ad': {
            const responser = yield call(base.applicationRoleMicrosoftAd);
            const { data } = yield call(base.getConfigData, 'ApplicationQuery', "");
            data.Applications.map(application => {
                const currentAplication = responser.value.filter(applicationRole => (applicationRole.resourceId === application.authAppId && application.aeApplicationUuid === appId));
                if (currentAplication[0]) {
                    projectList.push(application)
                }
            });
            break;
        } case 'microsoft-ad-info-apps': {
            projectList = yield call(base.getProjectOption, userId);
            break;
        }
        default: {
            projectList = yield call(base.getProjectOption, userId);
        }
    }
    return projectList;
}

function* getDefaultProject(provider: string, aeRoleApplication: any, tenantId: string, userId: string, roleList: any, projectId?: string, projectList?: any) {
    let project: any = {};
    switch (provider) {
        case 'okta': {
            let aeapplicationsList: any = [];
            let aeRoleList: any = [];
            for (let i = 0; i < aeRoleApplication.length; i++) {
                let aeapp: any = aeRoleApplication[i].split(":");
                if (projectId && projectId === aeapp[1]) {
                    aeRoleList.push(aeapp[0]);
                    aeapplicationsList.push(aeapp[1]);
                } else if (projectId === undefined) {
                    aeRoleList.push(aeapp[0]);
                    aeapplicationsList.push(aeapp[1]);
                }
            }
            let roleId = "";
            roleList.map(role => {
                if (aeRoleList[0] == role.roleName) {
                    roleId = role.aeRoleUuid;
                }
            });
            project.aeApplicationUuid = aeapplicationsList[0];
            project.aeInsertId = null;
            project.aeInsertTs = null;
            project.aeRoleUuid = roleId;
            project.aeTransactionId = null;
            project.aeUpdateId = null;
            project.aeUpdateTs = null;
            project.aeUserAuthUuid = userId;
            project.aeUserMRoleUuid = "094f3437-c74a-11ea-994f-12eaac2dc7ag";
            project.isDefault = 1;
            project.isdeleted = 0;
            project.tenantId = tenantId;
        }
        case 'microsoft-ad': {
            if (projectId) {
                const currentAplication = projectList.filter(application => application.aeApplicationUuid === projectId);
                project = new ApplicationRole(currentAplication[0].authAppId, currentAplication[0].aeInsertId, currentAplication[0].aeInsertTs, roleList[0].aeRoleUuid, currentAplication[0].aeTransactionId, currentAplication[0].aeUpdateId, currentAplication[0].aeUpdateTs, userId, roleList[0].aeRoleUuid, 1, 0, currentAplication[0].tenantId);
            }
            break;
        } case 'microsoft-ad-info-apps': {
            project = yield call(base.getDefaultProjectAndRoleFromUserId, userId, projectId);
            break;
        }
        default: {
            project = yield call(base.getDefaultProjectAndRoleFromUserId, userId, projectId);
        }
    }
    return project;
}

function* switchProjectSaga(action) {
    try {
        window.history.replaceState(null, "", window.location.pathname);
        const storage = createStorage(config);
        let userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
        const projectList = yield select(getApplications);
        const userDetailsMap1 = JSON.parse(userProfile ? userProfile : "{}");
        let userSession = cloneDeep(userDetailsMap1);
        let roleList: any = yield call(getRole,
            JSON.parse(process.env.AUTH_DETAILS!).provider.type, userDetailsMap1.APP_LOGGED_IN_USER_ID, userSession.groups);

        const defaultProject = yield call(getDefaultProject,JSON.parse(process.env.AUTH_DETAILS!).provider.type, userSession.aeRoleApplication, userSession.tenantId, userDetailsMap1.APP_LOGGED_IN_USER_ID, roleList, action.projectId, projectList);

        setDefaultProjectAndRole(defaultProject, roleList,
            userDetailsMap1, userSession, projectList, action.user, userDetailsMap1.APP_LOGGED_IN_USER_ID);
        userProfile = JSON.stringify(userSession);
        storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, userProfile);
        const messages: string[] = [];
        yield put(setToastMessage(messages));
        const updateStore: any = {
            currentProject: Object.keys(userDetailsMap1).length > 0 ? userDetailsMap1["APP_LOGGED_IN_PROJECT_NAME"] : projectList[0].applicationName,
            userDetails: userProfile,
            toggleMessages: messages
        }
        yield put(setStore(updateStore));
        yield put(push('/'))
        window.location.reload();
    } catch {
        const messages: string[] = [];
        messages.push(UpsertMessage.UNSUCCESSFUL);
        yield put(setToastMessage(messages));
    }

}
function* logoutSaga(action) {
    const messages: string[] = [];
    yield put(setToastMessage(messages));
    switch (action.url) {
        case '/logout': {
            const storage = createStorage(config);
            storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, "");
            action.history.push({
                pathname: '/logout'
            });
            break;
        }
        case '/index.html': {
            window.open(action.url, "_self");
            break;
        }

    }

}

const setDefaultProjectAndRole = (defaultProject, roleList, userDetails,
    userSession, projectList, user, userId) => {
    if (Object.keys(defaultProject).length) {
        const currentRoleMap = roleList.filter(role => role.aeRoleUuid === defaultProject.aeRoleUuid);
        const currentProjectMap = projectList.filter(project => project.aeApplicationUuid === defaultProject.aeApplicationUuid);

        let mappedRoleList = defaultProject.aeRoleUuid.split(",");
        let extractedRoleName = "";
        let extractedRoleId = "";
        roleList.map((role) => {
            if (mappedRoleList.includes(role.aeRoleUuid) && !extractedRoleName.includes(role.roleName)) {
                extractedRoleName = extractedRoleName + role.roleName + ",";
                extractedRoleId = extractedRoleId + role.roleId + ",";
            }
        });
        extractedRoleName = extractedRoleName.slice(0, -1);
        extractedRoleId = extractedRoleId.slice(0, -1);

        if (extractedRoleName) {
            userDetails['APP_LOGGED_IN_ROLENAME'] = extractedRoleName;
            userSession.AdditionalDetails.APP_LOGGED_IN_ROLENAME = extractedRoleName;
            userSession.AdditionalDetails.APP_LOGGED_IN_ROLE_ID = extractedRoleId;
            userSession.APP_LOGGED_IN_ROLE_ID = extractedRoleId;
        } else if (roleList.length) {
            userDetails['APP_LOGGED_IN_ROLENAME'] = roleList[0].roleName
            userSession.AdditionalDetails.APP_LOGGED_IN_ROLE_ID = roleList[0].roleId ?
                roleList[0].roleId : roleList[0].aeRoleUuid;
            userSession.APP_LOGGED_IN_ROLE_ID = roleList[0].roleId ?
                roleList[0].roleId : roleList[0].aeRoleUuid;
        }
        if (currentProjectMap.length) {
            userSession.AdditionalDetails.APP_LOGGED_IN_PROJECT_ID = currentProjectMap[0].hasOwnProperty('applicationId') ? currentProjectMap[0].applicationId : currentProjectMap[0].id;
            userSession.APP_LOGGED_IN_PROJECT_ID = currentProjectMap[0].hasOwnProperty('applicationId') ? currentProjectMap[0].applicationId : currentProjectMap[0].id;
            userSession.APP_LOGGED_IN_PROJECT_NAME = currentProjectMap[0].applicationName ? currentProjectMap[0].applicationName : currentProjectMap[0].name;
            userSession.AdditionalDetails['CURRENT_PROJECT'] = currentProjectMap[0].hasOwnProperty('applicationId') ? currentProjectMap[0].applicationId : currentProjectMap[0].id;
        } else if (projectList.length) {
            userSession.AdditionalDetails.APP_LOGGED_IN_PROJECT_ID = projectList[0].hasOwnProperty('applicationId') ? projectList[0].applicationId : projectList[0].id;
            userSession.APP_LOGGED_IN_PROJECT_ID = projectList[0].hasOwnProperty('applicationId') ? projectList[0].applicationId : projectList[0].id;
            userSession.APP_LOGGED_IN_PROJECT_NAME = projectList[0].applicationName ? projectList[0].applicationName : projectList[0].name;
            userSession.AdditionalDetails['CURRENT_PROJECT'] = projectList[0].hasOwnProperty('applicationId') ? projectList[0].applicationId : projectList[0].id;
        }
        const authProvider: string = JSON.parse(process.env.AUTH_DETAILS!).provider.type;
        userSession.APP_LOGGED_IN_USER_ID = userId;
    }
}
const projectParser = (projectList) => {
    let applications: any = [];
    projectList.map(project => {
        let applicationMap = {};
        applicationMap["code"] = project.applicationName;
        applicationMap["name"] = project.applicationName;
        applicationMap["id"] = project.applicationId;
        applicationMap["aeApplicationUuid"] = project.aeApplicationUuid;
        applications.push(applicationMap);
    })
    return applications;
}

function* getProjectOption(action) {
    const projectOptions = projectJson;
    yield put(setProjectOption(projectOptions));

}
function* getCompositeEntityInSelectOption(action) {
    let projectId = yield select(getProjectId);
    const composite = yield call(base.getConfigDataByType, "CompositeEntity", projectId);
    const optionData = parseOptionData(composite.data.ConfigItemByType);
    yield put(setCompositeEntityInSelectOption(optionData));
}
function* getDataSourceInSelectOption(action) {
    let projectId = yield select(getProjectId);
    const dataSource = yield call(base.getConfigDataByType, "DataSource", projectId);
    const optionData = parseOptionData(dataSource.data.ConfigItemByType);
    yield put(setDataSourceInSelectOption(optionData));
}
function* getPortalInSelectOption(action) {
    let projectId = yield select(getProjectId);
    const portal = yield call(base.getConfigDataByType, "Portal", projectId);
    const optionData = parseOptionData(portal.data.ConfigItemByType);
    yield put(setPortalInSelectOption(optionData));
}
function* getPortalCardInSelectOption(action) {
    let projectId = yield select(getProjectId);
    const portal = yield call(base.getConfigDataByType, "PortalCard", projectId);
    const optionData = parseOptionData(portal.data.ConfigItemByType);
    yield put(setPortalCardInSelectOption(optionData));
}
function* getRootCompositeEntityNodeInSelectOption(action) {
    let projectId = yield select(getProjectId);
    const compositeNode = yield call(base.getConfigDataByType, "RootCompositeEntityNode", projectId);
    const optionData = parseOptionData(compositeNode.data.ConfigItemByType);
    yield put(setRootCompositeEntityNodeInSelectOption(optionData));
}
function* getFormDataInSelectOption(action) {
    let projectId = yield select(getProjectId);
    const form = yield call(base.getConfigDataByType, "Form", projectId);
    const optionData = parseOptionData(form.data.ConfigItemByType);
    yield put(setFormDataInSelectOption(optionData));
}
function* getGridDataInSelectOption(action) {
    let projectId = yield select(getProjectId);
    const grid = yield call(base.getConfigDataByType, "DataGrid", projectId);
    const optionData = parseOptionData(grid.data.ConfigItemByType);
    yield put(setGridDataInSelectOption(optionData));
}
function parseOptionData(optionData: any) {
    var optionList: any = [];
    optionList.push({ label: "-select-", value: "-select-" })
    if (optionData) {
        optionData.map((item) => {
            var data: any = {};
            data['label'] = item.name;
            data['value'] = item.configObjectId;
            data['id'] = item.configObjectId;
            optionList.push(data);
        })
    }
    return optionList;
}
function* getScreenData(action) {
    try {
        switch (action.screenDetails.screenType) {
            case 'ParentForm':
                if (action.screenDetails.configItemId) {
                    let tree = getRelation(action.screenDetails.configType);
                    if (tree) {
                        let card = yield select(getCard);
                        let title = card.data.item ? card.data.item.name : "";
                        let data: any = yield call(base.getProcessedTreeData,
                            tree, title, action.screenDetails.configItemId);
                        let clonedTree = { ...sortableTreeData };
                        clonedTree.returnData.sortableTreeData = data[0];
                        clonedTree.returnData.LevelCount = data[1];
                        yield put(setSideTree(clonedTree));
                    } else {
                        let empty = {};
                        yield put(removeSideTree(empty));
                    }
                }
                break;

            case 'ParentDataGrid':
                let empty = {};
                yield put(removeSideTree(empty));
                break;

            case 'ChildForm':
                let card = yield select(getCard);
                let treeDetails = yield select(getTreeData);
                let clonedTreeDetails = JSON.parse(JSON.stringify(treeDetails));
                let data: any = yield call(base.expandTree,
                    clonedTreeDetails.returnData ? clonedTreeDetails.returnData.sortableTreeData : {},
                    action.screenDetails.configType, card.data.item ? card.data.item.name : "",
                    action.screenDetails.configItemId, action.screenDetails.treeNodeUuid);
                clonedTreeDetails.returnData.sortableTreeData = data[0];
                yield put(setSideTree(clonedTreeDetails));
                break;

            default:
        }
        yield put(setScreenDetails(action.screenDetails, action.historyEnable));
    } catch (e) {
        throw e;
    }
}
function* goBack(action) {
    let len = action.screenDetailsHistory.length
    let screenDtls: any = { ...action.screenDetailsHistory[len - 1] }
    if (screenDtls.screenType === 'ParentDataGrid') {
        let empty = {}
        yield put(removeSideTree(empty));
    }
    action.screenDetailsHistory.splice(len - 1);
    yield put(removeScreenDetailsHistory(action.screenDetailsHistory));
    yield put(setScreenDetails(screenDtls, false));
}
function processToCheckRequiredFieldExist(cardDatas: any, configType: string) {
    let errorDataMap: any = {}
    let cardsData = cardDatas
    if (cardDatas.data.item["name"] === undefined || cardDatas.data.item["name"] === null ||
        cardDatas.data.item["name"] === "") {
        errorDataMap["name"] = "Is Required";
    }
    let property: any = [];
    if (configType !== "FormField") {
        property = getPropertyByConfigType(configType);
    } else {
        if (cardDatas.data.property["type"] !== undefined) {
            property = getPropertyByFormFieldType(cardDatas.data.property["type"]);
        }
        else {
            let typeProperty: any = cardDatas.data.property
            Object.values(typeProperty).map((prop: any) => {
                if (prop.isMandatory) {
                    if ((cardDatas.data.property[prop.dbCode] === undefined ||
                        cardDatas.data.property[prop.dbCode] === null ||
                        cardDatas.data.property[prop.dbCode] === "")) {
                        errorDataMap[prop.dbCode] = "Is Required"
                    }
                }
            });
        }

    }

    property.map((prop: any) => {

        if (prop.isMandatory) {
            if ((cardDatas.data.property[prop.dbCode] === undefined ||
                cardDatas.data.property[prop.dbCode] === null ||
                cardDatas.data.property[prop.dbCode] === "") && (prop.defaultValue === "" || prop.defaultValue === null || prop.defaultValue === "-select-")) {
                errorDataMap[prop.dbCode] = "Is Required"
            } else if ((cardDatas.data.property[prop.dbCode] === undefined ||
                cardDatas.data.property[prop.dbCode] === null ||
                cardDatas.data.property[prop.dbCode] === "") && prop.defaultValue !== "" && prop.defaultValue !== null && prop.defaultValue !== "-select-") {
                cardsData.data.property[prop.dbCode] = prop.defaultValue;
            }
        }
        if (prop.dataType === "number" && cardDatas.data.property[prop.dbCode] !== undefined &&
            cardDatas.data.property[prop.dbCode] !== null && isNaN(Number(cardDatas.data.property[prop.dbCode]))) {
            errorDataMap[prop.dbCode] = "Is Number Only"
        }
    })

    let errorData = {}
    if (Object.keys(errorDataMap).length > 0) {
        errorData = {
            ...errorDataMap
        }
    }
    return { errorData, cardsData };
}
function* getConfigDataByIdSaga(action) {
    let cardData: any = {};
    let projectId = yield select(getProjectId);
    const { data } = yield call(base.getConfigData, action.screenDetails.configType + "Query", action.configItemId);
    cardData = parseConfigData(data, action);
    yield put(setFormDatas(cardData, "test555"));
    yield put(getScreenDetails(action.screenDetails, true));

}

function* deleteConfigItemById(action) {
    let cardData: any = {};
    let userDetails = yield select(getUserDetails);
    const projectId = JSON.parse(userDetails)["APP_LOGGED_IN_PROJECT_ID"];
    const { data } = yield call(base.getConfigData, action.screenDetails.configType + "Query", action.configItemId);
    cardData = parseConfigData(data, action);
    if (cardData.data.childRelation.length > 0) {
        alert("It has childs...First delete its child..")
    } else {
        let itemType = parseConfigItemForDelete(cardData);
        let privileges = parsePrivilegesForDelete(cardData);
        let properties = parsePropertyDataForDelete(cardData, action.screenDetails.configType);
        let parentRelation = parseParentRelationsForDelete(data[action.screenDetails.configType].parentRelations);
        let updatedData = parseFormDataToConfigObjectForDelete(cardData, properties, privileges, action, parentRelation, projectId)
        const result = yield call(createConfigData, action.screenDetails.configType + "Mutation", updatedData);
    }
}
function parseParentRelationsForDelete(parentRelations) {
    let parentRelationList: any = [];
    if (parentRelations) {
        parentRelationList = parentRelations.map(parentRelation => {
            let parentRelationMap: any = {};
            parentRelationMap["relationType"] = parentRelation.relationType;
            parentRelationMap["parentItemId"] = parentRelation.parentItemId;
            parentRelationMap["isDeleted"] = 1;
            return parentRelationMap;
        })
    }
    return parentRelationList;
}
function parsePropertyDataForDelete(cardDatas: any, configType) {
    let propertyMap: any = {}
    let property: any = getPropertyByConfigType(configType);
    property.map((prop: any) => {
        switch (prop.dataType) {
            case "string":
                let data = typeof cardDatas.data.property[prop.dbCode] === 'string' || cardDatas.data.property[prop.dbCode] instanceof String ?
                    cardDatas.data.property[prop.dbCode] : JSON.stringify(cardDatas.data.property[prop.dbCode]);
                propertyMap[prop.dbCode] = data;
                propertyMap['isDeleted'] = 1;
                break;
            case "boolean":
                propertyMap[prop.dbCode] = cardDatas.data.property[prop.dbCode] == "Yes" ? true : false;
                propertyMap['isDeleted'] = 1;
                break;
            case "number":
                propertyMap[prop.dbCode] = cardDatas.data.property[prop.dbCode] === "" ? null : parseInt(cardDatas.data.property[prop.dbCode]);
                propertyMap['isDeleted'] = 1;
                break;
        }
    });
    return propertyMap;
}
function parseFormDataToConfigObjectForDelete(cardDatas: any, propertyMap: any, privileges: { roleId: number; privilegeType: any; isDeleted: number; }[], action: any, parentRelation: any, projectId: any) {
    return {
        ...cardDatas.data.item, ...propertyMap,
        privileges: privileges, parentRelations: parentRelation,
        childRelations: cardDatas.data.childRelation,
        configObjectType: action.screenDetails.configType, projectId: projectId, configObjectId: action.configItemId
    };
}
function parseConfigItemForDelete(cardDatas: any) {
    return { ...cardDatas.data.item, isDeleted: 1 }
}
function parsePrivilegesForDelete(cardDatas: any) {
    return Object.keys(cardDatas.data.privilege).map(key => {
        return { roleId: parseInt(key), privilegeType: cardDatas.data.privilege[key], isDeleted: 1 };
    });
}
function parseConfigData(data: any, action: any) {
    let item = parseFormDataToItem(data[action.screenDetails.configType]);
    let property = {};
    if (action.screenDetails.configType === "FormField") {
        property = parseFormFieldConfigPropertyToFormData(data[action.screenDetails.configType]);
    } else {
        property = parseConfigPropertyToFormData(data[action.screenDetails.configType], action.screenDetails.configType);
        if (action.screenDetails.configType === "PortalCard") {
            const otherProperty = parsePortalCardConfigPropertyToFormData(data[action.screenDetails.configType]);
            Object.assign(property, otherProperty);
        }
        if (action.screenDetails.configType === "Menu") {
            const otherPropertyMap = parseMenuConfigPropertyToFormData(data[action.screenDetails.configType]);
            Object.assign(property, otherPropertyMap)
        }
    }
    let privilege: any = parseFormDataToPrivilege(data[action.screenDetails.configType].privileges);
    let childRelations = parseFormDataToChildRelations(data[action.screenDetails.configType].childRelations);
    let parentRelations = parseFormDataToParentRelations(data[action.screenDetails.configType].parentRelations);
    let formData: any = {
        item: { ...item }, property: { ...property }, privilege: [...privilege],
        parentRelation: [...parentRelations], childRelation: [...childRelations]
    };
    const cardData = new CardData("test555", CardType.FORM, { ...formData }, {});
    return cardData;
}
function parseFormDataToChildRelations(childRelations) {
    let childRelationList: any = [];
    if (childRelations) {
        childRelationList = childRelations.map(childRelation => {
            let childRelationMap: any = {};
            childRelationMap["relationType"] = childRelation.relationType;
            childRelationMap["childItemId"] = childRelation.childItemId;
            childRelationMap["createdBy"] = childRelation.createdBy;
            childRelationMap["insert_ts"] = childRelation.insert_ts;
            return childRelationMap;
        })
    }

    return childRelationList;
}
function parseFormDataToParentRelations(parentRelations) {
    let parentRelationList: any = [];
    if (parentRelations) {
        parentRelationList = parentRelations.map(parentRelation => {
            let parentRelationMap: any = {};
            parentRelationMap["relationType"] = parentRelation.relationType;
            parentRelationMap["parentItemId"] = parentRelation.parentItemId;
            parentRelationMap["createdBy"] = parentRelation.createdBy;
            parentRelationMap["insert_ts"] = parentRelation.insert_ts;
            return parentRelationMap;
        })
    }
    return parentRelationList;
}
function parseFormDataToPrivilege(privileges) {
    const privList: any[] = [];
    // privileges.map(priv => {
    //     let privMap: any = {
    //         [priv.roleId]: priv.privilegeType,
    //         "createdBy": priv.createdBy,
    //         "updatedBy": priv.updatedBy,
    //         "insert_ts": priv.insert_ts,
    //         "update_ts": priv.update_ts
    //     };
    //     privList.push(privMap)
    // })
    return privileges;

}
function parseMenuConfigPropertyToFormData(configData) {
    let propertyMap: any = {}
    propertyMap["hrefBaseUrl"] = configData.hrefBaseUrl;
    return propertyMap;
}
function parsePortalCardConfigPropertyToFormData(configData) {
    let propertyMap: any = {}
    let property: any = getPropertyByPortalCardType(configData.type);
    propertyMap["type"] = configData.type;
    property.map((prop: any) => {
        switch (prop.dataType) {
            case "string":
                let data = typeof configData[prop.dbCode] === 'string' || configData[prop.dbCode] instanceof String ?
                    configData[prop.dbCode] : JSON.stringify(configData[prop.dbCode]);
                propertyMap[prop.dbCode] = data === "null" ? "" : data;
                break;
            case "boolean":
                propertyMap[prop.dbCode] = configData[prop.dbCode] === true ? "Yes" : "No";
                break;
            case "number":
                let updatedData = JSON.stringify(configData[prop.dbCode]);
                propertyMap[prop.dbCode] = updatedData === "null" ? "" : updatedData === "0" ? 0 : updatedData;
                break;
        }
    });
    return propertyMap;
}
function parseFormFieldConfigPropertyToFormData(configData) {
    let propertyMap: any = {}
    let property: any = getPropertyByFormFieldType(configData.type);
    propertyMap["type"] = configData.type;
    property.map((prop: any) => {
        switch (prop.dataType) {
            case "string":
                let data = typeof configData[prop.dbCode] === 'string' || configData[prop.dbCode] instanceof String ?
                    configData[prop.dbCode] : JSON.stringify(configData[prop.dbCode]);
                propertyMap[prop.dbCode] = data === "null" ? "" : data;
                break;
            case "boolean":
                propertyMap[prop.dbCode] = configData[prop.dbCode] === true ? "Yes" : "No";
                break;
            case "number":
                let updatedData = JSON.stringify(configData[prop.dbCode]);
                propertyMap[prop.dbCode] = updatedData === "null" ? "" : updatedData === "0" ? 0 : updatedData;
                break;
        }
    });
    return propertyMap;
}
function parseConfigPropertyToFormData(configData, configType) {
    let propertyMap: any = {}
    let property: any = getPropertyByConfigType(configType);
    if (property) {
        property.map((prop: any) => {
            switch (prop.dataType) {
                case "string":
                    let data = typeof configData[prop.dbCode] === 'string' || configData[prop.dbCode] instanceof String ?
                        configData[prop.dbCode] : JSON.stringify(configData[prop.dbCode]);
                    propertyMap[prop.dbCode] = data === "null" ? "" : data;
                    break;
                case "boolean":
                    propertyMap[prop.dbCode] = configData[prop.dbCode] === true ? "Yes" : "No";
                    break;
                case "number":
                    let updatedData = JSON.stringify(configData[prop.dbCode]);
                    propertyMap[prop.dbCode] = updatedData === "null" ? "" : updatedData;
                    break;
            }
        });
    }

    return propertyMap;
}
function parseFormDataToItem(formData) {
    return {
        name: formData.name,
        createdBy: formData.createdBy,
        insert_ts: formData.insert_ts,
        updatedBy: formData.updatedBy,
        update_ts: formData.update_ts
    }
}
function* getGridData(action) {
    let screenDetails = yield select(getScreenDetailsData);
    let projectId = yield select(getProjectId);
    if (screenDetails.relationType === "") {
        const { data } = yield call(base.getConfigDataByType, screenDetails.configType, projectId);
        const cardData = new CardData("test555", CardType.DATAGRID, [...data.ConfigItemByType], {});
        yield put(setGridData(cardData));
    } else {
        const { data } = yield call(base.getConfigDataByParentIdAndRelationType,
            screenDetails.parentItemId, screenDetails.relationType, projectId);
        const cardData = new CardData("test555", CardType.DATAGRID, [...data.ConfigItemByTypeAndParentID], {});
        yield put(setGridData(cardData));
    }
}
function* getUserId() {
    try {
        // const currentSession = yield InfoAuth.currentSession();
        // const userToken = currentSession.accessToken.token;
        const storage = createStorage(config);
        const userToken = storage.getItem("AUTH_TOKEN");
        let userDetails;
        switch (JSON.parse(process.env.AUTH_DETAILS!).provider.type) {
            case 'aws':
                userDetails = yield tokenHandler(userToken);
                break;
            case 'infoauth':
                userDetails = jwt.verify(userToken, SECRET_KEY);
                break;
            case 'okta':
                userDetails = yield call(base.getOktaUserInfo);
                break;
        }
        return userDetails;
    } catch (e) {
        //console.log(e);
    }
}
function* createConfigItemSaga(action) {
    try {
        let cardDatas = yield select(getCard);
        //let userData = yield call(getUserId);
        let userDetails = yield select(getUserDetails);
        const projectId = JSON.parse(userDetails)["APP_LOGGED_IN_PROJECT_ID"];
        const configReleaseName = JSON.parse(userDetails).AdditionalDetails["APP_LOGGED_IN_CURRENT_RELEASE"];
        if (configReleaseName) {
            let screenDetails = yield select(getScreenDetailsData);
            let { errorData, cardsData } = processToCheckRequiredFieldExist(cardDatas, screenDetails.configType);
            // Extracting user id as per the auth provider
            const userId = getUserIdUsingAuthProvider(JSON.parse(process.env.AUTH_DETAILS!).provider.type, userDetails);
            yield put(setFormDatas(cardsData, "test555"));
            if (Object.keys(errorData).length > 0) {
                yield put(setErrorData(errorData));
                let errData: string[] = [];
                Object.keys(errorData).forEach((key) => {
                    errData.push(`${key} -${errorData[key]}`);
                })
                yield put(setToastMessage(errData));
            } else {
                yield put(setErrorData({}));
                const upsertDate = new Date();
                let updatedCardDatas = yield select(getCard);
                addUpsertDate(updatedCardDatas, upsertDate, userId);

                let privileges = parsePrivileges(updatedCardDatas, configReleaseName, upsertDate, action.configItemId, userId)
                let propertyMap: any = {};
                if (screenDetails.configType === "FormField") {
                    propertyMap = parseFormFieldPropertyDataByDatatype(updatedCardDatas);
                } else {
                    propertyMap = parsePropertyDataByDatatype(updatedCardDatas,
                        screenDetails.configType);
                    if (screenDetails.configType === "PortalCard") {
                        const otherPropertyMap = parsePortalCardDataByDatatype(updatedCardDatas);
                        Object.assign(propertyMap, otherPropertyMap)
                    }
                    if (screenDetails.configType === "Menu") {
                        const otherPropertyMap = parseMenuDataByDatatype(updatedCardDatas);
                        Object.assign(propertyMap, otherPropertyMap)
                    }
                }

                let data = parseFormDataToConfigObject(action.configItemId,
                    updatedCardDatas, propertyMap, privileges, screenDetails,
                    projectId, userId, configReleaseName, upsertDate)
                const result = yield call(createConfigData,
                    screenDetails.configType + "Mutation", data);

                if (result.data) {
                    if (!screenDetails.configItemId) {
                        let updatedScreenDetails = { ...screenDetails };
                        Object.keys(result.data).map(key => {
                            updatedScreenDetails.configItemId = result.data[key].configObjectId;
                        });
                        yield put(setScreenDetails(updatedScreenDetails, false));
                    }
                    yield put(setToastMessage([UpsertMessage.SAVESUCCESSFUL]));
                } else {
                    yield put(setToastMessage([UpsertMessage.UNSUCCESSFUL]));
                }
            }
        } else {
            yield put(setToastMessage([UpsertMessage.INVALID_RELEASENAME]));
        }
    } catch {
        yield put(setToastMessage([UpsertMessage.UNSUCCESSFUL]));
    }

}

function addUpsertDate(updatedCardDatas, upsertDate, userId) {
    for (const parentRelationObject of updatedCardDatas.data.parentRelation) {
        parentRelationObject["insert_ts"] = upsertDate;
        parentRelationObject['createdBy'] = userId;
    }
    for (const childRelationObject of updatedCardDatas.data.childRelation) {
        childRelationObject["insert_ts"] = upsertDate;
        childRelationObject['createdBy'] = userId;

    }
}

function getUserIdUsingAuthProvider(authProvider: string, userDetails) {
    switch (authProvider) {
        case 'aws': {
            return JSON.parse(userDetails)["APP_LOGGED_IN_USER_ID"];
        }
        case 'microsoft-ad': {
            return JSON.parse(userDetails)["APP_LOGGED_IN_USER_ID"];
        }
        case 'microsoft-ad-info-apps': {
            return JSON.parse(userDetails)["APP_LOGGED_IN_USER_ID"];
        }
        case 'okta':
        case 'info-auth':
        default: {
            return JSON.parse(userDetails)["userName"] ? JSON.parse(userDetails)["userName"] : JSON.parse(userDetails)["sub"]
        }
    }
}
function parseFormDataToConfigObject(configItemId, cardDatas: any,
    propertyMap: any, privileges: {
        roleId: number;
        privilegeType: any; isDeleted: number;
    }[], screenDetails: any,
    projectId: any, userId: any, configReleaseName: string, upsertDate: Date) {
    if (configItemId !== "") {
        return {
            ...cardDatas.data.item, isDeleted: 0, ...propertyMap,
            privileges: privileges, parentRelations: cardDatas.data.parentRelation,
            childRelations: cardDatas.data.childRelation,
            configObjectType: screenDetails.configType, projectId: projectId,
            configObjectId: configItemId, updatedBy: userId,
            configReleaseName: configReleaseName,
            update_ts: upsertDate,
            insert_ts: cardDatas.data.item.insert_ts,
            createdBy: cardDatas.data.item.createdBy
        };
    } else {
        return {
            ...cardDatas.data.item, isDeleted: 0, ...propertyMap,
            privileges: privileges, parentRelations: cardDatas.data.parentRelation,
            childRelations: cardDatas.data.childRelation,
            configObjectType: screenDetails.configType,
            projectId: projectId, configObjectId: null,
            createdBy: userId,
            configReleaseName: configReleaseName,
            insert_ts: upsertDate
        };
    }
}
function parsePrivileges(cardDatas: any, configReleaseName: string, upsertDate: Date, configItemId, userId) {
    return cardDatas.data.privilege.map((privilege) => {
        return {
            roleId: privilege.roleId,
            privilegeType: privilege.privilegeType,
            isDeleted: 0,
            configReleaseName: configReleaseName,
            insert_ts: configItemId ? privilege.insert_ts : upsertDate,
            update_ts: configItemId ? upsertDate : privilege.update_ts,
            createdBy: configItemId ? privilege.createdBy : userId,
            updatedBy: configItemId ? userId : privilege.updatedBy,
        }
    });
}
function parseMenuDataByDatatype(cardDatas) {
    let propertyMap: any = {}
    propertyMap["hrefBaseUrl"] = cardDatas.data.property.hrefBaseUrl;
    return propertyMap;
}
function parsePortalCardDataByDatatype(cardDatas) {
    let propertyMap: any = {}
    let property: any = getPropertyByPortalCardType(cardDatas.data.property.type);
    propertyMap["type"] = cardDatas.data.property.type;
    property.map((prop: any) => {
        switch (prop.dataType) {
            case "string":
                let data = typeof cardDatas.data.property[prop.dbCode] === 'string' || cardDatas.data.property[prop.dbCode] instanceof String ?
                    cardDatas.data.property[prop.dbCode] : JSON.stringify(cardDatas.data.property[prop.dbCode]);
                propertyMap[prop.dbCode] = data;
                break;
            case "boolean":
                propertyMap[prop.dbCode] = cardDatas.data.property[prop.dbCode] == "Yes" ? true : false;
                break;
            case "number":
                propertyMap[prop.dbCode] = cardDatas.data.property[prop.dbCode] === "" ? null : parseInt(cardDatas.data.property[prop.dbCode]);
                break;
        }
    });
    return propertyMap;
}
function parseFormFieldPropertyDataByDatatype(cardDatas) {
    let propertyMap: any = {}
    let property: any = getPropertyByFormFieldType(cardDatas.data.property.type);
    propertyMap["type"] = cardDatas.data.property.type;
    property.map((prop: any) => {
        switch (prop.dataType) {
            case "string":
                let data = typeof cardDatas.data.property[prop.dbCode] === 'string' || cardDatas.data.property[prop.dbCode] instanceof String ?
                    cardDatas.data.property[prop.dbCode] : JSON.stringify(cardDatas.data.property[prop.dbCode]);
                propertyMap[prop.dbCode] = data;
                break;
            case "boolean":
                propertyMap[prop.dbCode] = cardDatas.data.property[prop.dbCode] == "Yes" ? true : false;
                break;
            case "number":
                propertyMap[prop.dbCode] = cardDatas.data.property[prop.dbCode] === "" ? null : parseInt(cardDatas.data.property[prop.dbCode]);
                break;
        }
    });
    return propertyMap;
}
function parsePropertyDataByDatatype(cardDatas: any, configType) {
    let propertyMap: any = {}
    let property: any = getPropertyByConfigType(configType);
    property.map((prop: any) => {
        switch (prop.dataType) {
            case "string":
                let data = typeof cardDatas.data.property[prop.dbCode] === 'string' || cardDatas.data.property[prop.dbCode] instanceof String ?
                    cardDatas.data.property[prop.dbCode] : JSON.stringify(cardDatas.data.property[prop.dbCode]);
                propertyMap[prop.dbCode] = data;
                break;
            case "boolean":
                propertyMap[prop.dbCode] = cardDatas.data.property[prop.dbCode] == "Yes" ? true : false;
                break;
            case "number":
                propertyMap[prop.dbCode] = (cardDatas.data.property[prop.dbCode] === "" || cardDatas.data.property[prop.dbCode] === undefined) ? null : parseInt(cardDatas.data.property[prop.dbCode]);
                break;
        }
    });
    return propertyMap;
}

function* deployMeta() {
    const result = yield call(base.deployMeta);
    let messages: string[] = [];
    if (result === "Deployed Successfully") {
        messages.push(UpsertMessage.DEPLOYSUCCESSFUL);
        yield put(setToastMessage(messages));
    } else {
        messages.push(UpsertMessage.DEPLOYUNSUCCESSFUL);
        yield put(setToastMessage(messages));
    }
}

function* watchCreateConfigItem() {
    yield takeEvery(CREATE_CONFIG_ITEM, createConfigItemSaga);
}

function* watchGetScreenData() {
    yield takeEvery(GET_SCREEN_DETAILS, getScreenData);
}
function* watchGetCompositeEntityInSelectOption() {
    yield takeEvery(GET_COMPOSITE_ENTITY_IN_SELECT_OPTION, getCompositeEntityInSelectOption);
}
function* watchGetDataSourceInSelectOption() {
    yield takeEvery(GET_DATA_SOURCE_IN_SELECT_OPTION, getDataSourceInSelectOption);
}
function* watchGetProjectOption() {
    yield takeEvery(GET_PROJECT_OPTIONS, getProjectOption);
}
function* watchGetPortalInSelectOption() {
    yield takeEvery(GET_PORTAL_IN_SELECT_OPTION, getPortalInSelectOption);
}
function* watchGetPortalCardInSelectOption() {
    yield takeEvery(GET_PORTAL_CARD_IN_SELECT_OPTION, getPortalCardInSelectOption);
}
function* watchGetRootCompositeEntityNodeInSelectOption() {
    yield takeEvery(GET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION, getRootCompositeEntityNodeInSelectOption);
}
function* watchGetFormDataInSelectOption() {
    yield takeEvery(GET_FORM_DATA_IN_SELECT_OPTION, getFormDataInSelectOption);
}
function* watchGetGridDataInSelectOption() {
    yield takeEvery(GET_GRID_DATA_IN_SELECT_OPTION, getGridDataInSelectOption);
}
function* watchGetConfigDataById() {
    yield takeEvery(GET_CONFIG_DATA_BY_ID, getConfigDataByIdSaga)
}
function* watchDeleteConfigItemById() {
    yield takeEvery(GET_CONFIG_DATA_FOR_DELETE_BY_ID, deleteConfigItemById)
}
function* watchGetGridData() {
    yield takeEvery(GET_GRID_DATA, getGridData);
}
function* watchGoBack() {
    yield takeEvery(GO_BACK, goBack);
}
function* watchGetUserProfile() {
    yield takeEvery(GET_USER_PROFILE, getUserProfile);
}
function* watchDeployMeta() {
    yield takeEvery(DEPLOY_META, deployMeta);
}

function* watchSwitchProjectSaga() {
    yield takeEvery(SWITCH_PROJECT, switchProjectSaga);
}

function* watchLogoutSaga() {
    yield takeEvery(LOG_OUT, logoutSaga);
}