import gql from 'graphql-tag';

export const PortalCardQuery = gql`
query PortalCard($id:ID!)
{
    PortalCard(id:$id){
        configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    label
    archived
    favorite
    x
    y
    w
    h
    boxShadow
    cardStyle	
    cardBodyStyle
    cardHeaderStyle
    cardDataSharing
    referDataFromPortal
    order
    responsiveLayout
    isStatic
    type
    uiComponentId
    isAccessibile
    accessibilityRegex
    referenceData
    reloadRequired
    displayHeader
    initialComponent
    isFullScreenRequired
       childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    }
}`;