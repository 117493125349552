
import gql from 'graphql-tag';

export const ButtonPanel = gql`
mutation createButtonPanel($input:ButtonPanelInput)
 {
    createButtonPanel(input:$input) {
   configObjectId
   defaultType
   buttonPanelPosition
   mode
   name
   configObjectType
   createdBy
   isDeleted
   itemDescription
   insert_ts
   projectId
   updatedBy
   update_ts
   deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            } 
    }
}
`;