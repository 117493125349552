import React, { useEffect, useContext } from 'react';
import { InfoAuth } from 'info-auth-client';
import { ThemeContext } from '../theme/ThemeContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormErrorContainers } from 'info-ui-library';
//import { setUserProfile } from '../actions';
import { useDispatch } from 'react-redux';
import { createStorage } from '../storage';
import config from '../config/index';
import { USER_SESSION_LOCAL_STORAGE_KEY, ROUTE_STATE_LOCAL_STORAGE_KEY } from '../constants/appeng.enum';

const LogOut: React.FC<RouteComponentProps> = props => {
    const dispatch = useDispatch();
    useEffect(() => {
       // dispatch(setUserProfile(""));
        const storage = createStorage(config);
        storage.removeItem(USER_SESSION_LOCAL_STORAGE_KEY);
        storage.removeItem(ROUTE_STATE_LOCAL_STORAGE_KEY);
        InfoAuth.signOut()
            .then(() => {
                props.history.push('/');
                window.location.reload()
            })
            .catch(() => console.log('error signing out...'));
    });
    const themeContext = useContext(ThemeContext);
    try {
        return (
            <React.Fragment></React.Fragment>
        )
    } // eslint-disable-next-line
    catch {
        const error = ['Error occurred in Logout']
        return <FormErrorContainers ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }

};

export default withRouter(LogOut);
