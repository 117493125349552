import React, { useState } from 'react';
import styled from 'styled-components';
import ConfigPortalDatagrid from './ConfigPortalDatagrid';
import ConfigPortalForm from './ConfigPortalForm';
import { useSelector } from 'react-redux';
import SideMenu from '../components/SideMenuComponent';
import { useDispatch } from 'react-redux';
import { setToastMessage } from '../actions/index';
import Card from 'react-bootstrap/Card';
const OuterConatiner = styled.div`
padding-top:10px
`;
interface IConfigPortalWrapper {
    className?: string,
    rowHeight?: number,
    cols?: any,
    breakpoints?: any,
}

const ConfigPortal = props => {
    const screenDetails = useSelector((state: any) => state.screenDetails);
    try {
        switch (screenDetails.screenType) {
            case "ParentForm":
                if (screenDetails.configType === "ActionFlow") {
                    return <ConfigPortalForm />
                }
            case "ChildForm":
                return <ConfigPortalForm layout={props.layout} />
            case "ParentDataGrid":
            case "ChildDataGrid":
                return <ConfigPortalDatagrid />
            default:
                return <div></div>
        }
    } catch (e) {
        return <OuterConatiner></OuterConatiner>
    }
}

const ConfigPortalWrapper: React.FC<IConfigPortalWrapper> = (props: IConfigPortalWrapper) => {
    let size = [
        { i: "tree", x: 0, y: 0, w: 0.1, h: 20 },
        { i: "portal", x: 0.1, y: 0, w: 11.9, h: 20 },
        { i: "config-item", x: 0, y: 0, w: 7.9, h: 4 },
        { i: "action-buttons", x: 7.9, y: 0, w: 4, h: 4 },
        { i: "config-property", x: 0, y: 4, w: 7.9, h: 12 },
        { i: "config-privilege", x: 7.9, y: 4, w: 4, h: 12 },
        { i: "config-child-relation", x: 0, y: 16, w: 5.9, h: 8 },
        { i: "config-parent-relation", x: 5.9, y: 16, w: 6, h: 8 }];

    const [layout, setLayout] = useState(size);
    const dispatch = useDispatch();
    const screenDetail = useSelector((state: any) => state.screenDetails);
    const [isMenuOpen, setMenuToggle] = useState(false);
    const treeData = useSelector((state: any) => state.sideNodeTree.treeData);
    if (screenDetail.screenType === "ParentDataGrid" && layout[0].w === 2.3) { //this is tree width when rendered
        setLayout(size);
    }

    React.useEffect(() => {
        if (Object.keys(treeData).length === 0){
            setMenuToggle(false);
        }      
    }, [treeData]);

    const toggleMenu = (state) => {
        dispatch(setToastMessage([]));
        setMenuToggle(state.isOpen);
        if (state.isOpen) {
            setLayout([
                { i: "tree", x: 0, y: 0, w: 2.3, h: 20 },
                { i: "portal", x: 2.3, y: 0, w: 9.7, h: 20 },
                { i: "config-item", x: 0, y: 0, w: 6.5, h: 4 },
                { i: "action-buttons", x: 6.5, y: 0, w: 3.2, h: 4 },
                { i: "config-property", x: 0, y: 4, w: 6.5, h: 12 },
                { i: "config-privilege", x: 6.5, y: 4, w: 3.2, h: 12 },
                { i: "config-child-relation", x: 0, y: 16, w: 5, h: 8 },
                { i: "config-parent-relation", x: 5, y: 16, w: 4.7, h: 8 }]);
        }
        else {
            setLayout(size);
        }
    };

    return (
        <Card>
            <div id="outer-container">
                <SideMenu
                    toggleMenu={toggleMenu}
                />
                <div id="page-wrap" style={{ float: 'right', width: `${Object.keys(treeData).length !== 0 && isMenuOpen ? 'calc(100% - 275px)' : '100%'} ` }}>
                    <ConfigPortal layout={layout} />

                </div>
            </div>
        </Card>
    );
}

ConfigPortalWrapper.defaultProps = {
    className: "layout",
    rowHeight: 25,
    cols: { lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 },
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },

};


export default ConfigPortalWrapper;