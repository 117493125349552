import gql from 'graphql-tag';

export const ButtonpanelQuery = gql`
  query Buttonpanel($id: ID!)
  {
  ButtonPanel(id:$id) {
    configObjectId
    defaultType
    buttonPanelPosition
    mode
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
  childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;