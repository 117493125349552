import React, { useState, useContext, useEffect } from 'react';
import DataGrid from "./dataGridComponents/Info_Data_Grid";
import { FormErrorContainers, Button } from 'info-ui-library';
import { columns } from './HeaderColumns';
import { ThemeContext } from '../../theme/ThemeContext';
import { themes } from '../../theme/ThemeOptions';
import { useSelector, useDispatch } from 'react-redux';
import { getScreenDetails, getConfigDataById, setFormDatas, getGridData, setErrorData, deleteConfigItemById } from '../../actions/index';
import styled from 'styled-components';
import { resolveColor } from '../../theme/ThemeOptions';
import { StyledConfirModalDiv } from './FormStyle';
import { confirmAlert } from 'react-confirm-alert';
export const HeaderDiv = styled.div`
font-weight:bolder;
font-size:larger;
padding:0px !important;
padding-top: 5px!important;
color: ${ p =>
        resolveColor(p.ioTheme, p.ioMode, p.styleName, 'background') +
        '!important'};
`;
export const OuterDiv = styled.div`
display:flex;
padding:0px !important;
`;
export const ButtonDiv = styled.div`
padding:0px !important;

`;
export const StyledButton = styled(Button)`
float:right;

`;
const UIBasicTableGenerator: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    const screenDetails = useSelector((state: any) => state.screenDetails);
    const cardsData = useSelector((state: any) => state.cardsData);
    useEffect(() => {
        dispatch(getGridData());
    }, [screenDetails])



    const handleClick = (column, rowDetails, props: any, dispatch, actionColumnType) => {
        switch (actionColumnType) {
            case 'edit':
                {
                    const rowDetail = rowDetails.original;
                    let screenDtls: any = {
                        ...screenDetails,
                        screenType: screenDetails.screenType === "ChildDataGrid" ? "ChildForm" : "ParentForm",
                        configItemId: rowDetail.configObjectId
                    }
                    dispatch(setErrorData({}));
                    dispatch(getConfigDataById(rowDetail.configObjectId, screenDtls));
                }
                break;
            case 'delete':
                {

                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <StyledConfirModalDiv ioTheme={themes[0]}
                                    ioMode="light"
                                    styleName="primary" className='custom-ui'>
                                    <h1>Are you sure?</h1>
                                    <p>You want to delete this file?</p>
                                    <Button ioTheme={themes[0]}
                                        ioMode="light"
                                        styleName="primary" onClick={onClose}
                                        disabled={false}
                                        name={'No'} />
                                    <Button ioTheme={themes[0]}
                                        ioMode="light"
                                        styleName="primary"
                                        disabled={false}
                                        name={'Yes'}
                                        onClick={() => {
                                            const rowDetail = rowDetails.original;
                                                let screenDtls: any = {
                                                    ...screenDetails,
                                                    screenType: screenDetails.screenType === "ChildDataGrid" ? "ChildForm" : "ParentForm",
                                                    configItemId: rowDetail.configObjectId
                                                }
                                            cardsData.data.map(data => {
                                                if (rowDetails.original.configObjectId === data.configObjectId) {
                                                    cardsData.data.splice(rowDetails.index, 1);
                                                    dispatch(deleteConfigItemById(data.configObjectId, screenDtls));
                                                }
                                            })

                                            onClose();
                                        }}
                                    />
                                </StyledConfirModalDiv>
                            );
                        }
                    })
                }
                break;
        }
    }

    const handleSave = () => {
        let screenDtls: any = {
            ...screenDetails,
            screenType: "ParentForm",
            configItemId: ""
        }
        dispatch(getScreenDetails(screenDtls, true));
        dispatch(setFormDatas({}, ""));
    }
    let gridData = cardsData ? cardsData.data && cardsData.data[0] ? cardsData.data : [] : [];
    const themeContext = useContext(ThemeContext);
    const updatedScreenDetails = useSelector((state: any) => state.screenDetails);
    try {
        return (<div><OuterDiv className="col-md-12">
            <HeaderDiv ioTheme={themes[0]}
                ioMode={themeContext.ioMode}
                styleName={themeContext.styleName} className="col-md-10">{updatedScreenDetails.configType}</HeaderDiv>
            <ButtonDiv className="col-md-2"><StyledButton ioTheme={themes[0]}
                ioMode={themeContext.ioMode}
                styleName={themeContext.styleName} onClick={(e) => handleSave()}
                disabled={false}
                name={'Add'} /></ButtonDiv></OuterDiv><DataGrid
                styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                borderEnable={true}
                strippedEnable={true}
                hoverEnable={false}
                columns={columns(handleClick, themeContext, props)}
                data={[...gridData]}
                scrollEnable={false}
                serverPaginationEnable={false}
                subComponentEnable={false}
                subComponentType="Form"
                primaryDBCode="configObjectId"
                pageIndex={0}
                cardsData={cardsData}
            /></div>)

    } catch {
        const error = ['Error occurred in Dynamic UI Table']
        return <FormErrorContainers
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }


};

function isPropsAreEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}
export default (React.memo((UIBasicTableGenerator), isPropsAreEqual));