import React, { useContext } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import {
    TopHeader,
    Menu,
    MenuItem,
    SubMenu,
    Button,
    LeftMenu
} from 'info-ui-library';
import MediaQuery from 'react-responsive';
import { getScreenDetails, logOut } from '../actions/index';
import { useDispatch, useSelector } from 'react-redux';
import menuJson from "./mockData/menus.json";
import styled from 'styled-components';
import { resolveColor } from '../theme/ThemeOptions';
import UserSettingMenu from '../generators/SettingMenuGenerator';
import ProjectComponent from './ProjectComponent';

const profileImg = require('../assets/img/avatars/6.png');

export const MenuSection1 = styled.div`
    height: inherit;
    display: inline-flex; 
    flex-direction: row;
    width: 80%;
    border: none;
    background: rgb(23, 162, 184);
    color: black;
    @media only screen and (max-width: 750px) {
        width: 70%;
    }
    @media only screen and (max-width: 511px) {
        width: 50%;
    }
`;
export const MenuSection2 = styled.div`
    height: inherit; 
    display: inline-flex; 
    flex-direction: row-reverse; 
    width: 20%;
    border: none;
    background: rgb(23, 162, 184);
    color: black;
    @media only screen and (max-width: 750px) {
        width: 30%;
    }
    @media only screen and (max-width: 511px) {
        width: 50%;
    }
 `;

const commonMenuGeneration = (menuList, dispatch, themeContext, props) => {
    return menuList.menus.map(menu => {
        if (menu.subMenus === undefined) {
            return <MenuItem styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                key={menu.id}
                name={menu.menuName}
                onClick=
                {(e) => handleClick(menu.menuName, dispatch)}
            />
        } else {
            return <SubMenu styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                key={menu.id}
                name={menu.menuName}
            >
                {commonMenuGeneration(menu.subMenus, dispatch, themeContext, props)}
            </SubMenu>
        }
    })
}
const handleClick = async (menuName, dispatch) => {
    let screenDetails: any = {
        "screenType": "ParentDataGrid",
        "configType": menuName,
        "configItemId": "",
        "parentItemId": "",
        "relationType": ""
    }

    dispatch(getScreenDetails(screenDetails, false));
}

const TopMenu: React.FC<any> = (props) => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    const applications = useSelector((state: any) => state.applications);
    const userDetails = useSelector((state: any) => state.userDetails);
    const userDetailsParsed = userDetails ? JSON.parse(userDetails) : {};

    let roleIdArray;
    if (applications.length > 0 && userDetailsParsed.AdditionalDetails.APP_LOGGED_IN_ROLE_ID) {
        roleIdArray = userDetailsParsed.AdditionalDetails.APP_LOGGED_IN_ROLE_ID.split(',');
    }
    const rcMenu = roleIdArray.includes('16') || roleIdArray.includes('2') ? commonMenuGeneration(menuJson, dispatch, themeContext, props) : [];

    //console.log('dispatch(setStore(configObjectClicked));');
    const handleLogoutClick = async (url) => {
        dispatch(logOut(url, props.history));
    }

    try {
        return (
            <div style={{ display: "flex" }}>
                <MediaQuery query="(max-width: 750px)">
                    <Button onClick={handleClick} styleName={themeContext.styleName}
                        ioTheme={themeContext.ioTheme}
                        ioMode={themeContext.ioMode}
                        id={'leftmenutogglebuttonid1'} className='fa fa-bars' name={''} />
                </MediaQuery>
                <MenuSection1>
                    <Menu styleName={themeContext.styleName}
                        ioTheme={themeContext.ioTheme}
                        ioMode={themeContext.ioMode}>
                        {rcMenu}
                    </Menu> </MenuSection1>

                <MenuSection2>

                    <UserSettingMenu history={props.history}
                        profileImg={profileImg}
                        handleClick={(url) => handleLogoutClick(url)} />
                    <ProjectComponent />

                </MenuSection2>


            </div>
        )
    } catch (e) {
        return <div></div>
    }

}

export default (TopMenu);