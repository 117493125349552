import gql from 'graphql-tag';

export const MicroFlow = gql`
mutation createMicroFlow($input:MicroFlowInput)
 {
    createMicroFlow(input:$input) {
        configObjectId
        flowConfig
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        privileges {
            privilegeType
            roleId
            isDeleted
        }
        parentRelations {
            parentItemId
            relationType
            isDeleted
          }
        childRelations {
            childItemId
            relationType
            isDeleted
          } 
    }
}
`;