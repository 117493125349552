import gql from 'graphql-tag';

export const DataGrid = gql`
mutation createDataGrid($input:DataGridInput)
 {
    createDataGrid(input:$input) {
        label
        configObjectId
        defaultOrdering
        gridType
        isHavingAdvanceFilterForm
        swimlaneRequired
        modalRequired
        isRowReOrder
        isBorderEnable
        isStrippedEnable
        isHoverEnable
        isScrollEnable
        isServerPaginationEnable
        isRowSelectionEnable
        isHeaderAvailable
        isSubComponentEnable
        subComponentType
        isEditButtonEnable
        defaultSorting
        gridDataAlignment
        gridHeaderAlignment
        name
        tabGroup
        configObjectType
        createdBy
        isDeleted
        insert_ts
        projectId
        itemDescription
        updatedBy
        update_ts
        deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;