import { SelectOption } from 'info-ui-library';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRootCompositeEntityNodeInSelectOption } from '../actions/index';
import { Row, Col } from '@bootstrap-styled/v4';


const RootCompositeEntityNodeSelectOption: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    //fetch options from state
    const options = useSelector((state: any) => state.selectOptionDetails.rootcompositeEntityNodeSelectOption);
    const handleSelectOptionForm = (updatedValue, dbCode, type) => {
        props.handleSelectOption(updatedValue, dbCode, type, dispatch)
    }
    useEffect(() => {
        dispatch(getRootCompositeEntityNodeInSelectOption())
    }, []);

    const valueAsAnObject = getValueObject(props.selectedValue, options);
    return (<SelectOption
        styleName={props.styleName}
        ioTheme={props.ioTheme}
        value={valueAsAnObject}
        ioMode={props.ioMode}
        options={options}
        label={props.label}
        key={props.key}
        displayType={props.displayType}
        onChange={(value) => handleSelectOptionForm(value, props.dbCode, "property")}
        isSearchable={true}
        isDisabled={false}
        isMulti={false}
        mandatory={props.mandatory}
        hasError='' />
    )
}

export default (RootCompositeEntityNodeSelectOption);
export const getValueObject = (initialvalue, selectedOptions) => {
    let value = null
    if (selectedOptions) {
        selectedOptions.map(object => {
            if (String(object.value) === String(initialvalue)) {
                value = object;
            }
            return null;
        })
    }
    return value
}