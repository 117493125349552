import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { Languages, FormErrorContainers } from 'info-ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { switchProject } from '../actions';
import { createStorage } from '../storage';
import config from '../config/index';
import { FINANCIAL_YEAR_OPTIONS_KEY, USER_SESSION_LOCAL_STORAGE_KEY, UpsertMessage } from '../constants/appeng.enum';
const ProjectComponent: React.FC = (props: any) => {

    const projectOptions = useSelector((state: any) => state.applications);
    const currentProject = useSelector((state: any) => state.currentProject);
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    const storage = createStorage(config);
    try {
        const handleChange = (selectedProject) => {
            let currentProject;
            projectOptions.map(project => {
                if (selectedProject == project.code)
                    currentProject = project.aeApplicationUuid;
            })
            // const existingUserDetails = USER_SESSION_LOCAL_STORAGE_KEY);
            // const userDetailsMap = JSON.parse(existingUserDetails ? existingUserDetails : "{}");
            // userDetailsMap.AdditionalDetails.APP_LOGGED_IN_PROJECT_ID  = currentProject;
            // userDetailsMap.AdditionalDetails.APP_LOGGED_IN_PROJECT_NAME  = selectedProject;
            // userDetailsMap.APP_LOGGED_IN_PROJECT_ID  = currentProject;
            // userDetailsMap.APP_LOGGED_IN_PROJECT_NAME  = selectedProject;
            // storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(userDetailsMap));
            // const messages: string[] = [];
            // messages.push(UpsertMessage.APPLICATION_SWITCH);
            // const updateStore: any = {
            //     currentProject: selectedProject, toggleMessages: messages,
            //     userDetails: JSON.stringify(userDetailsMap)
            // }
            // dispatch(setStore(updateStore));
            dispatch(switchProject(currentProject));
            // window.location.reload();
        };

        return (
            projectOptions && projectOptions.length > 1 ?
                <Languages ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    LanguagesOption={projectOptions}
                    onClick={(selectedProject) => handleChange(selectedProject)}
                    className={"fa fa-list"}
                    defaultlanguage={currentProject}
                /> : null
        )
    } catch{
        const error = ['Error occurred in Project']
        return <FormErrorContainers ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }
}

export default ProjectComponent;
