import React, { useContext } from 'react';
import { push as Menu } from 'react-burger-menu';
import styled from 'styled-components';
import { DDLFileTree } from 'info-sortable-tree';
import { useDispatch, useSelector } from 'react-redux';
import { getScreenDetails, getConfigDataById ,setErrorData} from '../actions/index';
import { ThemeContext } from '../theme/ThemeContext';
import { resolveColor } from '../theme/ThemeOptions';

import logo from '../sideButton.svg';

const SideNav = styled(Menu)`
    position: absolute!important;
    height: 100%;
    width: 250px !important;
    & >.bm-menu {
        background: ${ p =>
    resolveColor(p.ioTheme, p.ioMode, p.styleName, 'color')};
        padding: 10px 0px;
        font-size: 0.91em;
        font-weight: 650;
    border: 1px solid #17a2b8;
    border-radius: 5px;
        overflow: auto;
        scrollbar-width: thin;                                     /*firefox*/
        scrollbar-color: ${ p => resolveColor(p.ioTheme, p.ioMode, p.styleName, 'scrollbarcolor') }   /*firefox*/
    }

    /*chrome*/
    & >.bm-menu::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: ${ p => resolveColor(p.ioTheme, p.ioMode, p.styleName, 'scrollbarbgcolor') }
    }
    & >.bm-menu::-webkit-scrollbar-thumb {
      background: rgb(105, 103, 103); 
      border-radius: 5px;
    }

    & >.bm-menu >.bm-item-list {
        color: #b8b7ad;
        padding: 10px 0px;
    }
    & >.bm-menu >.bm-item-list >.bm-item {
        display: inline-block;
    }

    & >.bm-menu >.bm-item-list > div > div > .rst__tree > .rstcustom__node > div > button {
      visibility: hidden;
    }

    /*cross button inside menu styles properties*/
    & > .bm-menu + div > .bm-cross-button {
      height: 24px;
      width: 24px;
      right: 15px !important;
    }

    & > .bm-menu + div > .bm-cross-button > span > .bm-cross {
      background: #bdc3c7;
    }

    /* Position and sizing of burger menu button */
    & + div >.bm-burger-button {
        position: fixed;
        width: 12px;
        height: 36px;
        left: 0px;
        top: 50%;
    }
    & + div > div > span > .bm-burger-bars {
      background: #373a47;
    }
    & + div > div > span > .bm-burger-bars:hover {
      background: #a90000;
    }
`;

const SideMenu: React.FC<any> = props => {
  const screenDetails = useSelector((state: any) => state.screenDetails);
  const treeData = useSelector((state: any) => state.sideNodeTree.treeData);
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const handleClick = (node: any, tree: any) => {
    let screenDtls: any = {
      ...screenDetails,
      screenType: node.screenType,
      configType: node.configType,
      configItemId: node.parentId,
      parentItemId: node.parentId,
      treeNodeUuid: node.id,
      relationType: node.screenType === "ParentDataGrid" || node.screenType === "ParentForm" ? "" :
        node.parentType + '_' + node.configType
    }
    dispatch(setErrorData({}));
    if (node.screenType === "ChildForm" || node.screenType === "ParentForm") {
      dispatch(getConfigDataById(screenDtls.configItemId, screenDtls));
    } else {
      dispatch(getScreenDetails(screenDtls, true));
    }
  }

  try {
    return (
      <div>
        {Object.keys(treeData).length !== 0 &&
          <SideNav
            id="navbar"
            noOverlay
            onStateChange={props.toggleMenu}
            isOpen={false}
            customBurgerIcon={<img src={logo} />}
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={themeContext.styleName}
          >
            <DDLFileTree
              styleName={themeContext.styleName}
              ioTheme={themeContext.ioTheme}
              ioMode={themeContext.ioMode}
              enableExpandButton={false}
              enableCollapseButton={false}
              enableExpandALLButton={false}
              enableCollapseAllButton={false}
              enableExpandLevelButton={false}
              enableCollapseLevelButton={false}
              levelCount={treeData.returnData.LevelCount}
              treeData={treeData}
              onClick={(node, tree) => handleClick(node, tree)}
            />
          </SideNav>}
      </div>
    )
  } catch (e) {
    return <div></div>
  }
}
export default (SideMenu);