
import { TextBox } from 'info-ui-library';
import React, { useState, useContext } from 'react';
import { themes } from '../theme/ThemeOptions';
import { ThemeContext } from '../theme/ThemeContext';
import ModalDataSharing from './ModalDataSharing';

const DataSharingComponentCore: React.FC<any> = (props: any) => {
    const themeContext = useContext(ThemeContext);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(props.dataSharingValu);

    
    const handleIconChange = (isOpen) => {
        setOpen(isOpen);
    }
    return (
        <React.Fragment>
            {open &&
                <ModalDataSharing
                    setData={setData}
                    dataSharingValue={data}
                    setOpen={setOpen}
                    handlePropertyChange={props.handlePropertyChange}
                />}
            <TextBox
                ioTheme={themes[0]}
                ioMode={themeContext.ioMode}
                styleName={themeContext.styleName}
                label={props.configProps.label}
                key={props.configProps.label}
                value={data}
                displayType="FloatingLabel"
                handleChange={(updatedValue) => {
                    //console.log('abc')
                }}
                errorMessage={props.errorData[props.configProps.dbCode]}
                hasError={props.errorData[props.configProps.dbCode] ? true : false}
                mandatory={props.configProps.isMandatory}
                isButtonTextBox={false}
                disabled={true}
                icon={'fa fa-pencil-square-o'}
                iconClicked={!open}
                handleIconClick={(iconClicked) => { handleIconChange(iconClicked) }}
            />
        </React.Fragment>

    );
}

export default (DataSharingComponentCore);