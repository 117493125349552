import gql from 'graphql-tag';

export const MicroFlowQuery = gql`
query MicroFlow($id: ID!)
 {
    MicroFlow(id:$id) {
    configObjectId
    flowConfig
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges {
            privilegeType
            roleId
            isDeleted
            insert_ts
            update_ts
            createdBy
            updatedBy
        }
        parentRelations {
            parentItemId
            relationType
            isDeleted
            insert_ts
            update_ts
            createdBy
            updatedBy
          }
        childRelations {
            childItemId
            relationType
            isDeleted
            insert_ts
            update_ts
            createdBy
            updatedBy
          } 
    }
}
`;