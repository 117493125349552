import gql from 'graphql-tag';

export const Column = gql`
mutation createColumn($input:ColumnInput)
 {
  createColumn(input:$input) {
        configObjectId
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        order
        columnAlignment
        accessibilityRegex
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;