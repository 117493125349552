import React,{useState,useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Modal, FormErrorContainers, Button, Form} from 'info-ui-library';
import {themes} from '../theme/ThemeOptions';
import ParentRelationForm from './ParentRelationForm';
import { ThemeContext } from '../theme/ThemeContext';

const handleClose = (SetModalVisible) => {
    SetModalVisible(false)
}
const handleClick = (SetModalVisible,setRelationFormData,relationFormData,setErrorData,setFormData,formData) => {
    SetModalVisible(false);
    setRelationFormData({parentRelation:{}})
    let parentRelations=formData;
    if (Object.keys(relationFormData.parentRelation).length ===0) {
        setErrorData(true)
        SetModalVisible(true);
    }else if(relationFormData.parentRelation.parentItemId === undefined){
        setErrorData(true)
        SetModalVisible(true);
    }else{
        parentRelations.parentRelation.push({...relationFormData.parentRelation,isDeleted:0});
        setFormData({ ...parentRelations });
   }
    

}

const ParentRelationModal = (props : any) => {
    const screenDetails = useSelector((state: any) => state.screenDetails);
    const themeContext = useContext(ThemeContext);
    const [errorData, setErrorData] = useState(false);
    const [relationFormData, setRelationFormData] = useState({parentRelation:{}});
    return (
        <Modal
            styleName={themeContext.styleName}
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
            id={'dfhgj-45t3dfg'}
            header='Add Parent Relation'
            toggle={() => handleClose(props.SetModalVisible)}
            isOpen={true}
            footer={< Button styleName = 'primary' 
            ioTheme = {themes[0]}
            ioMode = 'light' 
            id = "save" disabled = {false}
            name = "Save"
            onClick = {(e) => handleClick(props.SetModalVisible,setRelationFormData,relationFormData,setErrorData,props.setFormData,props.formData)} />}
            >
                <ParentRelationForm setFormData={setRelationFormData} formData={relationFormData} errorData={errorData} setErrorData={setErrorData}/>
            </Modal>
    );
}

export default(ParentRelationModal);
