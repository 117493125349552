import { SelectOption } from 'info-ui-library';
import React, { useContext, useEffect } from 'react';
import { themes } from '../theme/ThemeOptions';
import { ThemeContext } from '../theme/ThemeContext';
import { useDispatch, useSelector } from 'react-redux';
import { getPortalCardInSelectOption } from '../actions/index';
import { getValueObject } from './PortalSelectOption';

const PortalCardSelectOption: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    const themeContext = useContext(ThemeContext);
    //fetch options from state
    const options = useSelector((state: any) => state.selectOptionDetails.portalCardSelectOption);
    useEffect(() => {
        dispatch(getPortalCardInSelectOption())
    }, []);
    const handleChange = (value, index, dbCode) => {
        props.onChange(value,
            dbCode, index);
    }
    const valueAsAnObject = getValueObject(props.value, options);
    return (<SelectOption
        styleName={themeContext.styleName}
        ioTheme={themes[0]}
        value={valueAsAnObject}
        ioMode={themeContext.ioMode}
        options={options}
        label={props.label}
        configId={props.label}
        key={props.id}
        displayType={props.displayType}
        onChange={(value) => handleChange(value, props.index, props.dbCode)}
        isSearchable={true}
        isDisabled={false}
        isMulti={false}
        mandatory={false}
        hasError='' />
    )
}

export default (PortalCardSelectOption);
