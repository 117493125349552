import React from 'react';
import { TextBox } from 'info-ui-library';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import ReactTooltip from 'react-tooltip';

const EditableCell = ({
    cell,
    row,
    column: { id },
    updateMyData,
}) => {
    const [isEditable, setEditable] = React.useState(false);
    const [value, setValue] = React.useState(cell.value);
    const [previousValue, setPreviousValue] = React.useState(cell.value);


    React.useEffect(() => {
        setValue(cell.value);
        setPreviousValue(cell.value);
    }, [cell.value])

    const onChange = e => {
        setPreviousValue(value);
        setValue(e);
    }

    let errorData = cell.column.useErrorDataUsingDBCode(row.id.toString(), id, cell.column.referralId, true);

    React.useEffect(() => {
        if (errorData && errorData.dbCode === id && errorData.path === row.id.toString()) {
            setEditable(true);
        } else {
            setEditable(false);
        }
    }, [errorData]);

    const onBlur = () => {
        if (value !== previousValue) {
            updateMyData(row.index, id, value, row.id);
        } else {
            setEditable(false);
        }
    }

    const handleClick = () => {
        if (errorData || (errorData && errorData.path !== row.id.toString())) {
            setEditable(false);
        } else {
            setEditable(true);
        }
    }


    return cell.column.editEnable && isEditable ?
        <React.Fragment>
            <div onMouseLeave={onBlur} style={{ 'border': (errorData ? '1px solid red' : 'none'), 'height': '40px' }} data-for='textBoxError' data-tip='textBoxError' >
                <TextBox value={value} handleChange={(e) => onChange(e)} onBlur={onBlur} styleName={cell.column.styleName} ioTheme={cell.column.ioTheme} ioMode={cell.column.ioMode} label={""} displayType={'SingleLine'} className={"editableTextBox"} isInlineGridComponent={true} />
            </div>
            {errorData ? <ReactTooltip id='textBoxError' className='customeTheme'
                place="bottom" type="error" effect="solid">
                <span>{errorData.message}</span>
            </ReactTooltip> : ""}
        </React.Fragment > :
        <React.Fragment>
            {
                (cell.isRepeatedValue !== true && value !== undefined && value !== null) ?
                    <div onClick={() => handleClick()} style={{ padding: "0 10px" }} onMouseOver={() => handleClick()}>
                        <EllipsisWithTooltip placement="bottom">
                            {value}
                        </EllipsisWithTooltip>
                    </div>
                    : <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }} onClick={() => handleClick()} onMouseOver={() => handleClick()} />
            }
        </React.Fragment>
}

export default EditableCell;