import { createStorage } from '../storage';
import { call, put } from 'redux-saga/effects';
import { FormQuery } from '../api/metadata-query/Form';
import { PortalQuery } from '../api/metadata-query/Portal';
import { PortalCardQuery } from '../api/metadata-query/PortalCard';
import { SubPortalQuery } from '../api/metadata-query/SubPortal';
import { TabGroupQuery } from '../api/metadata-query/TabGroup';
import { TabPortalQuery } from '../api/metadata-query/TabPortal';
import { PortalDataGridQuery } from '../api/metadata-query/PortalDataGrid';
import { PortalFormQuery } from '../api/metadata-query/PortalForm';
import { DataGridQuery } from '../api/metadata-query/DataGrid';
import { WidgetQuery } from '../api/metadata-query/Widget';
import { CompositeEntityQuery } from '../api/metadata-query/CompositeEntity';
import { CompositeEntityNodeQuery } from '../api/metadata-query/CompositeEntityNode';
import { RootCompositeEntityNodeQuery } from '../api/metadata-query/RootCompositeEntityNode';
import { FormSectionQuery } from '../api/metadata-query/FormSection';
import { LogicalColumnQuery } from '../api/metadata-query/LogicalColumn';
import { LogicalEntityQuery } from '../api/metadata-query/LogicalEntity';
import { PhysicalColumnQuery } from '../api/metadata-query/PhysicalColumn';
import { PhysicalEntityQuery } from '../api/metadata-query/PhysicalEntity';
import { StandardValidationQuery } from '../api/metadata-query/StandardValidation';
import { DataBaseValidationQuery } from '../api/metadata-query/DataBaseValidation';
import { CustomFormValidationObjectQuery } from '../api/metadata-query/CustomFormValidation';
import { FormDbValidationQuery } from '../api/metadata-query/FormDbValidation';
import { DataSetRelQuery } from '../api/metadata-query/DataSetRel';
import { DataSetRelPropertyQuery } from '../api/metadata-query/DataSetRelProperty';
import { ConfigItemByParentIdAndRelationType } from '../api/metadata-query/ConfigItemByParentIdAndRelationType';
import { getClient } from '../utils/ClientUtils';
import { ConfigItem } from '../api/metadata-query/ConfigItem';
import { FormFieldQuery } from '../api/metadata-query/FormField';
import { DataSourceQuery } from '../api/metadata-query/DataSource';
import { MenuGroupQuery } from '../api/metadata-query/MenuGroup';
import { MenuQuery } from '../api/metadata-query/Menu';
import { DataGridColumnQuery } from '../api/metadata-query/DataGridColumn';
import { LogicalEntityOperationInputQuery } from '../api/metadata-query/LogicalEntityOperationInput';
import { ButtonpanelQuery } from '../api/metadata-query/Buttonpanel';
import { ButtonQuery } from '../api/metadata-query/Button';
import { MenuButtonQuery } from '../api/metadata-query/MenuButton';
import { ColumnDataPreprocessor } from '../api/metadata-query/ColumnDataPreprocessor';
import { InfoAuth } from 'info-auth-client';
import { push } from 'connected-react-router';
import { ActionFlowQuery } from '../api/metadata-query/ActionFlow';
import { MicroFlowQuery } from '../api/metadata-query/MicroFlow';
import { RowQuery } from '../api/metadata-query/Row';
import { ColumnQuery } from '../api/metadata-query/Column';
import { NodeBusinessRuleQuery } from '../api/metadata-query/NodeBusinessRule';
import { DataParserQuery } from '../api/metadata-query/DataParser';
import { ReleaseQuery } from '../api/metadata-query/ReleaseQuery';
import api, { userDetailsJwtKey } from '../api';
import config from '../config';
import { HelpItemQuery } from '../api/metadata-query/HelpItem';
import { EmailTemplateQuery } from '../api/metadata-query/EmailTemplate';
import { Applications } from '../api/metadata-query/Application';
import { Roles } from '../api/metadata-query/Role';
import { DatabaseQuery } from '../api/metadata-query/Database';

const uuid = require('uuid/v4');
let terminate = false;

function* authJwtKey(history?: any) {
    try {
        // const currentSession = yield InfoAuth.currentSession();
        // return currentSession.accessToken.token;
        const storage = createStorage(config);
        const auth = storage.getItem('AUTH_TOKEN');
        return auth;
    } catch (e) {
        yield put(push('/'));
        window.location.reload();
    }
}
export function* getConfigDataByType(type, projectId) {
    const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';
    let query = ConfigItem;
    if (isServerEnv) {
        const jwtToken = userDetailsJwtKey();
        const authToken = yield call(authJwtKey);
        const client = getClient()
        return yield client.query({
            query: query,
            variables: { type: type, projectId: projectId },
            context: {
                headers: {
                    authorization: authToken,
                    userDetails: jwtToken,
                    provider: JSON.parse(process.env.AUTH_DETAILS!).provider.type,
                }
            }
        });
    } else {
        query = query.loc.source.body;
        query = query.trim();
        query = query.substring(query.indexOf("{"));
        query = query.substring(0, query.length);
        query = query.replace("$id", '"' + type + '"');
        //return yield graphqlquery(query);
    }
}
export function* getConfigRelease() {
    const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';
    let query = ReleaseQuery;
    if (isServerEnv) {
        const jwtToken = userDetailsJwtKey();
        const authToken = yield call(authJwtKey);
        const client = getClient()
        return yield client.query({
            query: query,
            context: {
                headers: {
                    authorization: authToken,
                    userDetails: jwtToken,
                    provider: JSON.parse(process.env.AUTH_DETAILS!).provider.type,
                }
            }
        });
    } else {
        query = query.loc.source.body;
        query = query.trim();
        query = query.substring(query.indexOf("{"));
        query = query.substring(0, query.length);
        //return yield graphqlquery(query);
    }
}

export function* getConfigDataByParentIdAndRelationType(parentId, relationType, projectId) {
    const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';
    let query = ConfigItemByParentIdAndRelationType;
    if (isServerEnv) {
        const jwtToken = userDetailsJwtKey()
        const authToken = yield call(authJwtKey);
        const client = getClient();
        return yield client.query({
            query: query,
            variables: { parentID: parentId, type: relationType, projectId: projectId },
            context: {
                headers: {
                    authorization: authToken,
                    userDetails: jwtToken,
                    provider: JSON.parse(process.env.AUTH_DETAILS!).provider.type,
                }
            }
        });
    }
}
export function* getEntityData(entityId, primaryKey, history: any) {
    const authToken = yield call(authJwtKey, history);
    const response = yield call(api.getEntityData, entityId, primaryKey, authToken);
    return response;
}

export function* getGridData(gridId, data, history: any) {
    const authToken = yield call(authJwtKey, history);
    const response = yield call(api.getGridData, gridId, data, authToken);
    return response;
}

export function* deployMeta() {
    const authToken = yield call(authJwtKey);
    const response = yield call(api.deployMeta, authToken);
    return response;
}

export function* getProjectOption(userId) {
    const authToken = yield call(authJwtKey);
    const response = yield call(api.getProjectOption, userId, authToken);
    return response;
}

export function* getDefaultProjectAndRoleFromUserId(userId, projectId) {
    const authToken = yield call(authJwtKey);
    const response = yield call(api.getDefaultProjectAndRoleFromUserId, userId, projectId, authToken);
    return response;
}

export function* getAllApplications() {
    const authToken = yield call(authJwtKey);
    const response = yield call(api.getProjects, authToken);
    return response;
}

export function* getOktaUserInfo() {
    const authToken = yield call(authJwtKey);
    const response = yield call(api.getOktaUserInfo, 'Bearer ' + authToken);
    return response;
}

export function* getRoleOption(userId) {
    const authToken = yield call(authJwtKey);
    const response = yield call(api.getRoleOption, userId, authToken);
    return response;
}

export function* getRoleOptionViaApplicationId(appId) {
    const authToken = yield call(authJwtKey);
    const response = yield call(api.getRoleOptionViaApplicationId, appId, authToken);
    return response;
}

export function* getAllRoleOption() {
    const authToken = yield call(authJwtKey);
    const response = yield call(api.getAllRoleOption, authToken);
    return response;
}

export function* getConfigData(queryType, id) {
    const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';
    let query
    switch (queryType) {
        case 'FormQuery':
            query = FormQuery;
            break;
        case 'DataGridQuery':
            query = DataGridQuery
            break;
        case 'FormSectionQuery':
            query = FormSectionQuery
            break;
        case 'FormFieldQuery':
            query = FormFieldQuery
            break;
        case 'SubPortalQuery':
            query = SubPortalQuery
            break;
        case 'TabGroupQuery':
            query = TabGroupQuery
            break;
        case 'TabPortalQuery':
            query = TabPortalQuery
            break;
        case 'PortalQuery':
            query = PortalQuery
            break;
        case 'PortalCardQuery':
            query = PortalCardQuery
            break;
        case 'PortalDataGridQuery':
            query = PortalDataGridQuery
            break;
        case 'PortalFormQuery':
            query = PortalFormQuery
            break;
        case 'LogicalEntityQuery':
            query = LogicalEntityQuery
            break;
        case 'PhysicalEntityQuery':
            query = PhysicalEntityQuery
            break;
        case 'PhysicalColumnQuery':
            query = PhysicalColumnQuery
            break;
        case 'LogicalColumnQuery':
            query = LogicalColumnQuery
            break;
        case 'StandardValidationQuery':
            query = StandardValidationQuery
            break;
        case 'DataSetRelQuery':
            query = DataSetRelQuery
            break;
        case 'DatabaseValidationQuery':
            query = DataBaseValidationQuery
            break;
        case 'FormDbValidationQuery':
            query = FormDbValidationQuery
            break;
        case 'CustomFormValidationQuery':
            query = CustomFormValidationObjectQuery
            break;
        case 'DataSetRelQuery':
            query = DataSetRelQuery
            break;
        case 'DataSetRelPropertyQuery':
            query = DataSetRelPropertyQuery
            break;
        case 'CompositeEntityQuery':
            query = CompositeEntityQuery
            break;
        case 'CompositeEntityNodeQuery':
            query = CompositeEntityNodeQuery
            break;
        case 'RootCompositeEntityNodeQuery':
            query = RootCompositeEntityNodeQuery
            break;
        case 'DataSourceQuery':
            query = DataSourceQuery
            break;
        case 'MenuGroupQuery':
            query = MenuGroupQuery
            break;
        case 'MenuQuery':
            query = MenuQuery
            break;
        case 'DataGridColumnQuery':
            query = DataGridColumnQuery
            break;
        case 'LogicalEntityOperationQuery':
            query = LogicalEntityOperationInputQuery
            break;
        case 'ButtonPanelQuery':
            query = ButtonpanelQuery
            break;
        case 'ButtonQuery':
            query = ButtonQuery
            break;
        case 'MenuButtonQuery':
            query = MenuButtonQuery
            break;
        case 'ColumnDataPreprocessorQuery':
            query = ColumnDataPreprocessor
            break;
        case 'WidgetQuery':
            query = WidgetQuery
            break;
        case 'ActionFlowQuery':
            query = ActionFlowQuery
            break;
        case 'MicroFlowQuery':
            query = MicroFlowQuery
            break;
        case 'RowQuery':
            query = RowQuery
            break;
        case 'ColumnQuery':
            query = ColumnQuery
            break;
        case 'NodeBusinessRuleQuery':
            query = NodeBusinessRuleQuery
            break;
        case 'DataParserQuery':
            query = DataParserQuery
            break;
        case 'HelpItemQuery':
            query = HelpItemQuery;
            break;
        case 'EmailTemplateQuery':
            query = EmailTemplateQuery;
            break;
        case 'ApplicationQuery':
            query = Applications;
            break;
        case 'RoleQuery':
            query = Roles;
            break;
        case 'DatabaseQuery':
            query = DatabaseQuery;
            break;
    }
    if (isServerEnv) {
        const jwtToken = userDetailsJwtKey()
        const authToken = yield call(authJwtKey);
        const client = getClient();
        return yield client.query({
            query: query,
            variables: { id: id },
            context: {
                headers: {
                    authorization: authToken,
                    userDetails: jwtToken,
                    provider: JSON.parse(process.env.AUTH_DETAILS!).provider.type,
                }
            }
        });
    } else {
        query = query.loc.source.body;
        query = query.trim();
        query = query.substring(query.indexOf("{"));
        query = query.substring(0, query.length);
        query = query.replace("$id", '"' + id + '"');
        //return yield graphqlquery(query);
    }
}
export function* getProcessedTreeData(tree: any, title: string, parentId: string) {
    let maxTreeLevel = processTree(tree, 1, title, null, parentId);
    return [[tree], maxTreeLevel];
}

function setProperties(tree, stage, title, parentType, parentId) {
    let key: any = Object.keys(tree);
    let childs = tree[key] ? Object.values(tree[key].child) : tree;

    tree.level = stage;
    tree.className = "";
    tree.id = uuid();
    tree.title = key[0];
    tree.key = stage + 1;
    tree.parentId = (stage === 3 ? parentId : "");
    tree.parentType = parentType;
    tree.configType = key[0];
    tree.screenType = (stage === 1 ? "ParentDataGrid" : "ChildDataGrid");
    tree.type = "Node";
    tree.nodeType = (stage === 1 ? "ParentNode" : "ChildNode");
    tree.expanded = (stage === 1 ? true : false);
    tree.children = [{
        level: stage + 1,
        className: "",
        id: uuid(),
        title: (stage === 1 ? title : key[0] + "_datanode"),
        key: stage + 2 + "_datanode",
        parentId: (stage === 1 ? parentId : ""),
        parentType: (parentType ? parentType : key[0]),
        configType: key[0],
        screenType: (stage === 1 ? "ParentForm" : "ChildForm"),
        type: "Node",
        nodeType: "DataNode",
        expanded: (stage === 1 ? true : false),
        children: "",
    }];

    delete tree[key];
    return childs;
}

function processTree(tree, stage, title, parentType, parentId) {
    let childsData: any = setProperties(tree, stage, title, parentType, parentId);
    let max = 0, num = 0;
    if (childsData.length != undefined) {
        tree.children[0].children = childsData;
        childsData.forEach((childData) => {
            num = processTree(childData, stage + 2, title, tree.title, parentId);
            max = num > max ? num : max;
        })
        return max;
    } else {
        childsData.children[0].type = "Leaf";
        return max = childsData.children[0].level;
    }
}

export function* expandTree(tree: any, configType: string, title: string, parentId: string, uuid: string) {
    terminate = false;
    expandTreeNode(tree, configType, title, parentId, uuid);
    return [tree];
}

export function* applicationRoleMicrosoftAd() {
    const response = yield call(api.applicationRoleMicrosoftAd);
    return response;
}

function matchConfigType(childTree, configType, title, parentId, uuid) {
    if (childTree.title === configType && childTree.id === uuid) {
        childTree.expanded = true;
        if (childTree.children) {
            childTree.children[0].expanded = true;
            childTree.children[0].title = title;
            childTree.children[0].parentId = parentId;
            let childs = childTree.children[0].children;
            if (childs) {
                childs.forEach((child) => {
                    child.parentId = parentId;
                })
            }
        }
        terminate = true;
        return [true];
    } else {
        return childTree.children ? [false, childTree.children] : [true];
    }
}

function expandTreeNode(tree, configType, title, parentId, uuid) {
    let result;
    if (tree.length != undefined) {
        tree.forEach((childTree) => {
            if (!terminate) {
                result = matchConfigType(childTree, configType, title, parentId, uuid);
                if (!result[0]) {
                    expandTreeNode(result[1], configType, title, parentId, uuid);
                }
            }
        })
    }
}