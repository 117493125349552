import React from 'react';
import Routes from './routes/Routes';
import { ThemeContextProvider } from './theme/ThemeContext';
import "./grid-layout.css";
const App = () => {
  return (
    
    <ThemeContextProvider>
      <Routes/>
    </ThemeContextProvider>
  
  );
}

export default App;
