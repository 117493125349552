import gql from 'graphql-tag';

export const MenuGroup = gql`
mutation createMenuGroup($input:MenuGroupInput)
 {
    createMenuGroup(input:$input) {
    name
    configObjectId
    configObjectType
    appHomeId
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    type
    leftMenuDefaultOpen
      childRelations {
          relationType
          childItemId
          isDeleted
        }
        parentRelations {
          relationType
          parentItemId
          isDeleted
        }
        privileges {
          privilegeType
          roleId
          isDeleted
        }        
    }
}`;
