import gql from 'graphql-tag';

export const PortalDataGridQuery = gql`
query PortalDataGrid($id:ID!){
    PortalDataGrid(id:$id){
        configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    nodeId
    ceId
    logicalEntityId
    dataGridId
    childRelations {
        relationType
        childItemId
        insert_ts
        update_ts
        createdBy
        updatedBy
     }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
        
    }
    
}`;