import React, {useState, useEffect} from 'react';
import {SelectOption, Form} from 'info-ui-library';
import {themes} from '../theme/ThemeOptions';
import {getSelectOptionDataFormat} from '../utils/SelectOptionUtils';
import {getConfigData} from '../utils/ConfigUtils';
import {getConfigDataByParentIdAndRelationType} from "../utils/ConfigUtils";
import { useDispatch, useSelector } from 'react-redux';
const handleSelectOptionComp = (data, setSelectedCompData,setFormData,formData) => {
    setSelectedCompData(data);
    let childRelations:any=formData;
    childRelations.childRelation= {...formData.childRelation,...{childItemId:data.value}};
    setFormData({ ...childRelations });
}

const PhysicalEntityRelationForm = (props : any) => {
    const [logicalEntities,
        setLogicalEntityData] = useState([
        {
            label: "-select-",
            value: "-select-"
        }
    ]);
    const [selectedLogicalEntity,
        setselectedLogicalEntityData] = useState({
            label: "-select-",
            value: "-select-"
        });
    const projectId = useSelector((state: any) => state.projectId);
    useEffect(() => {
        const getLogicalEntities = async() => {
            let gridData = await getConfigData('ConfigItemQuery', "LogicalEntity",projectId);
            if (props.type) {
                setLogicalEntityData(getSelectOptionDataFormat(gridData.data.ConfigItemByType))
            }
        }
        const getPhysicalEntity = async(selectedLogicalEntity) => {
            let physicalEntityData:any = await getConfigDataByParentIdAndRelationType(selectedLogicalEntity,"LogicalEntity_PhysicalEntity" ,projectId);
            if (selectedLogicalEntity.value!=="-select-") {
                props.setCompData(getSelectOptionDataFormat(physicalEntityData.data.ConfigItemByTypeAndParentID))
            }
        }
        getLogicalEntities();
        getPhysicalEntity(selectedLogicalEntity);
    }, [props.type,selectedLogicalEntity])

    const FormComponent = <CommonComponent type={props.type}
    setselectedLogicalEntityData={setselectedLogicalEntityData}
        setCompData={props.setCompData}
        compData={props.compData}
        setFormData={props.setFormData} 
        formData={props.formData}
        setSelectedCompData={props.setSelectedCompData}
        selectedCompData={props.selectedCompData}
        selectedLogicalEntity={selectedLogicalEntity}
        logicalEntities={logicalEntities}
        errorData={props.errorData}
        setErrorData={props.setErrorData}/>
    try {
        return (
            <Form className={props.className}>
                {FormComponent}
            </Form>
        )
    } catch (e) {
        return <div></div>
    }
}
const handleSelectLogicalEntity = (value,type, setselectedLogicalEntityData,
     setSelectedCompData,
    setFormData,formData,configType) => {
    setSelectedCompData("-select-")
    setselectedLogicalEntityData(value);
    let childRelations:any=formData;
    childRelations.childRelation= {...formData.childRelation,...{relationType:configType+"_"+type}};
    setFormData({ ...childRelations });
}

const CommonComponent = (props) => {
    const screenDetails = useSelector((state: any) => state.screenDetails); 
    return <div>
            <SelectOption
                styleName='primary'
                ioTheme={themes[0]}
                ioMode='light'
                options={props.logicalEntities}
                label='LogicalEntity'
                configId={'LogicalEntity'}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectLogicalEntity(value.value,props.type,
                     props.setselectedLogicalEntityData, 
                     props.setSelectedCompData,props.setFormData
                     ,props.formData,screenDetails.configType)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError={props.errorData ?true:false}
                errorMessage={props.errorData?"Is Required":null}/>

            <SelectOption
                styleName='primary'
                ioTheme={themes[0]}
                value={props.selectedCompData}
                ioMode='light'
                options={props.compData}
                label='PhysicalEntity'
                configId={'PhysicalEntity'}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectOptionComp(value,
                     props.setSelectedCompData,props.setFormData
                     ,props.formData)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError={props.errorData ?true:false}
                errorMessage={props.errorData?"Is Required":null}/>
        </div>
}

export default(PhysicalEntityRelationForm);
