import gql from 'graphql-tag';

export const DatabaseQuery = gql`
 query Database($id: ID!)
 {
    Database(id:$id) {
    host
    port
    dbname
    user
    password
    engine
    features
    attachmentProvider
    awsAccessKey
    awsSeceretKey
    awsRegion
    awsTempBucket
    awsBucket
    awsDeleteBucket
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
       childRelations {
  relationType
  childItemId
  insert_ts
  update_ts
  createdBy
  updatedBy
}
parentRelations {
  relationType
  parentItemId
  insert_ts
  update_ts
  createdBy
  updatedBy
}
privileges {
  privilegeType
  roleId
  insert_ts
  update_ts
  createdBy
  updatedBy
}

  
    }
}
`;