export default {
    "MenuGroup": {
        "child": [
            {
                "Menu": {
                    "child": [
                        {
                            "Menu": {
                                "child": [
                                    {
                                        "Menu": {
                                            "child": [
                                                {
                                                    "Menu": {
                                                        "child": [
                                                            {
                                                                "Menu": {
                                                                    "child": [
                                                                        {
                                                                            "Menu": {
                                                                                "child": [
                                                                                    {
                                                                                        "Menu": {
                                                                                            "child": [
                                                                                                {
                                                                                                    "Menu": {
                                                                                                        "child": [
                                                                                                            {
                                                                                                                "Menu": {
                                                                                                                    "child": [
                                                                                                                        {
                                                                                                                            "Menu": null
                                                                                                                        },
                                                                                                                        {
                                                                                                                            "ActionFlow": {
                                                                                                                                "child": [
                                                                                                                                    {
                                                                                                                                        "MicroFlow": null
                                                                                                                                    }
                                                                                                                                ]
                                                                                                                            }
                                                                                                                        }
                                                                                                                    ]
                                                                                                                }
                                                                                                            },
                                                                                                            {
                                                                                                                "ActionFlow": {
                                                                                                                    "child": [
                                                                                                                        {
                                                                                                                            "MicroFlow": null
                                                                                                                        }
                                                                                                                    ]
                                                                                                                }
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                },
                                                                                                {
                                                                                                    "ActionFlow": {
                                                                                                        "child": [
                                                                                                            {
                                                                                                                "MicroFlow": null
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        "ActionFlow": {
                                                                                            "child": [
                                                                                                {
                                                                                                    "MicroFlow": null
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            }
                                                                        },
                                                                        {
                                                                            "ActionFlow": {
                                                                                "child": [
                                                                                    {
                                                                                        "MicroFlow": null
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            },
                                                            {
                                                                "ActionFlow": {
                                                                    "child": [
                                                                        {
                                                                            "MicroFlow": null
                                                                        }
                                                                    ]
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    "ActionFlow": {
                                                        "child": [
                                                            {
                                                                "MicroFlow": null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "ActionFlow": {
                                            "child": [
                                                {
                                                    "MicroFlow": null
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "ActionFlow": {
                                "child": [
                                    {
                                        "MicroFlow": null
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "ButtonPanel": {
                    "child": [
                        {
                            "Button": {
                                "child": [
                                    {
                                        "ActionFlow": {
                                            "child": [
                                                {
                                                    "MicroFlow": null
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "MenuButton": {
                                "child": [
                                    {
                                        "Button": {
                                            "child": [
                                                {
                                                    "ActionFlow": {
                                                        "child": [
                                                            {
                                                                "MicroFlow": null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },

                                ]
                            }
                        }
                    ]
                }
            }
        ]
    }
}