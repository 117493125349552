import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../user-context/UserContext';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RadioButton } from 'info-ui-library';
import { themes } from '../theme/ThemeOptions';
import { ThemeContext } from '../theme/ThemeContext';

const selectOptions = {
    'EDIT': 'Edit',
    'VIEW': 'View',
    'NO PRIVILEGE': 'No Privilege'
}

const ParentWrapperContainer = styled.div`
justify-content: center;
align-items: center;
-webkit-box-align: center;
display: flex;
flex-wrap: wrap;
`;

const RoleWrapper = styled.div`
justify-content: left;
align-items: left;
-webkit-box-align: right;
display: flex;
margin:0px;
flex-wrap: wrap;
`;

const Privilege = props => {
    const roleJson = useSelector((state: any) => state.currentApplicationRoleList);
    const rcPriviledge = <CommonRoleGeneration setFormData={props.setFormData} formData={props.formData} roles={roleJson} />
    try {
        return (
            <ParentWrapperContainer>
                {rcPriviledge}
            </ParentWrapperContainer>
        )
    } catch (e) {
        return <div></div>
    }
}

const CommonRoleGeneration = (props) => {
    // props.setFormData({privilege:roleMap});
    const themeContext = useContext(ThemeContext);
    useEffect(() => {
        if (props.formData.privilege === undefined || (props.formData.privilege && props.formData.privilege.length === 0)) {
            const roleList: any = [];
            props.roles.map((role) => {
                    let roleMap = {
                        roleId: role.roleId,
                        privilegeType: "NO PRIVILEGE"
                    };
                    roleList.push(roleMap);
                });
            let roleData = props.formData;
            roleData.privilege = [];
            roleData.privilege.push.apply(roleData.privilege, roleList);
            props.setFormData({ ...roleData });
        }
    }, [props.formData.privilege])

    return props.roles.map(role => {
            return <RoleWrapper>
                <RadioButton
                    styleName={themeContext.styleName}
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    label={role.roleName}
                    configId={role.roleName}
                    key={role.roleName}
                    name="radio"
                    onChange={(e) => onChange(e, role.roleId, props.setFormData, props.formData)}
                    onBlur={(e) => handleBlur(e)}
                    id={role.roleId}
                    style={{
                        'display': 'flex'
                    }}
                    errorMessage='Required'
                    options={selectOptions}
                    value={getPrivilegeValue(props.formData.privilege, role.roleId)}
                    displayType='FloatingLabel' />
            </RoleWrapper>
        })
}

const getPrivilegeValue = (privilegeList, roleId) => {
    let selectedPrivilege = "NO PRIVILEGE"
    privilegeList.map((privilege) => {
        if (privilege.roleId === roleId) {
            selectedPrivilege = privilege.privilegeType
        }
    });
    return selectedPrivilege;
}

const onChange = (e, roleId, setFormData, formData) => {
    let roleMap = formData;
    //@Zinel: Changed the privilege datatype from map to list of map as audit columns were required while perofrming upsert action
    //roleMap.privilege[roleName] = e;
    let newRoleId = true;
    for (const privilege of roleMap.privilege) {
        if (privilege.roleId === roleId) {
            privilege.privilegeType = e;
            newRoleId=false;
        }
    }
    if(newRoleId){
       let roleMapValue = {
            roleId: roleId,
            privilegeType: e,
            __typename: "ConfigItemPrivilege"
        }
        roleMap.privilege.push(roleMapValue)  
    }
    setFormData({ ...roleMap });
    //     setFormData({
    //         privilege: {
    //             ...formData.privilege,
    //             [roleName]: e
    //         },
    //         property: formData.property,
    //         item: formData.item
    // });
}
const handleBlur = (e) => {
    //console.log('handleblur of radio button is called')
}

export default Privilege;