import gql from 'graphql-tag';

export const EmailTemplateQuery = gql`
query EmailTemplate($id: ID!)
 {
    EmailTemplate(id:$id) {
    configObjectId
    to
    cc
    bcc
    subject
    content
    attachmentKey
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges {
            privilegeType
            roleId
            isDeleted
            insert_ts
            update_ts
            createdBy
            updatedBy
        }
        parentRelations {
            parentItemId
            relationType
            isDeleted
            insert_ts
            update_ts
            createdBy
            updatedBy
          }
        childRelations {
            childItemId
            relationType
            isDeleted
            insert_ts
            update_ts
            createdBy
            updatedBy
          } 
    }
}
`;