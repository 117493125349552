import gql from 'graphql-tag';

export const Widget = gql`
mutation createWidget($input:WidgetInput)
 {
    createWidget(input:$input) {
    configObjectId
    widgetType
    name
    settings
    series
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    datasetquery
    databaseid
    tableid
    datasource
    querytype
    resultmetadata
    xlabel
    ylabel
    formId
    chartClickOnRefresh
    dataParser
    filterOption
    filterRequired
    searchRequired
    cardsPerRow
    widgetClickable
    defaultSelected
    childRelations
            {
              childItemId
              relationType
              isDeleted
            }
            parentRelations
                {
                  parentItemId
                  relationType
                  isDeleted
                }
        privileges{
            privilegeType
            roleId
            isDeleted
        }
    }
}
`;