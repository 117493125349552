import React,{useState,useContext} from 'react';
import {Modal, FormErrorContainers, Button, Form} from 'info-ui-library';
import {themes} from '../theme/ThemeOptions';
import ChildRelationForm from './ChildRelationForm';
import { ThemeContext } from '../theme/ThemeContext';
const handleClose = (SetModalVisible) => {
    SetModalVisible(false)
}
const handleClick = (SetModalVisible,setRelationFormData,relationFormData,setErrorData,setFormData,formData) => {
    SetModalVisible(false);
    setRelationFormData({childRelation:{}})
    let childRelations=formData;
    if (Object.keys(relationFormData.childRelation).length ===0) {
                setErrorData(true)
                SetModalVisible(true);
    }else if(relationFormData.childRelation.childItemId === undefined){
                setErrorData(true)
                SetModalVisible(true);
    }else{
        childRelations.childRelation.push({...relationFormData.childRelation,isDeleted:0});
        setFormData({ ...childRelations });
    }

}

const ChildRelationModal = (props : any) => {
    const themeContext = useContext(ThemeContext);
    const [relationFormData, setRelationFormData] = useState({childRelation:{}});
    const [errorData, setErrorData] = useState(false);
    return (
        <Modal
        styleName={themeContext.styleName}
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
            id={'dfhgj-45t3dfg'}
            header='Add Child Relation'
            toggle={() => handleClose(props.SetModalVisible)}
            isOpen={true}
            footer={< Button  styleName={themeContext.styleName}
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            id = "save" disabled = {false}
            name = "Save"
            onClick = {(e) => handleClick(props.SetModalVisible,setRelationFormData,relationFormData,setErrorData,props.setFormData,props.formData)} />}
            >
                <ChildRelationForm setFormData={setRelationFormData} formData={relationFormData} errorData={errorData} setErrorData={setErrorData}/>
            </Modal>
    );
}

export default(ChildRelationModal);
