import React from 'react';
import { createStorage } from '../storage';
import config from '../config/index';
import { cloneDeep } from 'lodash';
import { APP_LOGGED_IN_ROLE_ID, USER_SESSION_LOCAL_STORAGE_KEY, APP_LOGGED_IN_YEAR } from '../constants/appeng.enum';

interface IUserContextProps {
    getCurrentRole: any;
    getCurrentFinacialYear: any;
    getUserDetail: any;
}

const getUserSession = () =>{
    const storage = createStorage(config);
    //console.log('this is user session context method',storage)
    const userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
    const userProfileMap = JSON.parse(userProfile ? userProfile : "{}");
    const usersession = cloneDeep(userProfileMap);
    delete usersession.AdditionalDetails;
    usersession.APP_CURRENT_DATE = new Date();
    Object.assign(usersession, userProfileMap.AdditionalDetails);
    return usersession;
}



const getCurrentRoleContext = () => {
    const usersession = getUserSession();
    return String(usersession[APP_LOGGED_IN_ROLE_ID]).split(',')
}

const getCurrentFinacialYearContext = () => {
    const usersession = getUserSession();
    return usersession[APP_LOGGED_IN_YEAR]
}

const getUserDetailContext = () => {
    const usersession = getUserSession();
    return usersession;
}

const createDefaultTheme = (): IUserContextProps => ({
    getCurrentRole: getCurrentRoleContext,
    getCurrentFinacialYear: getCurrentFinacialYearContext,
    getUserDetail: getUserDetailContext
});

export const UserContext = React.createContext<IUserContextProps>(
    createDefaultTheme()
);

export const UserContextProvider: React.FC = props => {
    return (
        <UserContext.Provider
            value={{
                getCurrentRole: getCurrentRoleContext,
                getCurrentFinacialYear: getCurrentFinacialYearContext,
                getUserDetail: getUserDetailContext
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};
