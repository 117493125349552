import formProperty from '../components/mockData/property/formProperty.json';
import datagridProperty from '../components/mockData/property/datagridProperty.json';
import formSectionProperty from '../components/mockData/property/formSectionProperty.json';
import logicalEntityOperationInputProperty from '../components/mockData/property/logicalEntityOperationInputProperty.json';
import logicalEntityProperty from '../components/mockData/property/logicalEntityProperty.json';
import menuButtonProperty from '../components/mockData/property/menuButtonProperty.json';
import menuGroupProperty from '../components/mockData/property/menuGroupProperty.json';
import menuProperty from '../components/mockData/property/menuProperty.json';
import nodeBusinessRuleProperty from '../components/mockData/property/nodeBusinessRuleProperty.json';
import physicalColumnProperty from '../components/mockData/property/physicalColumnProperty.json';
import physicalEntityProperty from '../components/mockData/property/physicalEntityProperty.json';
import portalCardProperty from '../components/mockData/property/portalCard/portalCardProperty.json';
import formSection from '../components/mockData/property/portalCard/formSection.json';
import formField from '../components/mockData/property/portalCard/formField.json';
import hybridEntityProperty from '../components/mockData/property/portalCard/hybridEntityProperty.json';
import featureManagementSortableTree from '../components/mockData/property/portalCard/featureManagementSortableTree.json';
import sideNodeNavigationProperty from '../components/mockData/property/portalCard/sideNodeNavigationProperty.json';
import portalCardDataGridProperty from '../components/mockData/property/portalCard/portalCardDataGridProperty.json';
import portalCardFormProperty from '../components/mockData/property/portalCard/portalCardFormProperty.json';
import portalChartsProperty from '../components/mockData/property/portalChartsProperty.json';
import portalDataGridProperty from '../components/mockData/property/portalDataGridProperty.json';
import portalFilterFormProperty from '../components/mockData/property/portalFilterFormProperty.json';
import portalFormProperty from '../components/mockData/property/portalFormProperty.json'
import portalProperty from '../components/mockData/property/portalProperty.json';
import subPortalProperty from '../components/mockData/property/subPortalProperty.json';
import tabPortalProperty from '../components/mockData/property/tabPortalProperty.json';
import rootCompositeEntityNodeProperty from '../components/mockData/property/rootCompositeEntityNodeProperty.json';
import StandardValidation from '../components/mockData/property/standardValidationProperty.json'
import button from '../components/mockData/property/button.json';
import buttonPanel from '../components/mockData/property/buttonPanel.json';
import columndatapreprocessor from '../components/mockData/property/columndatapreprocessor.json';
import compositeEntityNode from '../components/mockData/property/compositeEntityNode.json';
import customFormValidationObject from '../components/mockData/property/customformValidation.json';
import dataBaseValidation from '../components/mockData/property/dataBaseValidation.json';
import datagridColumn from '../components/mockData/property/datagridColumn.json';
import datasetRelproperty from '../components/mockData/property/datasetRelproperty.json';
import datasource from '../components/mockData/property/datasource.json';
import dboperation from '../components/mockData/property/dboperation.json';
import formdbvalidation from '../components/mockData/property/formdbvalidation.json';
import language from '../components/mockData/property/language.json';
import logicalcolumn from '../components/mockData/property/logicalcolumn.json';
import textBoxProperty from '../components/mockData/property/formField/textBoxProperty.json';
import selectOptionProperty from '../components/mockData/property/formField/selectOptionProperty.json';
import hiddenFieldProperty from '../components/mockData/property/formField/hiddenFieldProperty.json'
import textAreaProperty from '../components/mockData/property/formField/textAreaProperty.json';
import radiobuttonProperty from '../components/mockData/property/formField/radioButtonProperty.json';
import checkBoxProperty from '../components/mockData/property/formField/checkboxProperty.json';
import datePickerProperty from '../components/mockData/property/formField/datePickerProperty.json';
import timePickerProperty from '../components/mockData/property/formField/timePickerProperty.json';
import ckEditorProperty from '../components/mockData/property/formField/ckEditorProperty.json';
import multiSelectProperty from '../components/mockData/property/formField/multiSelectProperty.json';
import passwordProperty from '../components/mockData/property/formField/passwordProperty.json';
import profilePicProperty from '../components/mockData/property/formField/profilePicProperty.json';
import dropzoneProperty from '../components/mockData/property/formField/dropzoneProperty.json';
import tabGroupProperty from '../components/mockData/property/tabGroupProperty.json';
import microFlow from '../components/mockData/property/microFlow.json';
import rowProperty from '../components/mockData/property/rowProperty.json';
import columnProperty from '../components/mockData/property/columnProperty.json';
import labelProperty from '../components/mockData/property/formField/labelProperty.json';
import worldcontainerProperty from '../components/mockData/property/formField/worldcontainerProperty.json';
import statuscontainerProperty from '../components/mockData/property/formField/statuscontainerProperty.json';
import dataParserProperty from '../components/mockData/property/dataParserProperty.json';
import dateTimePickerProperty from '../components/mockData/property/formField/dateTimePickerProperty.json';
import helpItemProperty from '../components/mockData/property/helpItemProperty.json';
import phoneNumberProperty from '../components/mockData/property/formField/phoneNumber.json';
import emailTemplateProperty from '../components/mockData/property/emailTemplateProperty.json';
import databaseProperty from '../components/mockData/property/database.json';

export const getPropertyByConfigType = (configType) => {
    switch (configType) {
        case "Form":
            return formProperty;
        case "DataGrid":
            return datagridProperty;
        case "FormSection":
            return formSectionProperty;
        case "LogicalEntityOperation":
            return logicalEntityOperationInputProperty;
        case "LogicalEntity":
            return logicalEntityProperty;
        case "MenuButton":
            return menuButtonProperty;
        case "MenuGroup":
            return menuGroupProperty;
        case "Menu":
            return menuProperty;
        case "NodeBusinessRule":
            return nodeBusinessRuleProperty;
        case "PhysicalColumn":
            return physicalColumnProperty;
        case "PhysicalEntity":
            return physicalEntityProperty;
        case "PortalCard":
            return portalCardProperty;
        case "Widget":
            return portalChartsProperty;
        case "PortalDataGrid":
            return portalDataGridProperty;
        case "PortalFilterForm":
            return portalFilterFormProperty;
        case "PortalForm":
            return portalFormProperty;
        case "Portal":
            return portalProperty;
        case "RootCompositeEntityNode":
            return rootCompositeEntityNodeProperty;
        case "StandardValidation":
            return StandardValidation;
        case "Button":
            return button;
        case "ButtonPanel":
            return buttonPanel;
        case "ColumnDataPreprocessor":
            return columndatapreprocessor;
        case "CompositeEntityNode":
            return compositeEntityNode;
        case "CustomFormValidation":
            return customFormValidationObject;
        case "DatabaseValidation":
            return dataBaseValidation;
        case "DataGridColumn":
            return datagridColumn;
        case "DataSetRelProperty":
            return datasetRelproperty;
        case "DataSource":
            return datasource;
        case "DbOperation":
            return dboperation;
        case "FormDbValidation":
            return formdbvalidation;
        case "Language":
            return language;
        case "LogicalColumn":
            return logicalcolumn;
        case "SubPortal":
            return subPortalProperty;
        case "TabPortal":
            return tabPortalProperty;
        case "MicroFlow":
            return microFlow;
        case "TabGroup":
            return tabGroupProperty;
        case "Row":
            return rowProperty;
        case "Column":
            return columnProperty;
        case "DataParser":
            return dataParserProperty;
        case "HelpItem":
            return helpItemProperty;
        case "EmailTemplate":
            return emailTemplateProperty;
        case "Database":
            return databaseProperty
        default:
            return [];
    }
}


export const getPropertyByFormFieldType = (formFieldType) => {
    switch (formFieldType) {
        case "TextBox":
            return textBoxProperty;
        case "SelectOption":
            return selectOptionProperty;
        case "Hiddenfield":
            return hiddenFieldProperty;
        case "TextArea":
            return textAreaProperty;
        case "RadioButton":
            return radiobuttonProperty;
        case "CheckBox":
            return checkBoxProperty;
        case "DatePicker":
            return datePickerProperty;
        case "TimePicker":
            return timePickerProperty;
        case "CkEditor":
            return ckEditorProperty;
        case "ProfilePic":
            return profilePicProperty;
        case "Password":
            return passwordProperty;
        case "MultiSelect":
            return multiSelectProperty;
        case "Dropzone":
            return dropzoneProperty;
        case "StatusContainer":
            return statuscontainerProperty;
        case "WorldContainer":
            return worldcontainerProperty;
        case "Label":
            return labelProperty;
        case "DateTimePicker":
            return dateTimePickerProperty;
        case "PhoneNumber":
            return phoneNumberProperty;
    }
}

export const getPropertyByPortalCardType = (type) => {
    switch (type) {
        case 'HybridEntity':
            return hybridEntityProperty;
        case 'PortalForm':
            return portalCardFormProperty;
        case 'PortalDataGrid':
        case 'Widget':
            return portalCardDataGridProperty;
        case 'SideNodeNavigation':
            return sideNodeNavigationProperty;
        case 'FormSection':
            return formSection;
        case 'FormField':
            return formField;
        case 'FeatureManagementSortableTree':
            return featureManagementSortableTree;
        default:
            return [];
    }
}