import ActionButton from "../components/editableGridComponents/ActionButton";
import ExpanderForSubRows from "../components/dataGridComponents/ExpanderForSubRows";
import DatePickerCell from "../components/editableGridComponents/DatePickerCell";
import DatePickerFilter from "../components/filterGridComponents/DatePickerFilter";
import { filterTypes } from "./DefaultConstants";
export const columns =
    (handleClick , themeContext, props) => [
        //{
        //     width: 100,
        //     Header: () => null,
        //     id: 'expander',
        //     Cell: ExpanderForSubRows,
        //     isAccessible: true
        // },
        {
            filterEnable: "true",
            width: 230,
            Header: 'Item ID',
            accessor: 'configObjectId',
            isAccessible: true
        },
        {
            filterEnable: "true",
            width: 230,
            Header: 'Item Name',
            accessor: 'name',
            isAccessible: true
        },
        {
            filterEnable: "true",
            width: 230,
            Header: 'Updated By',
            accessor: 'updatedBy',
            isAccessible: true
        },
        {
            filterEnable: "true",
            width: 230,
            Header: 'Updation Date',
            accessor: 'update_ts',
            isAccessible: true,
            Cell: DatePickerCell,
            dateFormat: "d-MMM-yyyy",
            Filter: DatePickerFilter
        },
        {
            width: 140,
            Header: 'Action',
            accessor: 'actionColumn',
            isAction: true,
            filterEnable: false,
            isAccessible: true,
            Cell: ActionButton,
            styleName: themeContext.styleName,
            ioTheme: themeContext.ioTheme,
            ioMode: themeContext.ioMode,
            buttons: [{ id: "1", HeaderName: "", icon: "fa fa-edit",actionColumnType:"edit",parentProps: props, onClickHandler: handleClick , isAccessible: true },
            { id: "2", HeaderName: "", icon: "fa fa-trash-o",actionColumnType:"delete",parentProps: props, onClickHandler: handleClick, isAccessible: true,disabled:true }]
        }

    ]
