import gql from 'graphql-tag';
 
 export const NodeBusinessRuleQuery= gql`
 query NodeBusinessRule($id:ID!)
 {
     NodeBusinessRule(id:$id){
    configObjectId
    executionType
    rule
    order
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
      childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
         
         
     }
 }`;