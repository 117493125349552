import { IState } from '../store/index';
import {
  SET_FORM_DATA, SET_SCREEN_DETAILS, REMOVE_SCREEN_DETAILS_HISTORY, SET_ERROR_DATA,
  SET_SIDE_TREE, REMOVE_SIDE_TREE, SET_GRID_DATA, SET_DATA_SOURCE_IN_SELECT_OPTION,
  SET_FORM_DATA_IN_SELECT_OPTION, SET_GRID_DATA_IN_SELECT_OPTION, SET_TOAST_MESSAGE,
  SET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION,SET_CHANGE_FORM_DATA,SET_PORTAL_IN_SELECT_OPTION,
  SET_COMPOSITE_ENTITY_IN_SELECT_OPTION,SET_CURRENT_PROJECT,
  SET_PROJECT_OPTIONS,SET_STORE,
  SET_PORTAL_CARD_IN_SELECT_OPTION
} from '../actions/index';
export default function reducer(
  state: IState | null | undefined,
  actions
) {
  if (!state) {
    return null;
  }

  switch (actions.type) {
    case SET_DATA_SOURCE_IN_SELECT_OPTION: {
      const existingSelectOptionDetails = { ...state.selectOptionDetails };
      existingSelectOptionDetails.dataSourceSelectOption = actions.dataSourceDetails;
      return {
        ...state,
        selectOptionDetails: { ...existingSelectOptionDetails }
      }
    }
    case SET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION:{
      const existingSelectOptionDetails = { ...state.selectOptionDetails };
      existingSelectOptionDetails.rootcompositeEntityNodeSelectOption = actions.nodeid;
      return {
        ...state,
        selectOptionDetails: { ...existingSelectOptionDetails }
      }
    }
    case SET_FORM_DATA_IN_SELECT_OPTION: {
      const existingSelectOptionDetails = { ...state.selectOptionDetails };
      existingSelectOptionDetails.formSelectOption = actions.formDetails;
      return {
        ...state,
        selectOptionDetails: { ...existingSelectOptionDetails }
      }
    }
    case SET_PORTAL_IN_SELECT_OPTION:{
      const existingSelectOptionDetails = { ...state.selectOptionDetails };
      existingSelectOptionDetails.portalSelectOption = actions.portals;
      return {
        ...state,
        selectOptionDetails: { ...existingSelectOptionDetails }
      }
    }
    case SET_PORTAL_CARD_IN_SELECT_OPTION:{
      const existingSelectOptionDetails = { ...state.selectOptionDetails };
      existingSelectOptionDetails.portalCardSelectOption = actions.portalCards;
      return {
        ...state,
        selectOptionDetails: { ...existingSelectOptionDetails }
      }
    }
    case SET_COMPOSITE_ENTITY_IN_SELECT_OPTION:{
      const existingSelectOptionDetails = { ...state.selectOptionDetails };
      existingSelectOptionDetails.compositeEntitySelectOption = actions.ceid;
      return {
        ...state,
        selectOptionDetails: { ...existingSelectOptionDetails }
      }
    }
    case SET_GRID_DATA_IN_SELECT_OPTION: {
      const existingSelectOptionDetails = { ...state.selectOptionDetails };
      existingSelectOptionDetails.gridSelectOption = actions.gridDetails;
      return {
        ...state,
        selectOptionDetails: { ...existingSelectOptionDetails }
      }
    }
    case SET_FORM_DATA: {
      const existingcards = { ...state.cardsData };
      existingcards[actions.cardId] = actions.cardData;
      return {
        ...state,
        cardsData: actions.cardData
      }
    }
    case SET_GRID_DATA: {
      const existingcards = { ...state.cardsData };
      existingcards[actions.cardId] = actions.cardData;
      return {
        ...state,
        cardsData: actions.cardsData
      }
    }
    case SET_SCREEN_DETAILS: {
      let screenDetailsHistoryList = state.screenDetailsHistory;

      if (actions.historyEnable) {
        screenDetailsHistoryList.push({ ...state.screenDetails });

      }
      if (state.screenDetailsHistory.length > 10) {
        screenDetailsHistoryList.splice(1)
      }
      return {
        ...state,
        screenDetails: { ...actions.screenDetails },
        screenDetailsHistory: screenDetailsHistoryList
      }

    }

    case REMOVE_SCREEN_DETAILS_HISTORY:
      return {
        ...state,
        screenDetailsHistory: actions.screenDetailsHistory
      }
    case SET_ERROR_DATA:
      return {
        ...state,
        errorData: actions.errorData
      }

    case SET_SIDE_TREE:
    case REMOVE_SIDE_TREE:
      return {
        ...state,
        sideNodeTree: { ...state.sideNodeTree, treeData: { ...actions.sortableTreeData } }
      }

    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toggleMessages: [...actions.message]
      }
    case SET_CHANGE_FORM_DATA: {
      const existingcards = { ...state.cardsData };
      let cardData = { ...existingcards };
      cardData.data[actions.itemType][actions.dbCode] = actions.changedValue;
      return {
        ...state,
        cardsData: cardData
      }
    }
    case SET_CURRENT_PROJECT: {
      return {
        ...state,
        projectId :actions.projectId
      }
    }
    case SET_PROJECT_OPTIONS: {
      return {
        ...state,
        projectOptions :actions.projectOptions
      }
    }
    case SET_STORE: {
      return {
        ...state, ...actions.store
      }
    }
    default:
      return state;
  }
}
