import gql from 'graphql-tag';

export const WidgetQuery = gql`
query Widget($id:ID!){
    Widget(id:$id){
    configObjectId
    widgetType
    name
    settings
    series
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    datasetquery
    databaseid
    tableid
    datasource
    querytype
    resultmetadata
    xlabel
    ylabel
    formId
    chartClickOnRefresh
    dataParser
    filterOption
    filterRequired
    searchRequired
    cardsPerRow
    widgetClickable
    defaultSelected
    childRelations {
        relationType
        childItemId
        insert_ts
        update_ts
        createdBy
        updatedBy
     }
     parentRelations {
        relationType
        parentItemId
        insert_ts
        update_ts
        createdBy
        updatedBy
      }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    }
}`;