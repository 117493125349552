import React, {useState, useEffect,useContext} from 'react';
import {SelectOption, Form} from 'info-ui-library';
import {themes} from '../theme/ThemeOptions';
import {Row, Col} from '@bootstrap-styled/v4';
import {getSelectOptionDataFormat} from '../utils/SelectOptionUtils';
import {getConfigData} from '../utils/ConfigUtils';
import {getParentRelationsOptionByConfigType} from '../utils/Relation.utils';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from '../theme/ThemeContext';

const handleSelectOptionType = (value, setType, setCompData, setSelectedCompData,
    setFormData,formData,configType) => {
    setSelectedCompData("-select-")
    setType(value);
    let parentRelations:any=formData;
    let relationType=value!=="ParentNode"?{relationType:value+"_"+configType}:{relationType:"ParentNode_ChildNode"};
    parentRelations.parentRelation= {...formData.parentRelation,...relationType};
    setFormData({ ...parentRelations });
}

const handleSelectOptionComp = (data, setSelectedCompData,setFormData,formData) => {
    setSelectedCompData(data);
    let parentRelations:any=formData;
    parentRelations.parentRelation= {...formData.parentRelation,...{parentItemId:data.value}};
    setFormData({ ...parentRelations });
}

const ParentRelationForm = (props : any) => {
    const [type,
        setType] = useState('');
    const [compData,
        setCompData] = useState([
        {
            label: "-select-",
            value: "-select-"
        }
    ]);
    const [selectedCompData,
        setSelectedCompData] = useState({
            label: "-select-",
            value: "-select-"
        });
    const projectId = useSelector((state: any) => state.projectId);
    useEffect(() => {
        const getGridData = async(configType) => {
            let gridData:any={};
            let parentOptions:any={};
            if(configType!=="ParentNode"){
             gridData = await getConfigData('ConfigItemQuery', configType,projectId);
            }else{
            gridData = await getConfigData('ConfigItemQuery', "CompositeEntityNode",projectId);
            parentOptions = await getConfigData('ConfigItemQuery', "RootCompositeEntityNode",projectId)
            }
            if (type) {
                let options:any=[]
                options=getSelectOptionDataFormat(gridData.data.ConfigItemByType);
                if(Object.keys(parentOptions).length>0){
                   options=[...options,...getSelectOptionDataFormat(parentOptions.data.ConfigItemByType)]
                }
                setCompData(options);
            }
        }
        getGridData(type);
    }, [type])

    const FormComponent = <CommonComponent
        type={type}
        setType={setType}
        setCompData={setCompData}
        compData={compData}
        setFormData={props.setFormData} 
        formData={props.formData}
        setSelectedCompData={setSelectedCompData}
        selectedCompData={selectedCompData}
        errorData={props.errorData}
        setErrorData={props.setErrorData}/>
    try {
        return (
            <Form className={props.className}>
                {FormComponent}
            </Form>
        )
    } catch (e) {
        return <div></div>
    }
}

const CommonComponent = (props) => {
const themeContext = useContext(ThemeContext);
    const screenDetails = useSelector((state: any) => state.screenDetails); 
    const options:any =getParentRelationsOptionByConfigType(screenDetails.configType);
    return <Row>
        <Col sm='6'>
            <SelectOption
                styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                options={options}
                label='Type'
                configId={'Type'}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectOptionType(value.value,
                     props.setType, props.setCompData, 
                     props.setSelectedCompData,props.setFormData
                     ,props.formData,screenDetails.configType)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError={props.errorData && props.type===""  ?true:false}
                errorMessage={props.errorData?"Is Required":null}/>

        </Col>
        <Col>
            <SelectOption
                styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                value={props.selectedCompData}
                options={props.compData}
                label='Component'
                 configId={'Component'}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectOptionComp(value,
                     props.setSelectedCompData,props.setFormData
                     ,props.formData)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError={props.errorData ?true:false}
                errorMessage={props.errorData?"Is Required":null}/>
        </Col>
    </Row>
}

export default(ParentRelationForm);
