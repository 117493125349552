import gql from 'graphql-tag';

export const ConfigItemByParentIdAndRelationType = gql`
  query ConfigItemByTypeAndParentID($type: String!,$parentID: String!,$projectId: Int!)
  {
    ConfigItemByTypeAndParentID(type:$type,parentID:$parentID,projectId:$projectId) {
        configObjectId
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
  }
  }`;
