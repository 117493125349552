
import { DataSharingComponent, Modal } from 'info-ui-library';
import React, { useState, useContext, useEffect } from 'react';
import { themes } from '../theme/ThemeOptions';
import { ThemeContext } from '../theme/ThemeContext';
import DataSharingRowComponent from './DataSharingRowComponent';

const DataSharingComponentCore: React.FC<any> = (props: any) => {
    const themeContext = useContext(ThemeContext);
    const dataSharing = parseStringToObject(props.dataSharingValue);
    const [dataMap, setDataMap] = useState(dataSharing);

    // useEffect(() => {
    //     const updatedDataSharing = parseStringToObject(props.dataSharingValue);
    //     setDataMap(updatedDataSharing);
    // }, [props.dataSharingValue])

    const handleClick = (actionType, index) => {
        switch (actionType) {
            case 'add': {
                if (Object.keys(dataMap).length) {
                    const list = { ...dataMap };
                    list[Object.keys(list).length] = {
                        SOURCE_PORTAL_CARD_ID: "",
                        SOURCE_PORTAL_CARD_DBCODE: "",
                        TARGET_PORTAL_CARD_ID: "",
                        TARGET_PORTAL_CARD_DBCODE: ""
                    }
                    setDataMap(list);
                    // const updatedData=parseObjectToString(list);
                    // props.setData(updatedData)

                } else {
                    const list: any = {};
                    list[0] = {
                        SOURCE_PORTAL_CARD_ID: "",
                        SOURCE_PORTAL_CARD_DBCODE: "",
                        TARGET_PORTAL_CARD_ID: "",
                        TARGET_PORTAL_CARD_DBCODE: ""
                    }
                    setDataMap(list);
                    // const updatedData=parseObjectToString(list);
                    // props.setData(updatedData)

                }

                break;
            }
            case 'delete': {
                delete dataMap[index];
                const updatedData = parseObjectToString(dataMap);
                setDataMap(updatedData);
                break;

            }
        }
    }

    const handleChange = (value, type, elementIndex) => {
        const updatedData = { ...dataMap };
        switch (type) {
            case 'SOURCE_PORTAL_CARD_ID':
            case 'TARGET_PORTAL_CARD_ID': {
                updatedData[elementIndex][type] = value.id;
                break;
            }
            case 'SOURCE_PORTAL_CARD_DBCODE':
            case 'TARGET_PORTAL_CARD_DBCODE': {
                updatedData[elementIndex][type] = value;
                break;
            }
        }
        setDataMap(updatedData);
    }

    const handleDone = () => {
        const updatedData = parseObjectToString(dataMap);
        props.handlePropertyChange(updatedData);
        props.setData(updatedData)
        props.setOpen(false);
    }
    const dynamicContainer = getInnerComponent(dataMap, handleChange, props.cardId)

    return (
        <Modal ioTheme={themes[0]}
            ioMode={themeContext.ioMode}
            styleName={themeContext.styleName}
            id={'dfhgj-45t3dfg'}
            isOpen={true}
            toggle={() => props.setOpen(false)}
        >
            <React.Fragment>
                <DataSharingComponent
                    ioTheme={themes[0]}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    id={'ksjdfhkjsf'}
                    handleClick={handleClick}
                    handleDone={() => handleDone()}
                    list={dynamicContainer} />
            </React.Fragment>
        </Modal>


    );
}

const parseStringToObject = (value) => {
    let parsedValue: any = {};
    if (value) {
        value.split(';').map((row, index) => {
            const rowObject = {};
            if (row) {
                row.split(',').map(column => {
                    if (column) {
                        rowObject[column.split(':')[0]] = column.split(':')[1]
                    }
                })
                if (Object.keys(rowObject).length) {
                    parsedValue[index] = rowObject;
                }
            }
        })
    }
    return parsedValue;
}

const parseObjectToString = (dataToParse) => {
    let data = "";
    Object.keys(dataToParse).map(element => {
        let innerData = "";
        Object.keys(dataToParse[element]).map((innerEle, index) => {
            if (!innerData && Object.keys(dataToParse[element]).length - 1 !== index) {
                innerData = innerEle + ':' + dataToParse[element][innerEle];
            } else if (innerData && Object.keys(dataToParse[element]).length - 1 === index) {
                innerData = innerData + ',' + innerEle + ':' + dataToParse[element][innerEle] + ';';
            } else {
                innerData = innerData + ',' + innerEle + ':' + dataToParse[element][innerEle];
            }
        })
        if (!data) {
            data = innerData
        } else {
            data = data + innerData
        }
    })
    return data;
}

const getInnerComponent = (dataMap, handleChange, cardId) => {
    const component: any = [];
    Object.keys(dataMap).map((key, index) => {
        const rowComponent = (
            <DataSharingRowComponent
                rowData={dataMap[key]}
                rowIndex={index}
                handleChange={handleChange}
                cardId={cardId}
                dataMap={dataMap} />
        )
        component.push({ component: rowComponent })
    });
    return component;
}

export default (DataSharingComponentCore);