import gql from 'graphql-tag';

export const ReleaseQuery = gql`
query ConfigReleases
 {
    ConfigReleases {
        configReleaseName
        configReleaseId
        status
        projectId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
    }
}
`;