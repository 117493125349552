import React from 'react';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip'

const EditableCell = ({
    cell,
    row,
    column: { id },
    updateMyData,
}) => {
    const [value, setValue] = React.useState(cell.value);
    React.useEffect(() => {
        setValue(cell.value)
    }, [cell.value])

    return (cell.isRepeatedValue !== true && value !== undefined && value !== null) ? <div style={{ padding: "0 10px" }}>
        <EllipsisWithTooltip placement="bottom">
            {value}
        </EllipsisWithTooltip>
    </div> :
        <React.Fragment >
            <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
        </React.Fragment>
}

export default EditableCell;