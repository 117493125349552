import gql from 'graphql-tag';

export const Form = gql`
mutation createForm($input:FormInput)
 {
    createForm(input:$input) {
        configObjectId
        name
        configObjectType
        isDeleted
        projectId
        formLabel
        formType
        isRepeatable
        order
        tabRequiredInFormsection
        expressionAvailable
    accessibilityRegex
    editabilityRegex 
    expressionFieldString 
    defaultDataString
    maxRepeatation
    minRepeatation
    defaultRepeatation
    repeatationStyle
    isDeletionAllowed
    addAllowed
    filterFormReloadControl
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;