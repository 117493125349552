export const themes = [
    {
        name: 'default',
        ioMode: {
            light: {
                primary1: {
                    background: '#4CAF50',
                    color: 'white',
                    formSectionColor: 'darkgreen',
                    gridcolor: 'darkgreen',
                    borderColor: '#4CAF50',
                    hoverBGColor: '#0f0f0f',
                    tableBorderColor: '1px solid white',
                    tableBackground: '#A4D6A5',
                    tableHeaderColor: '#4CAF50',
                    tableAccentColor: '#8DCC8F',
                    tableHoverColor: '#0f0f0f',
                    menubackground: '#4CAF50',
                    submenubackground: 'transparent',
                    menucolor: '#0c0c0d',
                    menuhoverbgcolor: '#0f0f0f',
                    menuhovercolor: '#0f0f0f',
                    menuitemselectedbgcolor: '#3D8C40',
                    menuitemselectedcolor: '#0d120d',
                    footerbackground: '#4CAF50',
                    scrollbarcolor: '#4CAF50',
                    scrollbarbgcolor: '#e3dfdf',
                    leftmenubackgroundlevel1: '#70BF73',
                    leftmenubackgroundlevel2: '#8DCC8F',
                    leftmenubackgroundlevel3: '#A4D6A5',
                    leftmenubackgroundlevel4: '#B6DEB7',
                    leftmenubackgroundlevel5: '#B6DEB7',
                    leftmenuminimizerbuttoncolor: '#3D8C40',
                    leftmenucolor: '#0c0c0d',
                    leftmenuhoverbgcolor: '#49ab4f',
                    leftmenuhovercolor: '#090a0a',
                    leftmenusideinkbarcolor: '#0d0e0f',
                    leftmenuParentOpenedBackground: '#3D8C40',
                    leftmenuSelectedBackground: '#4CAF50',
                    leftmenuSelectedColor: '#0e0f0f',
                    leftmenuarrowcolor: 'black',
                    leftmenuicon: '#2f2f30',
                    active: '#49ab4f',
                    inkbar: '#2f2f30',
                    textcolor: '#0c0c0d',
                    attachmentBackground: '#e8faec',
                    inlineForm: "white!important",
                    inlineRowSelected: "green !important",
                    inlineGridSuccess: "#248f24!important",
                    inlineGridDanger: "#e60000!important"
                },
                primary: {
                    background: 'rgb(23, 162, 184)',
                    color: 'white',
                    footerbackground: 'rgb(23, 162, 184)',
                    textcolor: '#0c0c0d',
                    checkMarkColor :'white',
                    gridcolor: 'rgb(23, 162, 184)',
                    borderColor: 'rgb(23, 162, 184)',
                    hoverBGColor: 'rgb(23, 162, 184)',
                    tableBorderColor: '1px solid white',
                    tableBackground: '#63c2de',
                    tableHeaderColor: 'rgb(23, 162, 184)',
                    tableAccentColor: 'lightblue',
                    tableHoverColor: '#f2f2f2',
                    menubackground: 'rgb(23, 162, 184)',
                    submenubackground: 'transparent',
                    menucolor: 'white',
                    menuhoverbgcolor: 'white',
                    menuhovercolor: 'white',
                    menuitemselectedbgcolor: '#148ea1',
                    menuitemselectedcolor: 'white',
                    inlineForm: "black!important",
                    inlineRowSelected: "rgb(14, 100, 113)!important", 
                    inlineGridSuccess: "#248f24!important",
                    inlineGridDanger: "#e60000!important",
                },
                 danger: {
                    background: '#eb4242',
                    asteriskColor: '#eb4242',
                    fontColor: '#eb4242',
                    color: '#fff',
                    formSectionColor: '#eb4242',
                    gridcolor: '#eb4242',
                    borderColor: '#eb4242',
                    hoverBGColor: '#fdfdfdf7',
                    tableBorderColor: '1px solid white',
                    tableBackground: '#ff9494',
                    tableHeaderColor: '#eb4242',
                    tableAccentColor: '#EEACAC',
                    tableHoverColor: '#fff',
                    menubackground: '#eb4242',
                    submenubackground: 'transparent',
                    menucolor: '#fff',
                    menuhoverbgcolor: '#0f0f0f',
                    menuhovercolor: '#333',
                    menuitemselectedbgcolor: '#A30000',
                    menuitemselectedcolor: '#FFF',
                    footerbackground: '#eb4242',
                    scrollbarcolor: '#fff',
                    scrollbarbgcolor: '#fff',
                    leftmenubackgroundlevel1: '#D63333',
                    leftmenubackgroundlevel2: '#DE5C5C',
                    leftmenubackgroundlevel3: '#E57D7D',
                    leftmenubackgroundlevel4: '#EA9797',
                    leftmenubackgroundlevel5: '#EA9797',
                    leftmenuminimizerbuttoncolor: '#A30000',
                    leftmenucolor: '#fff',
                    leftmenuhoverbgcolor: '#A30000',
                    leftmenuhovercolor: '#FFF',
                    leftmenusideinkbarcolor: '#0d0e0f',
                    leftmenuParentOpenedBackground: '#A30000',
                    leftmenuSelectedBackground: '#ed2b2b',
                    leftmenuSelectedColor: '#0e0f0f',
                    leftmenuarrowcolor: 'white',
                    leftmenuicon: '#FFF',
                    active: '#A30000',
                    inkbar: 'white',
                    textcolor: 'white',
                    inlineForm: "white!important",
                    inlineRowSelected: "#33ffd6!important",
                    inlineGridSuccess: "#248f24!important",
                    inlineGridDanger: "#e60000!important",
                    attachmentBackground: '#fcf1f0'
                }
            }
        },
        uiLibrary: 'bootstrap'
    }
];

export interface IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink {
    background: string;
    color: string;
    borderColor: string;
}

export interface IDark {
    primary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    secondary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    success: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    info: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    warning: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    danger: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    link: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
}

export interface ILink {
    background: string;
    color: string;
}

export interface ILight {
    primary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    secondary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    success: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    info: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    warning: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    danger: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    link: ILink;
}

export interface IMode {
    dark: IDark;
    light: ILight;
}

export interface ITheme {
    name: string;
    ioMode: IMode;
    uiLibrary: string;
}

export const resolveColor = (
    iotheme: ITheme,
    ioMode: string,
    stylename: string,
    attribute: string
) => {
    return iotheme.ioMode[ioMode][stylename ? stylename : 'primary'][attribute];
};
