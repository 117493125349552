import gql from 'graphql-tag';

export const DataBaseValidation = gql`
mutation createDatabaseValidation($input:DatabaseValidationInput)
 {
  createDatabaseValidation(input:$input) {
        name
        configObjectId
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        mode
        datasourceName
        datasourceId
        validationType
        validationExpression
        validationQid
        validationMessage
        validationExpressionKeys
        isConditionAvailable
        conditionExpression
        conditionFields
        description
       childRelations {
            relationType
            childItemId
         }
          parentRelations {
           relationType
           parentItemId
         }
          privileges {
           privilegeType
           roleId
         }
    }
}
`;