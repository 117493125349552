import gql from 'graphql-tag';

export const HelpItem = gql`
mutation createHelpItem($input:HelpItemInput)
 {
    createHelpItem(input:$input) {
        type
        helpURL
	configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        
          
    }
}
`;

