import gql from 'graphql-tag';

export const ColumnDataPreprocessor = gql`
mutation createColumnDataPreprocessor($input:ColumnDataPreprocessorInput)
 {
    createColumnDataPreprocessor(input:$input) {
    configObjectId
    name
    configObjectType
    preProcessorBean
    isMultiValue
    excecutionType
    jsCode
    order
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;