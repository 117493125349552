import React,{useContext} from 'react';
import {
    Button
} from 'info-ui-library';
import { themes } from '../theme/ThemeOptions';
import styled from 'styled-components';
import { useDispatch,useSelector } from 'react-redux';
import {createConfigItem,goBack ,setErrorData} from '../actions/index';

import { resolveColor } from '../theme/ThemeOptions';
import { ThemeContext } from '../theme/ThemeContext';
const OuterConatiner = styled.div`
padding-top:10px
`;
const StyledButton = styled(Button)`
background: ${ p =>
    resolveColor(p.ioTheme, p.ioMode, p.styleName, 'background') };
color: ${ p =>
    resolveColor(p.ioTheme, p.ioMode, p.styleName, 'color') };
`;
const handleClick = () => {

}
const handleClose = (dispatch,screenDetailsHistory)=>{
    dispatch(setErrorData({}))
    dispatch(goBack(screenDetailsHistory));

}


const handleSave=(dispatch,configItemId)=>{
    dispatch(createConfigItem(configItemId));
}
const ActionButtons :React.FC<any> =  props => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    const screenDetailsHistory = useSelector((state: any) => state.screenDetailsHistory);
    const cardDatas = useSelector((state: any) => state.cardsData);
    const screenDetails = useSelector((state: any) => state.screenDetails);
    try {
        return (
            <OuterConatiner>
                <StyledButton styleName={themeContext.styleName}
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    id="duplicate"
                    disabled={false}
                    name="Duplicate"
                    onClick={() => handleClick()} />
                <StyledButton styleName={themeContext.styleName}
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    id="delete"
                    disabled={true}
                    name="Delete"
                    onClick={() => handleClick()} />
                <StyledButton styleName={themeContext.styleName}
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    id="save"
                    disabled={false}
                    name="Save"
                    onClick={() => handleSave(dispatch,screenDetails.configItemId)} />
                <StyledButton styleName={themeContext.styleName}
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    id="close"
                    disabled={false}
                    name="Close"
                    onClick={() => handleClose(dispatch,screenDetailsHistory)} />
            </OuterConatiner>
        )
    } catch (e) {
        return <OuterConatiner></OuterConatiner>
    }
}
export default ActionButtons;