import { applyMiddleware, createStore } from 'redux';
import reducer from '../reducers/index';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from '../sagas/index';
//import { getLocaleMessages } from '../utils/intlutils';

const sagaMiddleware: any = createSagaMiddleware();

export interface IState {
  cardsData: any;
  errorData: any;
  projectId: number;
  projectOptions : any;
  userDetails: string;
  selectOptionDetails:{
    dataSourceSelectOption:any,
    formSelectOption:any,
    gridSelectOption:any,
    portalSelectOption:any,
    compositeEntitySelectOption:any,
    rootcompositeEntityNodeSelectOption:any,
    portalCardSelectOption:any,
    configReleases: any
  };
  screenDetails:{
    configType:string;
    screenType:string;
    parentItemId:string;
    relationType:string;
    configItemId:string;
    treeNodeUuid:string;
  };
  screenDetailsHistory:any;
  toggleMessages:any;
  sideNodeTree:{
    treeData:{},
    referenceData:{}
  };
  applications: any;
  currentApplicationRoleList: any;
}

const middleware: any = [sagaMiddleware];

export function makeStore() {
  const store: any = createStore(reducer, {
    cardsData: {},
    errorData: {},
    projectId: 0,
    projectOptions: {},
    userDetails: "",
    selectOptionDetails:{
      dataSourceSelectOption:[],
      formSelectOption:[],
      gridSelectOption:[],
      portalSelectOption:[],
      compositeEntitySelectOption:[],
      rootcompositeEntityNodeSelectOption:[],
      portalCardSelectOption:[],
      configReleases:[]
    },
    screenDetails:{
      configType:"",
      screenType:"",
      parentItemId:"",
      relationType:"",
      configItemId:"",
      treeNodeUuid:"",
    },
    screenDetailsHistory:[],
    toggleMessages:[],
    sideNodeTree:{
      treeData:{},
      referenceData:{}
    },
    applications: [],
    currentApplicationRoleList: []
  },
    composeWithDevTools(applyMiddleware(...middleware)));
  sagaMiddleware.run(rootSaga);
  return store
}
