import gql from 'graphql-tag';

export const NodeBusinessRule = gql`
mutation createNodeBusinessRule($input:NodeBusinessRuleInput)
 {
    createNodeBusinessRule(input:$input) {
    configObjectId
    executionType
    rule
    order
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;