import gql from 'graphql-tag';

export const MenuButton = gql`
mutation createMenuButton($input:MenuButtonInput)
{
    createMenuButton(input:$input) {
    defaultType
    menuLabel
    position
    buttonClass
    warningMessage
    order
    accessibilityRegex
    editabilityRegex
    expressionAvailable
    transactionName
    modalRequired
    portalId
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
  }
}
`;