import gql from 'graphql-tag';

export const FormSection = gql`
mutation createFormSection($input:FormSectionInput)
 {
    createFormSection(input:$input) {
        configObjectId
    headerLabel
    order
    displayName
    accessibilityRegex
    editabilityRegex
    tabGroup
    isRenderOnRepeat
    requiredFormfields
    componentsPerRow
    expressionAvailable
    name
    configObjectType
    createdBy
    isDeleted
       
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;