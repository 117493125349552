import gql from 'graphql-tag';

export const DataSourceQuery = gql`
  query DataSource($id: ID!)
  {
    DataSource(id:$id) {
    displayName
    datasourceType
    lookupKey
    isTransactionEntriesEnable
    applicationId
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
   childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
