import { url } from "inspector";

export const SET_FORM_DATA = 'SET_FORM_DATA';
export const GET_SCREEN_DETAILS = 'GET_SCREEN_DETAILS';
export const SET_SCREEN_DETAILS = 'SET_SCREEN_DETAILS';
export const GET_DATA_SOURCE_IN_SELECT_OPTION = 'GET_DATA_SOURCE_IN_SELECT_OPTION';
export const SET_DATA_SOURCE_IN_SELECT_OPTION = 'SET_DATA_SOURCE_IN_SELECT_OPTION';
export const GET_FORM_DATA_IN_SELECT_OPTION = 'GET_FORM_DATA_IN_SELECT_OPTION';
export const SET_FORM_DATA_IN_SELECT_OPTION = 'SET_FORM_DATA_IN_SELECT_OPTION';
export const SET_GRID_DATA_IN_SELECT_OPTION = 'SET_GRID_DATA_IN_SELECT_OPTION';
export const GET_GRID_DATA_IN_SELECT_OPTION = 'GET_GRID_DATA_IN_SELECT_OPTION';
export const SET_SCREEN_DETAILS_HISTORY = 'SET_SCREEN_DETAILS_HISTORY';
export const REMOVE_SCREEN_DETAILS_HISTORY = 'REMOVE_SCREEN_DETAILS_HISTORY';
export const CREATE_CONFIG_ITEM = 'CREATE_CONFIG_ITEM';
export const SET_ERROR_DATA = 'SET_ERROR_DATA';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_CONFIG_DATA_BY_ID = "GET_CONFIG_DATA_BY_ID";
export const GET_GRID_DATA = "GET_GRID_DATA";
export const SET_GRID_DATA = "SET_GRID_DATA";
export const SET_SIDE_TREE = 'SET_SIDE_TREE';
export const REMOVE_SIDE_TREE = 'REMOVE_SIDE_TREE';
export const GO_BACK = 'GO_BACK';
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_CHANGE_FORM_DATA = 'SET_CHANGE_FORM_DATA';
export const GET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION = 'GET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION';
export const SET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION = 'SET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION';
export const GET_PORTAL_IN_SELECT_OPTION = 'GET_PORTAL_IN_SELECT_OPTION';
export const GET_PORTAL_CARD_IN_SELECT_OPTION = 'GET_PORTAL_CARD_IN_SELECT_OPTION';
export const SET_PORTAL_IN_SELECT_OPTION = 'SET_PORTAL_IN_SELECT_OPTION';
export const SET_PORTAL_CARD_IN_SELECT_OPTION = 'SET_PORTAL_CARD_IN_SELECT_OPTION';
export const SET_CONFIG_RELEASES_IN_SELECT_OPTION = 'SET_CONFIG_RELEASES_IN_SELECT_OPTION';
export const GET_COMPOSITE_ENTITY_IN_SELECT_OPTION = 'GET_COMPOSITE_ENTITY_IN_SELECT_OPTION';
export const SET_COMPOSITE_ENTITY_IN_SELECT_OPTION = 'SET_COMPOSITE_ENTITY_IN_SELECT_OPTION';
export const GET_CONFIG_DATA_FOR_DELETE_BY_ID = 'GET_CONFIG_DATA_FOR_DELETE_BY_ID';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const GET_PROJECT_OPTIONS = 'GET_PROJECT_OPTIONS';
export const SET_PROJECT_OPTIONS = 'SET_PROJECT_OPTIONS';
export const SET_TOGGLE_MESSAGES = 'SET_TOGGLE_MESSAGES';
export const SET_STORE = 'SET_STORE';
export const DEPLOY_META = 'DEPLOY_META';
export const SWITCH_PROJECT = 'SWITCH_PROJECT';
export const LOG_OUT ='LOG_OUT';

export interface IDeployMeta {
  type: string;
}

export const setStore = (store: any): ISetStore => {
  return {
    type: SET_STORE,
    store: store
  }
}
export interface ISetStore {
  type: string;
  store: any;
}
export const setCurrentProject = (projectId: number): ISetCurrentProject => {
  return {
    type: SET_CURRENT_PROJECT,
    projectId: projectId
  }
}
export interface ISetCurrentProject {
  type: string;
  projectId: number;
}

export function getProjectOption(): IGetProjectOption {
  return {
    type: GET_PROJECT_OPTIONS
  };
}
export interface IGetProjectOption {
  type: string;
}
export function setProjectOption(projectOptions: any): ISetProjectOption {
  return {
    type: SET_PROJECT_OPTIONS,
    projectOptions: projectOptions
  };
}
export interface ISetProjectOption {
  type: string;
  projectOptions: any
}
export function deleteConfigItemById(configItemId: string, screenDetails: any): IDeleteConfigItemById {
  return {
    type: GET_CONFIG_DATA_FOR_DELETE_BY_ID,
    configItemId: configItemId,
    screenDetails: screenDetails
  };
}
export interface IDeleteConfigItemById {
  type: string;
  configItemId: string
  screenDetails: any
}

export function getCompositeEntityInSelectOption(): IGETCompositeEntityInSelectOption {
  return {
    type: GET_COMPOSITE_ENTITY_IN_SELECT_OPTION
  };
}
export interface IGETCompositeEntityInSelectOption {
  type: string;
}
export function setCompositeEntityInSelectOption(ceid: any): ISetCompositeEntityInSelectOption {
  return {
    type: SET_COMPOSITE_ENTITY_IN_SELECT_OPTION,
    ceid: ceid
  };
}
export interface ISetCompositeEntityInSelectOption {
  type: string;
  ceid: any;
}
export function getDataSourceInSelectOption(): IGetDataSourceInSelectOption {
  return {
    type: GET_DATA_SOURCE_IN_SELECT_OPTION
  };
}
export interface IGetDataSourceInSelectOption {
  type: string;
}
export function setDataSourceInSelectOption(dataSourceDetails: any): ISetDataSourceInSelectOption {
  return {
    type: SET_DATA_SOURCE_IN_SELECT_OPTION,
    dataSourceDetails: dataSourceDetails
  };
}
export interface ISetDataSourceInSelectOption {
  type: string;
  dataSourceDetails: any;
}
export function getRootCompositeEntityNodeInSelectOption(): IGETRootCompositeEntityNodeInSelectOption {
  return {
    type: GET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION
  };
}
export interface IGETRootCompositeEntityNodeInSelectOption {
  type: string;
}
export function setRootCompositeEntityNodeInSelectOption(nodeid: any): ISetRootCompositeEntityNodeInSelectOption {
  return {
    type: SET_ROOT_COMPOSITE_ENTITY_NODE_IN_SELECT_OPTION,
    nodeid: nodeid
  };
}
export interface ISetRootCompositeEntityNodeInSelectOption {
  type: string;
  nodeid: any;
}
export function getFormDataInSelectOption(): IGetFormDataInSelectOption {
  return {
    type: GET_FORM_DATA_IN_SELECT_OPTION
  };
}
export interface IGetFormDataInSelectOption {
  type: string;
}
export function setFormDataInSelectOption(formDetails: any): ISetFormDataInSelectOption {
  return {
    type: SET_FORM_DATA_IN_SELECT_OPTION,
    formDetails: formDetails
  };
}
export interface ISetFormDataInSelectOption {
  type: string;
  formDetails: any;
}
export function getPortalInSelectOption(): IGetPortalInSelectOption {
  return {
    type: GET_PORTAL_IN_SELECT_OPTION
  };
}
export interface IGetPortalInSelectOption {
  type: string;
}
export function getPortalCardInSelectOption(): IGetPortalCardInSelectOption {
  return {
    type: GET_PORTAL_CARD_IN_SELECT_OPTION
  };
}
export interface IGetPortalCardInSelectOption {
  type: string;
}
export function setPortalInSelectOption(portals: any): ISetPortalInSelectOption {
  return {
    type: SET_PORTAL_IN_SELECT_OPTION,
    portals: portals
  };
}
export interface ISetPortalInSelectOption {
  type: string;
  portals: any;
}

export function setPortalCardInSelectOption(portalCards: any): ISetPortalCardInSelectOption {
  return {
    type: SET_PORTAL_CARD_IN_SELECT_OPTION,
    portalCards: portalCards
  };
}

export interface ISetConfigReleasesInSelectOption {
  type: string;
  releases: any;
}

export function setConfigReleasesInSelectOption(releases: any): ISetConfigReleasesInSelectOption {
  return {
    type: SET_CONFIG_RELEASES_IN_SELECT_OPTION,
    releases: releases
  };
}

export interface ISetPortalCardInSelectOption {
  type: string;
  portalCards: any;
}
export function getGridDataInSelectOption(): IGetGridDataInSelectOption {
  return {
    type: GET_GRID_DATA_IN_SELECT_OPTION
  };
}
export interface IGetGridDataInSelectOption {
  type: string;
}
export function setGridDataInSelectOption(gridDetails: any): ISetGridDataInSelectOption {
  return {
    type: SET_GRID_DATA_IN_SELECT_OPTION,
    gridDetails: gridDetails
  };
}
export interface ISetGridDataInSelectOption {
  type: string;
  gridDetails: any;
}
export function setGridData(cardsData: any): ISetGridData {
  return {
    type: SET_GRID_DATA,
    cardsData: cardsData
  }
}
export interface ISetGridData {
  type: string;
  cardsData: any

}
export function getGridData(): IGetGridData {
  return {
    type: GET_GRID_DATA
  }
}
export interface IGetGridData {
  type: string;
}
export function getConfigDataById(configItemId: string, screenDetails: any): IGetConfigDataById {
  return {
    type: GET_CONFIG_DATA_BY_ID,
    configItemId: configItemId,
    screenDetails: screenDetails
  };
}
export interface IGetConfigDataById {
  type: string;
  configItemId: string
  screenDetails: any
}
export function setErrorData(errorData: any): IErrorData {
  return {
    type: SET_ERROR_DATA,
    errorData: errorData
  };
}
export interface IErrorData {
  type: string;
  errorData: any
}
export function createConfigItem(configItemId: string): ICreateConfigItem {
  return {
    type: CREATE_CONFIG_ITEM,
    configItemId: configItemId
  };
}
export interface ICreateConfigItem {
  type: string;
  configItemId: string
}
export function removeScreenDetailsHistory(screenDetailsHistory: any): IRemoveScreenDetailsHistory {
  return {
    type: REMOVE_SCREEN_DETAILS_HISTORY,
    screenDetailsHistory: screenDetailsHistory
  };
}
export interface IRemoveScreenDetailsHistory {
  type: string;
  screenDetailsHistory: any;
}
export function setScreenDetailsHistory(screenDetailsHistory: any): ISetScreenDetailsHistory {
  return {
    type: SET_SCREEN_DETAILS_HISTORY,
    screenDetailsHistory: screenDetailsHistory
  };
}
export interface ISetScreenDetailsHistory {
  type: string;
  screenDetailsHistory: any;
}

export function getScreenDetails(screenDetails: any, historyEnable: boolean): ISetScreenDetails {
  return {
    type: GET_SCREEN_DETAILS,
    screenDetails: screenDetails,
    historyEnable: historyEnable
  };
}
export function setScreenDetails(screenDetails: any, historyEnable: boolean): ISetScreenDetails {
  return {
    type: SET_SCREEN_DETAILS,
    screenDetails: screenDetails,
    historyEnable: historyEnable
  };
}
export interface ISetScreenDetails {
  type: string;
  screenDetails: any;
  historyEnable: boolean;
}

export function setFormDatas(cardData: any, cardId: string): ISetFormData {
  return {
    type: SET_FORM_DATA,
    cardData: cardData,
    cardId: cardId
  };
}
export interface ISetFormData {
  type: string;
  cardData: any;
  cardId: string;
}

export function setSideTree(sortableTreeData: any): ISetSideTree {
  return {
    type: SET_SIDE_TREE,
    sortableTreeData: sortableTreeData
  };
}
export interface ISetSideTree {
  type: string;
  sortableTreeData: any;
}

export function removeSideTree(sortableTreeData: any): IRemoveSideTree {
  return {
    type: REMOVE_SIDE_TREE,
    sortableTreeData: sortableTreeData
  };
}
export interface IRemoveSideTree {
  type: string;
  sortableTreeData: any;
}

export function goBack(screenDetailsHistory: any): IGoBack {
  return {
    type: GO_BACK,
    screenDetailsHistory: screenDetailsHistory
  };
}
export interface IGoBack {
  type: string;
  screenDetailsHistory: any
}
export interface IGetUserProfile {
  type: string;
  userId: string;
  userStatus: string;
  user?: any;
}

export const getUserProfile = (userId: string, userStatus: string, user?: any): IGetUserProfile => {
  return {
    type: GET_USER_PROFILE,
    userId: userId,
    userStatus: userStatus,
    user: user
  }
}

export function setToggleMessages(messages: string[]): ISetToggleMessages {
  return {
    type: SET_TOGGLE_MESSAGES,
    messages: messages
  };
}
export interface ISetToggleMessages {
  type: string;
  messages: string[];
}

export interface ISetToastMessage {
  type: string;
  message: string[];
}

export const setToastMessage = (message: string[]): ISetToastMessage => {
  return {
    type: SET_TOAST_MESSAGE,
    message: message
  }
}

export interface ISetUserProfile {
  type: string;
  userDetails: string;
}

export const setUserProfile = (userDetails: string): ISetUserProfile => {
  return {
    type: SET_USER_PROFILE,
    userDetails: userDetails
  }
}

export interface ISetChangeFormData {
  type: string;
  cardId: string;
  changedValue: any;
  dbCode: string;
  itemType: string;
}

export function setChangeFormData(cardId: string, changedValue: any, dbCode: string, itemType: string): ISetChangeFormData {
  return {
    type: SET_CHANGE_FORM_DATA,
    cardId: cardId,
    changedValue: changedValue,
    dbCode: dbCode,
    itemType: itemType
  };
}

export function deployMeta(): IDeployMeta {
  return {
    type: DEPLOY_META
  };
}

export interface ISwicthProject {
  type: string;
  projectId: any;
}

export function switchProject(projectId: string): ISwicthProject {
  return {
    type: SWITCH_PROJECT,
    projectId: projectId
  };
}


export interface ILogout {
  type: string;
  history: any;
  url: string;
}

export function logOut(url: string, history: any): ILogout {
  return {
    type: LOG_OUT,
    url: url,
    history: history
  };
}