import gql from 'graphql-tag';

export const FormField = gql`
mutation createFormField($input:FormFieldInput)
 {
    createFormField(input:$input) {
    label
    order
    height
    hasSupportingLabel
    hasValueInSupportingLabel
    supportingLabel
    supportingLabelFontSize
    fontSize
    type
    displayType
    editorType
    isMandatory
    isButtonTextBox
    expressionAvailable
    refreshFormOnChange
    accessibilityRegex
    editabilityRegex
    previewAccessibilityRegex
    previewEditabilityRegex
    deleteAccessibilityRegex
    deleteEditabilityRegex
    expressionFieldString
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    multivalueList
    selectItemsReferenceID
    placeHolder
    datasourceId
    defaultValue
    isMultiLingual
    formatDate
    isApplyDateFormat
    resetOnRefresh
    rowspan
    colspan
    hasToolTipIcon
    toolTipText
    isSearchBoxRequired
    isHyperlink
    gotoLink
    uploadLabel
    outDbcodeUploadFileTempId
    outDbcodeUploadFileName
    outDbcodeUploadFileType
    isRefreshFormWithUploadData
    valueType
    isReadOnly
    starCount
    starColor
    mask
    maskChar
    isSearchable
    isDisabled
    isMulti
    showYearDropdown
    showMonthDropdown
    dateFormat
    dropdownMode
    timeFormat
    showTimeSelect
    showTimeSelectOnly
    timeIntervals
    timeCaption
    entityName
    parentDBCode
    layout
    dbCode
    isAsyncOption
    isClearable
    labelColor
    mandatoryIconAccessibilityRegex
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
   }
}
`;