export default {
    "CompositeEntity": {
        "child": [
            {
                "RootCompositeEntityNode": {
                    "child": [
                        {
                            "PhysicalEntity": {
                                "child":[
                                    {
                                        "PhysicalColumn":null
                                    }
                                ]
                            }
                        },
                        {
                            "NodeBusinessRule":null
                        }
                    ]
                }
            },
            {
                "CompositeEntityNode": {
                    "child": [
                        {
                            "PhysicalEntity": {
                                "child":[
                                    {
                                        "PhysicalColumn":null
                                    }
                                ]
                            }
                        },
                        {
                            "NodeBusinessRule":null
                        }
                    ]
                }
            }
        ]
    }
}