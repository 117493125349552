import gql from 'graphql-tag';

export const Button = gql`
mutation createButton($input:ButtonInput)
 {
    createButton(input:$input) {
     label
   order
   portalId
   buttonClass
   warningMessage
   buttonAlignment
   accessibilityRegex
   editabilityRegex
   expressionAvailable
   modalRequired
   expressionFieldString
   dbCode
   icon
   iconFirst
   buttonStyle
   toolTip
   modalCss
   transactionName
   configObjectId
   name
   configObjectType
   createdBy
   isDeleted
   itemDescription
   insert_ts
   projectId
   updatedBy
   update_ts
   deletionDate
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;