
export default {
    "Form": {
        "child": [
            {
                "FormSection": {
                    "child": [
                        {
                            "FormField": {
                                "child": [
                                    {
                                        "LogicalColumn": null
                                    },
                                    {
                                        "ColumnDataPreprocessor": null
                                    },
                                    {
                                        "ActionFlow": {
                                            "child": [
                                                {
                                                    "MicroFlow": null
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "Button": {
                                            "child": [
                                                {
                                                    "ActionFlow": {
                                                        "child": [
                                                            {
                                                                "MicroFlow": null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "ButtonPanel": {
                    "child": [
                        {
                            "Button": {
                                "child": [
                                    {
                                        "ActionFlow": {
                                            "child": [
                                                {
                                                    "MicroFlow": null
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "MenuButton": {
                                "child": [
                                    {
                                        "Button": {
                                            "child": [
                                                {
                                                    "ActionFlow": {
                                                        "child": [
                                                            {
                                                                "MicroFlow": null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "ActionFlow": {
                                            "child": [
                                                {
                                                    "MicroFlow": null
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "ColumnDataPreprocessor": null
            }
        ]
    }
}