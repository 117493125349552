export default {
    "ActionFlow": {
        "child": [
            {
                "MicroFlow":null
            },
            {
                "EmailTemplate":null
            }
        ]
    }
}