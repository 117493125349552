export default {
    "Validation": {
        "child": [
            {
                "LogicalEntity": null
            },
            {
                "LogicalColumn": null
            }
        ]
    }
}