import gql from 'graphql-tag';

export const MenuGroupQuery= gql`

query MenuGroup($id:ID!)
{
    MenuGroup(id:$id){
    name
    configObjectId
    appHomeId
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    type
    leftMenuDefaultOpen
      childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        
}
}`;