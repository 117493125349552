import gql from 'graphql-tag';

export const DataGridQuery = gql`
  query DataGrid($id: ID!)
  {
    DataGrid(id:$id) {
        label
        defaultOrdering
        gridType
        isHavingAdvanceFilterForm
        swimlaneRequired
        modalRequired
        isRowReOrder
        isBorderEnable
        isStrippedEnable
        isHoverEnable
        isScrollEnable
        isServerPaginationEnable
        isRowSelectionEnable
        isHeaderAvailable
        isSubComponentEnable
        subComponentType
        isEditButtonEnable
        defaultSorting
        gridDataAlignment
        gridHeaderAlignment
        name
        tabGroup
        configObjectType
        createdBy
        isDeleted
        insert_ts
        projectId
        updatedBy
        update_ts
        childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
