import styled from 'styled-components';
import { resolveColor } from '../../theme/ThemeOptions';

export const StyledSimpleFormDiv = styled.div`
border-color: ${ p =>
                resolveColor(p.ioTheme, p.ioMode, p.styleName, 'borderColor') +
                '!important'};
border-style: inset;
padding: 10px;
border: 1px solid ;
margin: 15px;
border-radius: 10px;
`;

export const StyledSimpleFormButtonWrapper = styled.div`
    float:right;
    margin-right: 25px;
`

export const StyledSimpleFormSpan = styled.span`
color: ${ p =>
                resolveColor(p.ioTheme, p.ioMode, p.styleName, 'formSectionColor') + '!important'};
font-weight: bold;
font-size: 20px;
margin-left: 20px;
`;

export const StyledComplexFormDiv = styled.div`
border-color: ${ p =>
                resolveColor(p.ioTheme, p.ioMode, p.styleName, 'borderColor') +
                '!important'};
border-style: inset;
padding: 10px;
border: 1px solid ;
margin: 15px;
border-radius: 10px;
`;

export const StyledConfirModalDiv = styled.div`
text-align: center;
    width: 500px;
    padding: 40px;
    background: ${ p =>
        resolveColor(p.ioTheme, p.ioMode, p.styleName, 'color') + '!important'};
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
`;

export const StyledComplexFormButtonWrapper = styled.div`
    float:right;
    margin-right: 25px;
`
export const StyledComplexFormSpan = styled.span`
color: ${ p =>
                resolveColor(p.ioTheme, p.ioMode, p.styleName, 'formSectionColor') + '!important'};
font-weight: bold;
font-size: 20px;
margin-left: 20px;
`;
