import React from 'react';
import moment from 'moment';
import { DateTimePicker } from 'info-ui-library';
import ReactTooltip from 'react-tooltip';

const DatePickerCell = ({
    cell,
    row,
    column: { id },
    updateMyData,
}) => {
    const [value, setValue] = React.useState(cell.value);
    const [isEditable, setEditable] = React.useState(false);

    let errorData = null ; //cell.column.useErrorDataUsingDBCode(row.id.toString(), id, cell.column.referralId,true);
    React.useEffect(() => {
        if (errorData && errorData.dbCode === id && errorData.path === row.id.toString()) {
            setEditable(true);
        } else {
            setEditable(false);
        }
    }, [errorData]);

    const handleClick = () => {
        if (errorData || (errorData && errorData.path !== row.id.toString())) {
            setEditable(false);
        } else {
            setEditable(true);
        }
    }

    const onChange = e => {
        let localToUtc = moment(e).utc().format();
        setValue(localToUtc);
        if (value !== localToUtc) {
            updateMyData(row.index, id, e, row.id);
        }
        setEditable(false);
    }

    const mouseLeaveEvent = () => {
        setEditable(false);
    }

    React.useEffect(() => {
        setValue(cell.value)
    }, [cell.value])

    let utcToLocalDate = value ? moment.utc(value).local().format(cell.column.dateFormat.toUpperCase()) : null;

    return cell.column.editEnable && isEditable ?
        <React.Fragment>
            <div onMouseLeave={mouseLeaveEvent} data-for='datePickerError' data-tip='datePickerError'>
                <DateTimePicker
                    styleName={cell.column.styleName}
                    ioTheme={cell.column.ioTheme}
                    ioMode={cell.column.ioMode}
                    label={""}
                    displayType={'SingleLine'}
                    showTimeSelect={false}
                    showTimeSelectOnly={false}
                    dateFormat={"dd-MMM-yyyy"}
                    onChange={(e) => onChange(e)}
                    selected={utcToLocalDate ? new Date(utcToLocalDate) : null}
                    withPortal
                    isInlineGridComponent={true}
                    widthCell={cell.column.width}
                    className={errorData ? "inlineGridError" : "inlineGrid"}
                />
            </div>
            {
                errorData ? <ReactTooltip id='datePickerError' className='customeTheme'
                    place="bottom" type="error" effect="solid">
                    <span>{errorData.message}</span>
                </ReactTooltip> : ""
            }
        </React.Fragment >
        : <React.Fragment>
            {
                (cell.isRepeatedValue !== true && value) ? <div onClick={() => handleClick()} style={{ padding: "0 10px" }} onMouseOver={() => handleClick()}>
                    {utcToLocalDate}
                </div> : <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }} onClick={() => handleClick()} onMouseOver={() => handleClick()}></div>
            }
        </React.Fragment>
}

export default DatePickerCell;