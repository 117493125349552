import gql from 'graphql-tag';

export const CustomFormValidationObjectQuery = gql`
  query CustomFormValidation($id: ID!)
{
  CustomFormValidation(id:$id) {
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    mode
    validationType
    validationMsg
    validatorBeanId
    isConditionAvailable
    conditionExpression
    conditionFields
    description
    childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
