
import { SelectOption } from 'info-ui-library';
import React, { useState, useEffect } from 'react';
import { themes } from '../theme/ThemeOptions';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSourceInSelectOption } from '../actions/index';
import { Row, Col } from '@bootstrap-styled/v4';

const DataSourceComponent: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    const options = useSelector((state: any) => state.selectOptionDetails.dataSourceSelectOption);

    const handleSelectOptionDataSource = (updatedValue, dbCode, type) => {
        props.handleSelectOption(updatedValue, dbCode, type, dispatch)
    }

    useEffect(() => {
        dispatch(getDataSourceInSelectOption())
    }, [])
    const valueAsAnObject = getValueObject(props.selectedValue, options);
    return (<SelectOption
        styleName={props.styleName}
        ioTheme={props.ioTheme}
        value={valueAsAnObject}
        ioMode={props.ioMode}
        options={options}
        label={props.label}
        configId={props.label}
        key={props.key}
        displayType={props.displayType}
        onChange={(value) => handleSelectOptionDataSource(value, props.property.dbCode, "property")}
        isSearchable={props.isSearchable}
        isDisabled={props.isDisabled}
        isMulti={props.isMulti}
        mandatory={props.mandatory}
        hasError={props.hasError}
        errorMessage={props.errorMessage} />
    )
}

export default (DataSourceComponent);
export const getValueObject = (initialvalue, selectedOptions) => {
    let value = null
    if (selectedOptions) {
        selectedOptions.map(object => {
            if (String(object.value) === String(initialvalue)) {
                value = object;
            }
            return null;
        })
    }
    return value
}