import React from 'react';
import { themes } from '../../mockdata/ThemeOption';
import data1 from "../../mockdata/internalGridData";
import InfoDataGrid from './Info_Data_Grid';
import TextBoxEditableCell from "../editableGridComponents/TextBoxEditableCell";
import ActionButton from "../editableGridComponents/ActionButton";

const InternalGridCall = (props) => {
    const row = props.row;
    const columns = [
        {
            Header: 'User Story',
            accessor: 'USER_STORY',
            width:330,
            editEnable: true,
            filterEnable: true,
            groupByEnable: true,
            Cell: TextBoxEditableCell,
            aggregate: ['sum', 'count'],
            Aggregated: ({ cell: { value } }) => `${value} Names`
        },
        {
            Header: 'Task',
            width: 330,
            accessor: 'TASK',
            filterEnable: false,
            filter: 'fuzzyText',
            aggregate: ['sum', 'uniqueCount'],
            Aggregated: ({ cell: { value } }) => `${value} Unique Names`,
        },
        {
            Header: 'Status',
            width: 330,
            accessor: 'STATUS',
            filterEnable: false,
            filter: 'fuzzyText',
            aggregate: ['sum', 'uniqueCount'],
            Aggregated: ({ cell: { value } }) => `${value} Unique Names`,
        },
        {
            Header: 'Action',
            width: 330,
            accessor: 'actionColumn',
            isAction: true,
            Cell: ActionButton,
            filterEnable: false,
            buttons: [{ id: "1", HeaderName: "", icon: "fa fa-edit" }, { id: "asdfg2", HeaderName: "", icon: "fa fa-trash" }, , { id: "asdfg2", HeaderName: "", icon: "fa fa-file" }]
        },
    ];
    const data = [...data1];

    return <InfoDataGrid
        columns={columns}
        data={data}
        serverPaginationEnable={false}
        subComponentEnable={false}
        styleName={"info"}
        ioTheme={themes[0]}
        ioMode={"light"}
        borderEnable={true}
        strippedEnable={true}
        hoverEnable={true} />
}

export default InternalGridCall;