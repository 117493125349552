import gql from 'graphql-tag';

export const PortalCard = gql`
mutation createPortalCard($input:PortalCardInput)
 {
    createPortalCard(input:$input) {
    configObjectId
    name
    configObjectType
    isDeleted
    projectId
	label
    archived
    favorite
	x
	y
	w
    h
    boxShadow
    cardStyle	
    cardBodyStyle
    cardHeaderStyle
    cardDataSharing
    referDataFromPortal
    order
    responsiveLayout
	isStatic
	type
	uiComponentId
	referenceData 
    isAccessibile
    reloadRequired
    displayHeader
    accessibilityRegex
    initialComponent
    isFullScreenRequired
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;