import gql from 'graphql-tag';

export const TabPortal = gql`
mutation createTabPortal($input:TabPortalInput)
 {
    createTabPortal(input:$input) {
        configObjectId
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        tabName
        isDefaultOpen
        order
        cardStyle	
        cardBodyStyle
        cardHeaderStyle
        expressionAvailable
        accessibilityRegex
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;