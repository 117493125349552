import React, { useContext } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { Footer ,FormErrorContainers } from 'info-ui-library';
import { useDispatch } from 'react-redux';
import { deployMeta } from '../actions/index';
const FooterComponent: React.FC = () => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    const handleClick=()=>{
        dispatch(deployMeta())
    }
    try {
        return (
            <Footer
            style={{position:'absolute'}}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                styleName={themeContext.styleName}
                label={"Deploy"}
                isClickRequired={true}
                handleClick={()=>{handleClick()}}
            />
        )
    } catch{
        const error = ['Error occurred in Footer']
        return <FormErrorContainers ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }
}

export default FooterComponent;
