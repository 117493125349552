import gql from 'graphql-tag';

export const FormDbValidationQuery = gql`
  query FormDbValidation($id: ID!)
{
  FormDbValidation(id:$id) {
    name
    configObjectId
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    mode
    datasourceName
    datasourceId
    validationType
    validationExpression
    validationQid
    validationMessage
    validationExpressionKeys
    fieldIds
    isConditionAvailable
    conditionExpression
    conditionFields
    description
   childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;