import gql from 'graphql-tag';

export const LogicalColumn = gql`
mutation createLogicalColumn($input:LogicalColumnInput)
 {
    createLogicalColumn(input:$input) {
      isPrimaryKey
      isDisplayColumn
      dataType
      isVerticalFieldKey
      dbCode
      length
      dbType
      isMandatory
      jsonName
      mode
      isUnique
      isDerived
      configObjectId
      name
      configObjectType
      createdBy
      isDeleted
      itemDescription
      insert_ts
      projectId
      updatedBy
      update_ts
      deletionDate
      childRelations {
            relationType
            childItemId
          }
          parentRelations {
            relationType
            parentItemId
          }
          privileges {
            privilegeType
            roleId
          }
    }
}
`;
