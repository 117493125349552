import gql from 'graphql-tag';

export const PhysicalEntityQuery= gql`
query PhysicalEntity($id:ID!){
    PhysicalEntity(id:$id){
         configObjectId
    compositeEntityId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    expressionAvailable
    accessibilityRegex
    isMultivalueMapping
    isPrimaryEntity
    order
    dbType
    dbTypeName
    schemaName
    releaseAreaSessName
    workAreaSessName
    auditDatasourceId
    datasourceId
    insertQID
    updateQID
    sequenceQID
    singleSelectQID
    multiSelectQID
    deleteQID
    isAuditRequired
        childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    
    }
}`;