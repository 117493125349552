import * as Cookies from 'js-cookie';
import { isEmpty } from '../utils/object.utils';
import IStorage from './storage.interface';

export interface CookieScope {
    domain: string;
    path: string;
    expires: number;
    secure: boolean;
}

export class CookieStorage implements IStorage {
    private domain: string;
    private path: string;
    private expires: number;
    private secure: boolean;

    private config: any;

    constructor(config: any) {
        this.config = config;
        this.path = config.path;
        this.domain = config.domain;
        this.expires = config.expires;
        this.secure = config.secure;
    }

    public setItem(key: string, value: string | null) {
        if (value != null && value.length !== 0) {
            Cookies.set(key, value, {
                path: this.path,
                expires: this.expires,
                domain: this.domain,
                secure: this.secure
            });
            return this.getItem(key);
        }
        return value;
    }

    public getItem(key: string) {
        return isEmpty(Cookies.get(key)) ? (Cookies.get(key) as string) : null;
    }

    public removeItem(key: string): boolean {
        const removed = Cookies.remove(key, {
            path: this.path,
            domain: this.domain,
            secure: this.secure
        });
        return removed !== undefined ? true : false;
    }

    public clear() {
        const cookies = Cookies.get();
        // eslint-disable-next-line 
        const total = cookies.keys;
        let index: number;
        for (index = 0; index < Object.keys(cookies).length; ++index) {
            Cookies.remove(cookies[index]);
        }
        return {};
    }
}
