import React from 'react';
import { useTable, useSortBy, useFilters, usePagination, useGroupBy, useExpanded, useRowSelect } from 'react-table';
import { Table, Tbody, Td, Thead, Th, Tr } from "info-ui-library";
import { filterTypes, defaultColumn } from "../DefaultConstants"
import InternalGrid from "./InternalGrid";
import ExpandableSubComponents from "./ExpandableSubComponents";
import Pagination from "./Pagination";

function InfoTable(props) {
    let columns = props.columns;
    let data = props.data;
    let errorData = props.errorDataExtractor ? props.errorDataExtractor("abc", "UUID", props.referralId, false) : null;
    const updateMyData = props.updateMyData;
    const serverPaginationEnable = props.serverPaginationEnable;
    const subComponentEnable = props.subComponentEnable;
    const subComponentType = props.subComponentType;
    let pageCountCopy = props.pageCount;
    const [selectedRowPath, setSelectedRowPath] = React.useState(null);
    let pageCalculatedIndex = props.pageCalculatedIndex;
    let cardsData = props.cardsData ? props.cardsData.data ? props.cardsData.data.length > 0 ? "pass" : "empty" : undefined : undefined;

    const onClick = (row, handleRowClick) => {
        setSelectedRowPath(row.id.toString());
        handleRowClick && handleRowClick(row);
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        state: { pageIndex, pageSize, filters, groupBy, expanded },
    } = useTable({
        columns,
        data,
        defaultColumn,
        updateMyData,
        filterTypes,
        initialState: { pageIndex: pageCalculatedIndex },
        manualPagination: serverPaginationEnable,
        pageCount: pageCountCopy,
    },
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        useRowSelect,
        usePagination);

    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        if (serverPaginationEnable) {
            props.fetchData({ pageIndex, pageSize });
        } else if (pageIndex !== undefined) {
            //  props.dispatch(props.setGridPageIndex(props.referralId, pageIndex, false))
        }
    }, [props.fetchData, pageIndex, pageSize]);


    const renderRowItem = React.useCallback((index, selectedPath, errorOccurred, selectedRowPath) => {
        const row = page[index];
        let status = "normal";
        if (row.id.toString() === selectedPath && errorOccurred && errorOccurred[selectedPath]) {
            status = "fail";
        } else if (row.id.toString() === selectedPath) {
            status = "success";
        } else if (row.id.toString() === selectedRowPath) {
            status = "selected";
        }
        prepareRow(row)
        return (
            <React.Fragment>
                <Tr {...row.getRowProps()}
                    styleName={props.styleName}
                    ioTheme={props.ioTheme}
                    ioMode={props.ioMode}
                    hoverEnable={props.hoverEnable}
                    strippedEnable={props.strippedEnable}
                    status={status}
                    style={{ lineHeight: `40px` }}
                    onClick={() => onClick(row, props.handleRowClick)}>
                    {row.cells.map(cell => {
                        if (cell.column.isAccessible) {
                            let tdProps = cell.getCellProps();
                            tdProps["style"] = {};
                            tdProps.style["padding"] = "0.1rem";
                            return <Td {...tdProps}
                                styleName={props.styleName}
                                ioTheme={props.ioTheme}
                                ioMode={props.ioMode}
                                borderEnable={props.borderEnable}>
                                {/* if cell is grouped, icons for first column whether it is grouped or expanded*/}
                                {cell.isGrouped ? (
                                    <React.Fragment>
                                        <span {...row.getToggleRowExpandedProps()} className={row.isExpanded ? "fa fa-sort-desc" : "fa fa-play"} />
                                        {cell.render('Cell')} ({row.subRows.length})
                                    </React.Fragment>
                                ) : cell.isAggregated && row.subRows.length === 0 ? (
                                    cell.render('Aggregated')
                                ) : cell.isRepeatedValue ? null : (
                                    cell.render('Cell', { editable: true, subComponentEnable: subComponentEnable })
                                )}
                            </Td>
                        }
                    })}
                </Tr>
                {/* if subrows are present*/}
                {
                    row.isExpanded && row.subRows.length === 0 ? (
                        <Tr styleName={props.styleName}
                            ioTheme={props.ioTheme}
                            ioMode={props.ioMode}
                            hoverEnable={props.hoverEnable}
                            strippedEnable={props.strippedEnable}
                            status={"expandedRow"}>
                            <Td colSpan={visibleColumns.length} styleName={props.styleName}
                                ioTheme={props.ioTheme}
                                ioMode={props.ioMode}
                                borderEnable={props.borderEnable}>
                                {subComponentType === "DataTable" ? InternalGrid({ row }) : ExpandableSubComponents({ row, ...props })}
                            </Td>
                        </Tr>
                    ) : null
                }
            </React.Fragment>
        )
    },
        [prepareRow, page]
    );

    return (
        <React.Fragment>
            <Table {...getTableProps()}
                styleName={props.styleName}
                ioTheme={props.ioTheme}
                ioMode={props.ioMode}
                borderEnable={props.borderEnable}
                strippedEnable={props.strippedEnable}
                hoverEnable={props.hoverEnable}>
                <Thead
                    styleName={props.styleName}
                    ioTheme={props.ioTheme}
                    ioMode={props.ioMode}
                    borderEnable={props.borderEnable}>
                    {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}
                            styleName={props.styleName}
                            ioTheme={props.ioTheme}
                            ioMode={props.ioMode}
                            hoverEnable={false}
                            strippedEnable={false}
                            status={"normal"}>
                            {headerGroup.headers.map(column => {
                                if (column.isAccessible === undefined || column.isAccessible) {
                                    let thProps = column.getHeaderProps();
                                    thProps['style'] = {};
                                    thProps.style["width"] = column.width;
                                    return <Th {...thProps}
                                        styleName={props.styleName}
                                        ioTheme={props.ioTheme}
                                        ioMode={props.ioMode}
                                        borderEnable={props.borderEnable}>
                                        {/* if group by is Enabled show Icon in Column Header*/}
                                        <div>
                                            {subComponentEnable === false && column.canGroupBy ? (
                                                <span {...column.getGroupByToggleProps()} className={column.groupByEnable ? column.isGrouped ? "fa fa-compress" : "fa fa-expand" : ''}>
                                                </span>
                                            ) : null}
                                            {/* Sorting Icons in Column Header*/}
                                            <span {...column.getSortByToggleProps()}>
                                                {column.render('Header')}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                        </div>
                                        {/* if Filter is Enabled*/}
                                        <div>{column.canFilter && column.filterEnable ? column.render('Filter') : null}</div>
                                    </Th>
                                }
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}
                    styleName={props.styleName}
                    ioTheme={props.ioTheme}
                    ioMode={props.ioMode}>
                    {
                        cardsData === undefined ? <Tr
                            styleName={props.styleName}
                            ioTheme={props.ioTheme}
                            ioMode={props.ioMode}
                            hoverEnable={props.hoverEnable}
                            strippedEnable={props.strippedEnable}
                            status={"normal"}>
                            <Td
                                colSpan={visibleColumns.length}
                                styleName={props.styleName}
                                ioTheme={props.ioTheme}
                                ioMode={props.ioMode}
                                borderEnable={props.borderEnable}>{"Loading..."}
                            </Td>
                        </Tr> : ""
                    }
                    {
                        cardsData === "empty" ? <Tr
                            styleName={props.styleName}
                            ioTheme={props.ioTheme}
                            ioMode={props.ioMode}
                            hoverEnable={props.hoverEnable}
                            strippedEnable={props.strippedEnable}
                            status={"normal"}>
                            <Td
                                colSpan={visibleColumns.length}
                                styleName={props.styleName}
                                ioTheme={props.ioTheme}
                                ioMode={props.ioMode}
                                borderEnable={props.borderEnable}>{"No Data Found"}
                            </Td>
                        </Tr> : ""
                    }
                    {
                        page.map((i, index) => {
                            return renderRowItem(index, props.selectedPath, errorData, selectedRowPath);
                        })
                    }
                </Tbody>
            </Table>
            {/*Code for Pagination it its is Enable*/}
            {data.length > 9 ? <Pagination gotoPage={gotoPage} canPreviousPage={canPreviousPage} pageCount={pageCount} canNextPage={canNextPage} pageIndex={pageIndex} pageOptions={pageOptions} setPageSize={setPageSize} pageSize={pageSize} nextPage={nextPage} previousPage={previousPage} styleName={props.styleName} ioTheme={props.ioTheme} ioMode={props.ioMode} /> : ""}
        </React.Fragment>
    )
}

export default InfoTable;