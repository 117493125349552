import gql from 'graphql-tag';

export const RootCompositeEntityNodeQuery = gql`
query RootCompositeEntityNode($id:ID!)
{
  RootCompositeEntityNode(id:$id){
      graphTemplate
    displayNodeName
    order
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    addedHidden
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
       childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
  }
}`;