import gql from 'graphql-tag';

export const CompositeEntityQuery = gql`
  query CompositeEntity($id: ID!)
{
  CompositeEntity(id:$id) {
    graphTemplate
    isFormLevelOperationAllowed
    isReleaseAreaNotApplicable
    logicalEntityId
    multiEntityOrder
    isMenuRequired
    isNodeTreeRequired
    rootDataSetId
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
     childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
 