
import { CardType } from '../constants/appeng.enum';

export default class CardData {

    id: string;
    type: CardType;
    data: any;
    referenceData: any;
    errorData: any;
    options: any;
    datagridErrorData: any;

    constructor(id: string, type: CardType, data: any,
        referenceData?: any, errorData?: any, options?: any, datagridErrorData?: any) {
        this.id = id;
        this.type = type;
        this.data = data;
        this.referenceData = referenceData ? referenceData : null;
        this.errorData = errorData ? errorData : {};
        this.options = options ? options : {};
        this.datagridErrorData = datagridErrorData ? datagridErrorData : {};
        Object.freeze(this);
    }
}
