import gql from 'graphql-tag';

export const LogicalColumnQuery = gql`
 query LogicalColumn($id: ID!)
{
  LogicalColumn(id:$id) {
    isPrimaryKey
    isDisplayColumn
    dataType
    isVerticalFieldKey
    dbCode
    length
    dbType
    isMandatory
    jsonName
    mode
    isUnique
    isDerived
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
