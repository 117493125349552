import React from 'react';
import { useTable, useSortBy, useFilters, useGroupBy, useExpanded, useBlockLayout } from 'react-table';
import { Table, Tbody, Td, Thead, Th, Tr } from "info-ui-library";
import { filterTypes } from "../DefaultConstants";
import ExpandableSubComponents from "./ExpandableSubComponents";
import DefaultColumnFilter from "../filterGridComponents/DefaultColumnFilter";
import DefaultCell from "../editableGridComponents/DefaultCell";
import InternalGrid from "./InternalGrid";
import InfiniteScroll from "react-infinite-scroll-component";

function InfoTable(props) {
    let columns = props.columns;
    let data = props.data;
    let errorData = props.errorDataExtractor("abc", "UUID", props.referralId, false);
    const gridHeight = window.innerHeight > 790 ? 69 : 64;
    const updateMyData = props.updateMyData;
    const subComponentEnable = props.subComponentEnable;
    const subComponentType = props.subComponentType;
    const defaultColumn = React.useMemo(
        () => ({
            width: "250",
            Filter: DefaultColumnFilter,
            Cell: DefaultCell
        }),
        []
    );

    const [selectedRowPath, setSelectedRowPath] = React.useState(null);
    let cardsData = props.cardsData ? props.cardsData.data ? props.cardsData.data.length > 0 ? "pass" : "empty" : undefined : undefined;

    const onClick = (row) => {
        setSelectedRowPath(row.id.toString());
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        visibleColumns,
        state: { groupBy, expanded },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            updateMyData,
            filterTypes,
        },
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        useBlockLayout
    );

    const renderVariableHeightItem = React.useCallback((index, selectedPath, errorOccurred, selectedRowPath) => {
        const row = rows[index];
        let status = "normal";
        if (row.id.toString() === selectedPath && errorOccurred && errorOccurred[selectedPath]) {
            status = "fail";
        } else if (row.id.toString() === selectedPath) {
            status = "success";
        } else if (row.id.toString() === selectedRowPath) {
            status = "selected";
        }
        prepareRow(row)
        return (
            <>
                <Tr
                    styleName={props.styleName}
                    ioTheme={props.ioTheme}
                    ioMode={props.ioMode}
                    hoverEnable={props.hoverEnable}
                    strippedEnable={props.strippedEnable}
                    style={{ lineHeight: `40px` }}
                    status={status}
                    onClick={() => onClick(row)}
                >
                    {row.cells.map(cell => {
                        if (cell.column.isAccessible) {
                            let tdProps = cell.getCellProps();
                            tdProps["style"] = {};
                            tdProps.style["padding"] = "0.1rem"
                            return (
                                <Td {...tdProps}
                                    styleName={props.styleName}
                                    ioTheme={props.ioTheme}
                                    ioMode={props.ioMode}
                                    borderEnable={props.borderEnable} >
                                    {cell.isGrouped ? (
                                        <React.Fragment>
                                            <span {...row.getToggleRowExpandedProps()} className={row.isExpanded ? "fa fa-sort-desc" : "fa fa-play"} />
                                            {cell.render('Cell')} ({row.subRows.length})
                                        </React.Fragment>
                                    ) : cell.isAggregated && row.subRows.length === 0 ? (
                                        cell.render('Aggregated')
                                    ) : cell.isRepeatedValue && row.subRows.length === 0 ? cell.render(DefaultCell) : (
                                        cell.render('Cell', { editable: true, subComponentEnable: subComponentEnable })
                                    )}
                                </Td>
                            )
                        }
                    })
                    }
                </Tr >
                {/* if subrows are present*/}
                {
                    row.isExpanded && row.subRows.length === 0 ? (
                        <Tr styleName={props.styleName}
                            ioTheme={props.ioTheme}
                            ioMode={props.ioMode}
                            hoverEnable={props.hoverEnable}
                            strippedEnable={props.strippedEnable}
                            status={"expandedRow"}>
                            <Td colSpan={visibleColumns.length} styleName={props.styleName}
                                ioTheme={props.ioTheme}
                                ioMode={props.ioMode}
                                borderEnable={props.borderEnable}>
                                {subComponentType === "DataTable" ? InternalGrid({ row }) : ExpandableSubComponents({ row, ...props })}
                            </Td>
                        </Tr>
                    ) : null
                }
            </>
        )
    },
        [prepareRow, rows]
    );

    return (

        <Table {...getTableProps()}
            styleName={props.styleName}
            ioTheme={props.ioTheme}
            ioMode={props.ioMode}
            borderEnable={props.borderEnable}
            strippedEnable={props.strippedEnable}
            hoverEnable={props.hoverEnable}
        >
            <Thead styleName={props.styleName}
                ioTheme={props.ioTheme}
                ioMode={props.ioMode}
                borderEnable={props.borderEnable}
            >
                {headerGroups.map(headerGroup => {
                    let trProps = headerGroup.getHeaderGroupProps();
                    trProps.style.width = '100%';
                    return <Tr {...trProps} status={"normal"}>
                        {headerGroup.headers.map(column => {
                            if (column.isAccessible) {
                                return <Th {...column.getHeaderProps()}
                                    styleName={props.styleName}
                                    ioTheme={props.ioTheme}
                                    ioMode={props.ioMode}
                                    borderEnable={props.borderEnable}>
                                    <div>
                                        {subComponentEnable === false && column.canGroupBy ? (
                                            <span {...column.getGroupByToggleProps()} className={column.groupByEnable ? column.isGrouped ? "fa fa-compress" : "fa fa-expand" : ''}>
                                            </span>
                                        ) : null}
                                        <span {...column.getSortByToggleProps()}>
                                            {column.render('Header')}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </div>
                                    <div>{column.canFilter && (column.filterEnable || false) ? column.render('Filter') : null}</div>
                                </Th>
                            }
                        })}
                    </Tr>
                })}
            </Thead>

            <Tbody {...getTableBodyProps()}
                styleName={props.styleName}
                ioTheme={props.ioTheme}
                ioMode={props.ioMode}
            >
                {
                    cardsData === undefined ? <Tr
                        styleName={props.styleName}
                        ioTheme={props.ioTheme}
                        ioMode={props.ioMode}
                        hoverEnable={props.hoverEnable}
                        strippedEnable={props.strippedEnable}
                        status={"normal"}>
                        <Td
                            colSpan={visibleColumns.length}
                            styleName={props.styleName}
                            ioTheme={props.ioTheme}
                            ioMode={props.ioMode}
                            borderEnable={props.borderEnable}>{"Loading..."}
                        </Td>
                    </Tr> :
                        cardsData === "empty" ? <Tr
                            styleName={props.styleName}
                            ioTheme={props.ioTheme}
                            ioMode={props.ioMode}
                            hoverEnable={props.hoverEnable}
                            strippedEnable={props.strippedEnable}
                            status={"normal"}>
                            <Td
                                colSpan={visibleColumns.length}
                                styleName={props.styleName}
                                ioTheme={props.ioTheme}
                                ioMode={props.ioMode}
                                borderEnable={props.borderEnable}>No Data Found
                            </Td>
                        </Tr> :
                            <InfiniteScroll
                                dataLength={rows.length}
                                hasMore={false}
                                height={(window.innerHeight * gridHeight) / 110}
                                loader={<h4>Loading...</h4>}
                                className={"info-react-table"}
                            >
                                {rows.map((i, index) => {
                                    return renderVariableHeightItem(index, props.selectedPath, errorData, selectedRowPath);
                                })}
                            </InfiniteScroll>
                }
            </Tbody>

        </Table >

    )
}
export default InfoTable;