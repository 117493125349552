export default [
    {
        "USER_STORY": "summer",
        "TASK": "collection",
        "STATUS": "complicated"
    },
    {
        "USER_STORY": "summer",
        "TASK": "collection",
        "STATUS": "complicated"
    },
    {
        "USER_STORY": "summer",
        "TASK": "collection",
        "STATUS": "complicated"
    },
    {
        "USER_STORY": "summer",
        "TASK": "collection",
        "STATUS": "complicated"
    },
    {
        "USER_STORY": "summer",
        "TASK": "collection",
        "STATUS": "complicated"
    },
    {
        "USER_STORY": "detail",
        "TASK": "north",
        "STATUS": "relationship"
    },
    {
        "USER_STORY": "porter",
        "TASK": "front",
        "STATUS": "complicated"
    },
    {
        "USER_STORY": "oven",
        "TASK": "wine",
        "STATUS": "single"
    },
    {
        "USER_STORY": "car",
        "TASK": "space",
        "STATUS": "relationship"
    },
    {
        "USER_STORY": "examination",
        "TASK": "furniture",
        "STATUS": "relationship"
    },
    {
        "USER_STORY": "leader",
        "TASK": "beggar",
        "STATUS": "relationship"
    }
]