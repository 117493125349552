export default {
    "LogicalEntity": {
        "child": [
            {
                "PhysicalEntity": {
                    "child": [
                        {
                            "PhysicalColumn": null
                        }
                    ]
                }
            },
            {
                "LogicalColumn": null
            }
        ]
    }
}