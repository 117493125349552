import React, { useContext } from 'react';
import {
    TextBox
} from 'info-ui-library';
import styled from 'styled-components';
import { themes } from '../theme/ThemeOptions';
import { ThemeContext } from '../theme/ThemeContext';
import { useDispatch, useSelector } from 'react-redux';
import { setChangeFormData } from '../actions/index';
const InnerContainer = styled.div`{
    padding-right:15px;
    padding-left:15px;
    display: flex!important;
}`


const handleChange = (updatedValue, dbCode, type, dispatch) => {
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, type));
}

const onBlur = (updatedValue, dbCode, type, dispatch) => {
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, type));
}


const ConfigItemComponent = props => {
    const dispatch = useDispatch();
    const screenDetails = useSelector((state: any) => state.screenDetails);
    const themeContext = useContext(ThemeContext);
    try {
        return (
            <InnerContainer><TextBox
                styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                label="Config Item Name"
                configId={"config_item_name"}
                key="itemName"
                value={props.formData.item ? props.formData.item["name"] : ""}
                displayType="FloatingLabel"
                handleChange={(updatedValue) => {
                    handleChange(updatedValue, "name", "item", dispatch)
                }}

                onBlur={(updatedValue) => {
                    onBlur(updatedValue, "name", "item", dispatch)
                }}
                errorMessage={props.errorData["name"]}
                hasError={props.errorData["name"] ? true : false}
                mandatory={true}
                isButtonTextBox={false}
            /><TextBox
                    styleName={themeContext.styleName}
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    label="Config Item Type"
                    configId={"config_item_type"}
                    key="itemType"
                    value={screenDetails.configType}
                    displayType="FloatingLabel"
                    handleChange={(updatedValue) => {
                        handleChange(updatedValue, "type", "item", dispatch)
                    }}

                    onBlur={(updatedValue) => {
                        onBlur(updatedValue, "type", "item", dispatch)
                    }}
                    isFilterComponent={true}
                    hasError={false}
                    mandatory={false}
                    isButtonTextBox={false}
                    disabled={true}
                /></InnerContainer>
        )
    } catch (e) {
        return <div></div>
    }
}
export default ConfigItemComponent;