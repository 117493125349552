import gql from 'graphql-tag';

export const PortalForm = gql`
mutation createPortalForm($input:PortalFormInput)
 {
    createPortalForm(input:$input) {
	configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    nodeId
    ceId
    logicalEntityId
    formId
    mode
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        
          
    }
}
`;