
export default {
    "Portal": {
        "child": [
            {
                "PortalCard": {
                    "child": [
                        {
                            "TabGroup": {
                                "child": [
                                    {
                                        "TabPortal": {
                                            "child": [
                                                {
                                                    "SubPortal": {
                                                        "child": [
                                                            {
                                                                "PortalCard": {
                                                                    "child": [
                                                                        {
                                                                            "ButtonPanel": {
                                                                                "child": [
                                                                                    {
                                                                                        "Button": null
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }

                                                                    ]
                                                                }
                                                            }

                                                        ]
                                                    }
                                                },
                                                {
                                                    "ButtonPanel": {
                                                        "child": [
                                                            {
                                                                "Button": null
                                                            }
                                                        ]
                                                    }
                                                }

                                            ]
                                        }
                                    }

                                ]
                            }
                        },
                        {
                            "SubPortal": {
                                "child": [
                                    {
                                        "PortalCard": {
                                            "child": [
                                                {
                                                    "ButtonPanel": {
                                                        "child": [
                                                            {
                                                                "Button": null
                                                            }
                                                        ]
                                                    }
                                                }

                                            ]
                                        }
                                    }

                                ]
                            }
                        }

                    ]
                }
            },
            {
                "HelpItem": null
            },
            {
                "ButtonPanel": {
                    "child": [
                        {
                            "Button": {
                                "child": [
                                    {
                                        "ActionFlow": {
                                            "child": [
                                                {
                                                    "MicroFlow": null
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "MenuButton": {
                                "child": [
                                    {
                                        "Button": {
                                            "child": [
                                                {
                                                    "ActionFlow": {
                                                        "child": [
                                                            {
                                                                "MicroFlow": null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    }
}