export default class ApplicationRole {
    aeApplicationUuid: String;
    aeInsertId: String;
    aeInsertTs: Date;
    aeRoleUuid: String;
    aeTransactionId: String;
    aeUpdateId: String;
    aeUpdateTs: Date;
    aeUserAuthUuid: String;
    aeUserMRoleUuid: String;
    isDefault: number;
    isdeleted: number;
    tenantId: String;


    constructor(aeApplicationUuid: String, aeInsertId: String, aeInsertTs: Date, aeRoleUuid: String, aeTransactionId: String, aeUpdateId: String, aeUpdateTs: Date, aeUserAuthUuid: String, aeUserMRoleUuid: String, isDefault: number, isdeleted: number, tenantId: String) {
        this.aeApplicationUuid = aeApplicationUuid;
        this.aeInsertId = aeInsertId;
        this.aeInsertTs = aeInsertTs;
        this.aeRoleUuid = aeRoleUuid;
        this.aeTransactionId = aeTransactionId;
        this.aeUpdateId = aeUpdateId;
        this.aeUpdateTs = aeUpdateTs;
        this.aeUserAuthUuid = aeUserAuthUuid;
        this.aeUserMRoleUuid = aeUserMRoleUuid;
        this.isDefault = isDefault;
        this.isdeleted = isdeleted;
        this.tenantId = tenantId;
        Object.freeze(this);
    }
}