import gql from 'graphql-tag';

export const TabPortalQuery = gql`
query TabPortal($id:ID!){
    TabPortal(id:$id){
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    tabName
    isDefaultOpen
    order
    cardStyle	
    cardBodyStyle
    cardHeaderStyle
    expressionAvailable
    accessibilityRegex
       childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    }
    
}`;