import React from 'react';
import { Button } from 'info-ui-library';

const Pagination = (props) => {

    const [internalPageIndex, setInternalPageIndex] = React.useState(0);

    React.useEffect(() => {
        props.gotoPage(internalPageIndex)
    }, [internalPageIndex])

    const setInternalPageSize = (pageSize) =>{

        var topRowIndex = props.pageOptions.length * internalPageIndex;
        var pageIndex = Math.floor(topRowIndex / pageSize);
        setInternalPageIndex(pageIndex)
    };

    return (
        <div>
            <Button onClick={() => setInternalPageIndex(0)} styleName={props.styleName} ioTheme={props.ioTheme} ioMode={props.ioMode} size="sm" className="fa fa-fast-backward" name={""} disabled={!props.canPreviousPage} />
            <Button onClick={() => setInternalPageIndex(internalPageIndex-1)} disabled={!props.canPreviousPage} styleName={props.styleName} ioTheme={props.ioTheme} ioMode={props.ioMode} size="sm" className="fa fa-step-backward" name={""} />
            <Button onClick={() => setInternalPageIndex(internalPageIndex+1)} disabled={!props.canNextPage} styleName={props.styleName} ioTheme={props.ioTheme} ioMode={props.ioMode} size="sm" className="fa fa-step-forward " name={""} />
            <Button onClick={() => setInternalPageIndex(props.pageCount - 1)} disabled={!props.canNextPage} styleName={props.styleName} ioTheme={props.ioTheme} ioMode={props.ioMode} size="sm" className="fa fa-fast-forward" name={""} />
            <span>
                Page{' '}
                <strong>
                    {internalPageIndex+1} of {props.pageOptions.length}
                </strong>{' '}
            </span>
            <span style={{ marginTop: "-3px" }}>
                | Go to page:{' '}
                <input
                    type="number" min={1} max={props.pageOptions.length}
                    defaultValue={internalPageIndex+1}
                    onChange={e => {
                    e.target.value <= props.pageOptions.length && setInternalPageIndex(Number(e.target.value)-1) ;
                    }}
                    style={{ width: '100px' }}
                />
            </span>{' '}
            <select style={{ marginTop: "-3px", marginLeft: "5px" }}
                value={props.pageSize}
                onChange={e => {
                    props.setPageSize(Number(e.target.value))
                    setInternalPageSize(Number(e.target.value))
                }}
            >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default Pagination;
