import gql from 'graphql-tag';

export const PhysicalColumn = gql`
mutation createPhysicalColumn($input:PhysicalColumnInput)
 {
    createPhysicalColumn(input:$input) {
        isKey
        dbCode
        length
        isDisplayColumn
        isPrimaryKey
        dataType
        dbType
        jsonName
        isAlternatePrimaryKey
        isLogicalColumnRequired
        isUnique
        isVirtualColumn
        isMultiValueField
        isPhysicalColumnMandatory
        aliasedDBCode
        configObjectId
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
           childRelations {
                relationType
                childItemId
             }
              parentRelations {
               relationType
               parentItemId
             }
              privileges {
               privilegeType
               roleId
             }
    }
}
`;
