import React from 'react';
import { Button } from 'info-ui-library';
import { useDispatch } from 'react-redux';

function handleClick(onClickHandler, parentProps,actionColumnType, hrefValue, rowDetails, dispatch) {
    let column = {};
    column["actionColumnType"] = actionColumnType;
    column["hrefValue"] = hrefValue;
    onClickHandler(column, rowDetails, parentProps, dispatch,actionColumnType);
}

const ActionButton = ({
    column,
    row
}) => {
    const dispatch = useDispatch();
    return column.buttons.map(button => {
        if (button.isAccessible) {
            return <Button
                className={button.icon}
                name={button.HeaderName}
                id={button.id}
                disabled={button.disabled}
                styleName={column.styleName}
                ioTheme={column.ioTheme}
                ioMode={column.ioMode}
                onClick={(e) => handleClick(button.onClickHandler, button.parentProps, button.actionColumnType, button.hrefValue, row, dispatch)}
                size="sm" ></Button>
        }
    })
}

export default ActionButton;