import React, { useState, useEffect, useContext } from 'react';
import ConfigItemComponent from '../components/ConfigItemComponent';
import ConfigItemPropertyComponent from '../components/ConfigItemPropertyComponent';
import ActionButtons from '../components/ActionButtons';
import Priviledge from '../components/Priviledge';
import ChildRelation from '../components/ChildRelation';
import ParentRelation from '../components/ParentRelation';
import CardData from '../models/carddata.model';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { CardType } from '../constants/appeng.enum';
import { setFormDatas } from '../actions/index';
import "../grid-layout.css";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { resolveColor } from '../theme/ThemeOptions';
import { ThemeContext } from '../theme/ThemeContext';

interface IConfigPortalForm {
  className?: string,
  rowHeight?: number,
  cols?: any,
  breakpoints?: any,
  layout?: any
}
export const Stylediv = styled(Col)`
    padding-left:15px !important;
    padding:15px !important;
      box-sizing: border-box;
     border:none;
      color :rgb(23,162,184);
      box-shadow:0 4px 8px 0 rgba(0,0,0,0.2);
    
`
export const StyleResponsiveReactGridLayout = styled(Card)`
  border: none !important;
  background: ${ p =>
    resolveColor(p.ioTheme, p.ioMode, p.styleName, 'color')};
  color: ${ p => resolveColor(p.ioTheme, p.ioMode, p.styleName, 'menucolor')};

&.rc-menu-horizontal > .rc-menu-item-selected{
  background: ${ p =>
    resolveColor(p.ioTheme, p.ioMode, p.styleName, 'menuitemselectedbgcolor')};
  color: ${ p =>
    resolveColor(p.ioTheme, p.ioMode, p.styleName, 'menuitemselectedcolor')};
}
`
const handleChange = (dispatch, formData) => {
  const cardData = new CardData("test555", CardType.FORM, { ...formData }, {});
  dispatch(setFormDatas(cardData, "test555"));
}
const ConfigPortalForm: React.FC<IConfigPortalForm> = (props: IConfigPortalForm) => {
  const existingErrorData = useSelector((state: any) => state.errorData);
  const cardsData = useSelector((state: any) => state.cardsData);
  const screenDetails = useSelector((state: any) => state.screenDetails);
  const [formData, setFormData] = useState({
    item: {}, property: {}, privilege: [], parentRelation: [], childRelation: [], ...cardsData.data
  });
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (screenDetails.parentItemId !== "" && screenDetails.configItemId === "") {
      setFormData({
        item: {}, property: {}, privilege: [],
        childRelation: [], ...cardsData.data, parentRelation: [{
          relationType: screenDetails.relationType,
          parentItemId: screenDetails.parentItemId, isDeleted: 0
        }]
      })
    } else {
      setFormData({
        item: {}, property: {}, privilege: [], parentRelation: [],
        childRelation: [], ...cardsData.data
      })
    }

  }, [cardsData])

  let property: any = <div></div>;
  if (screenDetails.configType != "ActionFlow") {
    property = <Stylediv xs="12"
      md="8"
      lg="8"
      sm="12" key="config-property" ioTheme={themeContext.ioTheme} ioMode={themeContext.ioMode} styleName={themeContext.styleName}>
      ConfigItem Property<ConfigItemPropertyComponent layouts={{ "lg": props.layout }} errorData={existingErrorData} setFormData={(formData) => handleChange(dispatch, formData)}
        formData={formData} /></Stylediv>
  }

  return (
    <StyleResponsiveReactGridLayout
      margin={[5, 5]} ioTheme={themeContext.ioTheme}
      ioMode={themeContext.ioMode} styleName={themeContext.styleName}
    >
      <Row>
        <Stylediv xs={{ span: 12, order: 1 }}
          md={{ span: 4, order: 2 }}
          lg={{ span: 4, order: 2 }}
          sm={{ span: 12, order: 1 }}
          key="action-buttons" style={{ height: "auto" }}
          ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
          styleName={themeContext.styleName}>
          Action Button<ActionButtons /></Stylediv>
        <Stylediv xs={{ span: 12, order: 2 }} style={{ height: "auto" }}
          md={{ span: 8, order: 1 }}
          lg={{ span: 8, order: 1 }}
          sm={{ span: 12, order: 2 }} key="config-item" ioTheme={themeContext.ioTheme} ioMode={themeContext.ioMode} styleName={themeContext.styleName}>
          ConfigItem<ConfigItemComponent errorData={existingErrorData} setFormData={(formData) => handleChange(dispatch, formData)} formData={formData} /></Stylediv>
      </Row>
      <Row>
        {property}
        <Stylediv xs="12"
          md="4"
          lg="4"
          sm="12" key="config-privilege" ioTheme={themeContext.ioTheme} ioMode={themeContext.ioMode} styleName={themeContext.styleName}>
          ConfigItem Privilege <Priviledge setFormData={(formData) => handleChange(dispatch, formData)} formData={formData} /></Stylediv>
      </Row>
      <Row>
        <Stylediv xs="12"
          md="12"
          lg="12"
          sm="12" key="config-child-relation" ioTheme={themeContext.ioTheme} ioMode={themeContext.ioMode} styleName={themeContext.styleName}>
          ConfigItem Child Relation<ChildRelation setFormData={(formData) => handleChange(dispatch, formData)} formData={formData} /></Stylediv>
        <Stylediv xs="12"
          md="12"
          lg="12"
          sm="12" key="config-parent-relation" ioTheme={themeContext.ioTheme} ioMode={themeContext.ioMode} styleName={themeContext.styleName}
        >ConfigItem Parent Relation<ParentRelation setFormData={(formData) => handleChange(dispatch, formData)} formData={formData} /></Stylediv>
      </Row> </StyleResponsiveReactGridLayout>

  );
}

ConfigPortalForm.defaultProps = {
  className: "layout",
  rowHeight: 25,
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },

};
export default ConfigPortalForm;