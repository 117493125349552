import gql from 'graphql-tag';

export const ButtonQuery = gql`
  query Button($id: ID!)
  {
  Button(id:$id) {
    label
    order
    portalId
    buttonClass
    warningMessage
    buttonAlignment
    accessibilityRegex
    editabilityRegex
    expressionAvailable
    modalRequired
    expressionFieldString
    dbCode
    icon
    iconFirst
    buttonStyle
    toolTip
    modalCss
    transactionName
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;