export const themes = [
    {
        name: 'default',
        ioMode: {
            dark: {
                primary: {
                    background: '#0073aa',
                    color: 'white',
                    borderColor: '#0073aa',
                    hoverBGColor: '#fdfdfdf7',
                    checkMarkColor: 'red',
                    active: '#112469',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#0073aa',
                    textcolor: 'white'
                },
                secondary: {
                    background: '#868e96',
                    color: 'white',
                    borderColor: '#868e96',
                    hoverBGColor: '#fdfdfdf7',
                    checkMarkColor: 'white',
                    active: '#595A5C',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#868e96',
                    textcolor: 'black'
                },
                success: {
                    background: '#28a745',
                    color: 'white',
                    borderColor: '#28a745',
                    hoverBGColor: '#fdfdfdf7',
                    checkMarkColor: 'green',
                    active: 'green',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#28a745',
                    textcolor: 'white'
                },
                info: {
                    background: '#1997c6',
                    color: 'white',
                    borderColor: '#1997c6',
                    hoverBGColor: '#fdfdfdf7',
                    checkMarkColor: 'pink',
                    active: '#112469',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#1997c6',
                    textcolor: 'black'

                },
                warning: {
                    background: '#fdff00',
                    color: 'black',
                    borderColor: '#fdff00',
                    hoverBGColor: '#fdfdfdf7',
                    checkMarkColor: 'blue',
                    active: '#BCCA18',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#fdff00',
                    textcolor: 'black'
                },
                danger: {
                    background: '#d9534f',
                    color: 'red',
                    borderColor: '#dc3545',
                    hoverBGColor: '#fdfdfdf7',
                    checkMarkColor: 'grey',
                    active: '#F9B89D',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#d9534f',
                    textcolor: 'white'
                },
                link: {
                    background: 'blue',
                    color: 'white',
                    borderColor: 'blue',
                    hoverBGColor: '#fdfdfdf7',
                    panel: 'azure',
                    menubackground: 'blue',
                    menucolor: 'white',
                    menuhoverbgcolor: '#fdfdfdf7',
                    menuhovercolor: ''
                }
            },
            light: {
                primary: {
                    background: '#B33B00',
                    color: 'white',
                    borderColor: 'antiquewhite',
                    hoverBGColor: '#fdfdfdf7',
                    tableBorderColor: '1px solid white',
                    tableBackground: '#e9f7fc',
                    tableHeaderColor: '#20a8d8',
                    tableAccentColor: '#bce6f5',
                    tableHoverColor: '#f2f2f2',
                    checkMarkColor: 'red',
                    active: '#112469',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#3c8dbc',
                    textcolor: 'white'
                },
                secondary: {
                    background: '#c8ced3',
                    color: 'white',
                    borderColor: '#c8ced3',
                    hoverBGColor: '#fdfdfdf7',
                    tableBorderColor: '1px solid White',
                    tableBackground: '#ffffff',
                    tableHeaderColor: '#394047',
                    tableAccentColor: '#ffffff',
                    tableHoverColor: '#f2f2f2',
                    checkMarkColor: 'white',
                    active: '#595A5C',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#c8ced3',
                    textcolor: 'black'
                },
                success: {
                    background: '#4dbd74',
                    color: 'white',
                    borderColor: '#4dbd74',
                    hoverBGColor: '#fdfdfdf7',
                    tableBorderColor: '1px solid White',
                    tableBackground: '#ecf8f0',
                    tableHeaderColor: '#30824d',
                    tableAccentColor: '#daf1e2',
                    tableHoverColor: '#f2f2f2',
                    checkMarkColor: 'green',
                    active: 'green',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#4dbd74',
                    textcolor: 'white'
                },
                info: {
                    background: '#004269',
                    color: 'white',
                    borderColor: '#004269',
                    hoverBGColor: '#fdfdfdf7',
                    tableBorderColor: '1px solid white',
                    tableBackground: '#daf2e6',
                    tableHeaderColor: '#004269',
                    tableAccentColor: '#e6fff2',
                    tableHoverColor: '#f2f2f2',
                    checkMarkColor: 'pink',
                    active: '#112469',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#004269',
                    textcolor: 'white'
                },
                warning: {
                    background: '#ffc107',
                    color: 'white',
                    borderColor: '#ffc107',
                    hoverBGColor: '#fdfdfdf7',
                    tableBorderColor: '1px solid white',
                    tableBackground: '#f2e0aa',
                    tableHeaderColor: '#cc9900',
                    tableAccentColor: '#ffecb3',
                    tableHoverColor: '#f2f2f2',
                    checkMarkColor: 'blue',
                    active: '#BCCA18',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#ffc107',
                    textcolor: 'black'
                },
                danger: {
                    background: '#f86c6b',
                    color: 'red',
                    borderColor: '#f86c6b',
                    hoverBGColor: '#fdfdfdf7',
                    tableBorderColor: '1px solid white',
                    tableBackground: '#fee7e7',
                    tableHeaderColor: '#c30909',
                    tableAccentColor: '#fee7e7',
                    tableHoverColor: '#f2f2f2',
                    checkMarkColor: 'grey',
                    active: '#F9B89D',
                    inkbar: 'deepskyblue',
                    inkbarShadow: '#070707d6',
                    footerbackground: '#f86c6b',
                    textcolor: 'white'
                },
                link: {
                    background: 'blue',
                    color: 'white',
                    hoverBGColor: '#fdfdfdf7'
                }
            }
        },
        uiLibrary: 'bootstrap'
    },

    {
        name: 'mugreen',
        ioMode: {
            light: {
                primary: {
                    background: 'green',
                    color: 'white'
                }
            },
            light1: {
                primary: {
                    background: '#6f42c1',
                    color: 'red'
                }
            }
        },
        uiLibrary: 'materialui'
    }
];

export interface IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink {
    background: string;
    color: string;
    borderColor: string;
    checkMarkColor: string;
}

export interface IDark {
    primary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    secondary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    success: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    info: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    warning: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    danger: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    link: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
}

export interface ILink {
    background: string;
    color: string;
}

export interface ILight {
    primary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    secondary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    success: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    info: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    warning: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    danger: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
    link: ILink;
}

export interface IMode {
    dark: IDark;
    light: ILight;
}

export interface ITheme {
    name: string;
    ioMode: IMode;
    uiLibrary: string;
}

export const resolveColor = (
    theme: ITheme,
    mode: string,
    styleName: string,
    attribute: string
) => {
    return theme.ioMode[mode][styleName ? styleName : 'primary'][attribute];
};

export const resolver = (
    enable: boolean,
    theme: ITheme,
    mode: string,
    styleName: string,
    attribute: string
) => {
    return enable
        ? theme.ioMode[mode][styleName ? styleName : 'primary'][attribute]
        : '';
};
