import gql from 'graphql-tag';

export const FormQuery = gql`
  query Form($id: ID!)
  {
    Form(id:$id) {
        name
        configObjectType
        isDeleted
        projectId
        formLabel
        formType
        isRepeatable
        order
        tabRequiredInFormsection
        expressionAvailable
    accessibilityRegex
    editabilityRegex 
    expressionFieldString 
    defaultDataString
    maxRepeatation
    minRepeatation
    defaultRepeatation
    repeatationStyle
    isDeletionAllowed
    addAllowed
    insert_ts
    update_ts
    createdBy
    updatedBy
    filterFormReloadControl
        childRelations {
          relationType
          insert_ts
          update_ts
          createdBy
          updatedBy
          childItemId
        }
        parentRelations {
          relationType
          insert_ts
          update_ts
          createdBy
          updatedBy
          parentItemId
        }
        privileges {
          privilegeType
          insert_ts
          update_ts
          createdBy
          updatedBy
          roleId
        }
  }
  }`;
