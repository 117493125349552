import gql from 'graphql-tag';

export const PhysicalColumnQuery =gql`
query PhysicalColumn($id:ID!)
{
    PhysicalColumn(id:$id){
        isKey
    dbCode
    length
    isDisplayColumn
    isPrimaryKey
    dataType
    dbType
    jsonName
    isAlternatePrimaryKey
    isLogicalColumnRequired
    isUnique
    isVirtualColumn
    isMultiValueField
    isPhysicalColumnMandatory
    aliasedDBCode
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
       childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    }
}`;

