import React, { useContext, useCallback } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { ReactToast ,FormErrorContainers } from 'info-ui-library';
import { useSelector } from 'react-redux';

const ReactToastComponent: React.FC = (props) => {
    const themeContext = useContext(ThemeContext);
    const toggleMessages = useSelector((state: any) => state.toggleMessages);
    
    try {
        if (toggleMessages.length) {
            return <React.Fragment>{toggleMessages.map(msg => {
                return (
                    <ReactToast ioTheme={themeContext.ioTheme}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        message={msg}
                        autoClose={2000}
                        key={Math.floor(Math.random() * 100)}
                    />
                )
            })}</React.Fragment>
        } else {
            return null;
        }
    }
    catch{
        const error = ['Error occurred in React Toast']
        return <FormErrorContainers ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }
}

export default ReactToastComponent;
