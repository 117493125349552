import gql from 'graphql-tag';

export const Database = gql`
mutation createDatabase($input:DatabaseInput)
 {
    createDatabase(input:$input) {
    host
    port
    dbname
    user
    password
    engine
    features
    attachmentProvider
    awsAccessKey
    awsSeceretKey
    awsRegion
    awsTempBucket
    awsBucket
    awsDeleteBucket
	configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
privileges{
    privilegeType
    roleId
    isDeleted
}

  
    }
}
`;

