import gql from 'graphql-tag';

export const StandardValidation = gql`
mutation createStandardValidation($input:StandardValidationInput)
 {
    createStandardValidation(input:$input) {
    name
    configObjectId
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    mode
    defaultErrorMessage
    validationType
    regex
    isConditionAvailable
    conditionExpression
    conditionFields
    description
       childRelations {
            relationType
            childItemId
         }
          parentRelations {
           relationType
           parentItemId
         }
          privileges {
           privilegeType
           roleId
         }
    }
}
`;
