import form from './form';
import portal from './portal';
import logicalEntity from './logicalEntity';
import compositeEntity from './compositeEntity';
import Validation from './Validation';
import sortableTreeData from './sortableTreeData';
import dataSetRel from './dataSetRel';
import datagrid from './datagrid';
import menuGroup from './menuGroup';
import actionFlow from './actionFlow';
import tabGroup from './tabGroup';
import subPortal from './subPortal';
import button from './button';
import chart from './chart';
import datasource from './datasource';
export default sortableTreeData;
export const getRelation = (configType: string) => {
    switch (configType) {
        case 'Form':
            let formTree = JSON.parse(JSON.stringify(form))
            return formTree;
        case 'Portal':
            let portalTree = JSON.parse(JSON.stringify(portal))
            return portalTree;
        case 'LogicalEntity':
            let logicalEntityTree = JSON.parse(JSON.stringify(logicalEntity))
            return logicalEntityTree;
        case 'Validation':
            let validationTree = JSON.parse(JSON.stringify(Validation))
            return validationTree;
        case 'DataSetRel':
            let dataSetRelTree = JSON.parse(JSON.stringify(dataSetRel))
            return dataSetRelTree;
        case 'CompositeEntity':
            let compositeEntityTree = JSON.parse(JSON.stringify(compositeEntity))
            return compositeEntityTree;
        case 'DataGrid':
            let datagridTree = JSON.parse(JSON.stringify(datagrid))
            return datagridTree;
        case 'MenuGroup':
            let menuGroupTree = JSON.parse(JSON.stringify(menuGroup))
            return menuGroupTree;
        case 'ActionFlow':
            let actionFlowTree = JSON.parse(JSON.stringify(actionFlow))
            return actionFlowTree;
        case 'TabGroup':
            let tabGroupTree = JSON.parse(JSON.stringify(tabGroup))
            return tabGroupTree;
        case 'SubPortal':
            let subPortalTree = JSON.parse(JSON.stringify(subPortal))
            return subPortalTree;
        case 'Button':
            let buttonTree = JSON.parse(JSON.stringify(button))
            return buttonTree;
        case 'Widget':
            let chartTree = JSON.parse(JSON.stringify(chart))
            return chartTree;
        case 'DataSource':
            let dataSourceTree = JSON.parse(JSON.stringify(datasource))
            return dataSourceTree;
        default:
            return null;
    }
};

