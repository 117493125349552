import DefaultColumnFilter from './filterGridComponents/DefaultColumnFilter';
import { fuzzyTextFilterFn } from "./filterGridComponents/FilterType";
import DefaultCell from "./editableGridComponents/DefaultCell";

export const deleteActionHandler = (e) => {
    //console.log("deleteActionHandler  clicked ",e);
}

export const viewEditActionHandler = (e) => {
    //console.log("viewEditActionHandler  clicked ",e);
}

export const filterTypes = {
    fuzzyText: fuzzyTextFilterFn,
    text: (rows, id, filterValue) => {
        return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
        })
    },
}

export const defaultColumn = {
    Filter: DefaultColumnFilter,
    Cell: DefaultCell
};