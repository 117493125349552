import gql from 'graphql-tag';

export const LogicalEntity = gql`
mutation createLogicalEntity($input:LogicalEntityInput)
 {
    createLogicalEntity(input:$input) {
        name
        configObjectId
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        dbTypeName
        supportedFlavor
        generateSkeleton
          childRelations {
              relationType
              childItemId
            }
            parentRelations {
              relationType
              parentItemId
            }
            privileges {
              privilegeType
              roleId
            }
        
    }
}
`;
