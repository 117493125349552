
import React from 'react';
import DataSharingColumnComponent from './DataSharingColumnComponent';

const DataSharingRowComponent: React.FC<any> = (props: any) => {

    return (
        <React.Fragment>
            {
                Object.keys(props.rowData).map(element => {
                    return (
                        <DataSharingColumnComponent
                            dataMap={props.dataMap}
                            handleChange={props.handleChange}
                            dbCode={element}
                            rowIndex={props.rowIndex}
                            rowData={props.rowData}
                            cardId={props.cardId}
                        />
                    )
                })
            }
        </React.Fragment>
    )

}

export default (DataSharingRowComponent);