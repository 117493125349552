import { getClient } from '../utils/ClientUtils';
import { call, put } from 'redux-saga/effects';
import { ConfigItem } from '../api/metadata-query/ConfigItem';
import { Form } from '../api/metadata-mutation/Form';
import { FormSection } from '../api/metadata-mutation/FormSection';
import { DataSetRel } from '../api/metadata-mutation/DataSetRel';
import { DataGrid } from '../api/metadata-mutation/DataGrid';
import { LogicalColumn } from '../api/metadata-mutation/LogicalColumn';
import { PhysicalColumn } from '../api/metadata-mutation/PhysicalColumn';
import { StandardValidation } from '../api/metadata-mutation/StandardValidation';
import { DataBaseValidation } from '../api/metadata-mutation/DataBaseValidation';
import { FormDbValidation } from '../api/metadata-mutation/FormDbValidation';
import { FormField } from '../api/metadata-mutation/FormField';
import { CustomFormValidationObject } from '../api/metadata-mutation/CustomFormValidation';
import { LogicalEntity } from '../api/metadata-mutation/LogicalEntity';
import { PhysicalEntity } from '../api/metadata-mutation/PhysicalEntity';
import { DataSetRelProperty } from '../api/metadata-mutation/DataSetRelProperty';
import { DataSource } from '../api/metadata-mutation/DataSource';
import { MenuGroup } from '../api/metadata-mutation/MenuGroup';
import { Menu } from '../api/metadata-mutation/Menu';
import { MenuButton } from '../api/metadata-mutation/MenuButton';
import { Button } from '../api/metadata-mutation/Button';
import { DataGridColumn } from '../api/metadata-mutation/DataGridColumn';
import { LogicalEntityOperation } from '../api/metadata-mutation/LogicalEntityOperation';
import { ButtonPanel } from '../api/metadata-mutation/ButtonPanel';
import { Portal } from '../api/metadata-mutation/Portal';
import { PortalDataGrid } from '../api/metadata-mutation/PortalDataGrid';
import { PortalForm } from '../api/metadata-mutation/PortalForm';
import { HelpItem } from '../api/metadata-mutation/HelpItem';
import { EmailTemplate } from '../api/metadata-mutation/EmailTemplate';
import { SubPortal } from '../api/metadata-mutation/SubPortal';
import { TabPortal } from '../api/metadata-mutation/TabPortal';
import { TabGroup } from '../api/metadata-mutation/TabGroup';
import { PortalCard } from '../api/metadata-mutation/PortalCard';
import { CompositeEntityNode } from '../api/metadata-mutation/CompositeEntityNode';
import { CompositeEntity } from '../api/metadata-mutation/CompositeEntity';
import { Widget } from '../api/metadata-mutation/Widget';
import { RootCompositeEntityNode } from '../api/metadata-mutation/RootCompositeEntityNode';
import { ColumnDataPreprocessor } from '../api/metadata-mutation/ColumnDataPreprocessor';
import { ConfigItemByParentIdAndRelationType } from '../api/metadata-query/ConfigItemByParentIdAndRelationType';
import { ActionFlow } from '../api/metadata-mutation/ActionFlow';
import { MicroFlow } from '../api/metadata-mutation/MicroFlow';
import { Row } from '../api/metadata-mutation/Row';
import { Column } from '../api/metadata-mutation/Column';
import { NodeBusinessRule } from '../api/metadata-mutation/NodeBusinessRule';
import { DataParser } from '../api/metadata-mutation/DataParser';
import { InfoAuth } from 'info-auth-client';
import { userDetailsJwtKey } from '../api';
import { push } from 'connected-react-router';
import config from '../config';
import { createStorage } from '../storage';
import { Database } from '../api/metadata-mutation/Database';

let graphqlquery;

const authJwtKey = async () => {
    try {
        // const currentSession = await InfoAuth.currentSession();
        // return currentSession.accessToken.token;
        const storage = createStorage(config);
        const auth = storage.getItem('AUTH_TOKEN');
        return auth;
    } catch (e) {
        await push('/');
        window.location.reload();
    }
}
export const getAllChildFormList = (childNodes, hasNodes, formType, mode) => {
    let childList: object[] = [];
    if (hasNodes) {
        childNodes.sort((a, b) => (a.order > b.order) ? 1 : -1)  // sorting in asc order
        childNodes.map(childNode => {
            if (childNode.addToParentDisplay &&
                ((childNode.addToParentEditForm && mode === 'Edit')
                    || (childNode.addToParentInsertForm && mode === 'Insert'))) {
                let childObject = {
                    form: formType === 'Edit' ?
                        (childNode.editForm ? childNode.editForm : childNode.insertForm) :
                        (childNode.insertForm ? childNode.insertForm : childNode.editForm),
                    nodeName: childNode.displayNodeName,
                    expressionAvailable: childNode.expressionAvailable,
                    accessibilityRegex: childNode.accessibilityRegex,
                    entityId: childNode.entity.configObjectId,
                    entityName: childNode.entity.name,

                }
                childList.push(childObject)
            }
            return childNodes;
        })
    }
    return childList;
}

export const getDispalyColumn = (logicalColumns) => {
    return logicalColumns.find(lc => lc.isDisplayColumn)
}

export const getPrimaryDBCode = (logicalColumns) => {
    return logicalColumns.find(lc => lc.isPrimaryKey).dbCode
}

export const getButtonList = (buttonPanels, mode) => {
    const buttonList: any[] = []
    buttonPanels.map(buttonPanel => {
        const buttonMode = buttonPanel.mode === 'INSERT' ? 'Insert' : 'Edit'
        if (buttonMode === mode) {
            buttonPanel.buttons.map(button => {
                buttonList.push(button)
                return null;
            })
        }
        return null;
    })
    return buttonList
}

export const getForm = (mode, CompositeEntityNode) => {
    if (mode === 'Edit') {
        return CompositeEntityNode.editForm ? CompositeEntityNode.editForm :
            CompositeEntityNode.insertForm;
    } else {
        return CompositeEntityNode.insertForm ?
            CompositeEntityNode.insertForm : CompositeEntityNode.editForm
    }
}


export const getConfigData = async (queryType, id, projectId) => {
    const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';
    let query
    switch (queryType) {
        case 'ConfigItemQuery':
            query = ConfigItem;
            break;
    }
    if (isServerEnv) {
        const authToken = await authJwtKey();
        const jwtToken = userDetailsJwtKey();
        const client = getClient()
        return await client.query({
            query: query,
            variables: { type: id, projectId: projectId },
            context: {
                headers: {
                    authorization: authToken,
                    userDetails: jwtToken,
                    provider: JSON.parse(process.env.AUTH_DETAILS!).provider.type,
                }
            }
        });
    } else {
        query = query.loc.source.body;
        query = query.trim();
        query = query.substring(query.indexOf("{"));
        query = query.substring(0, query.length);
        query = query.replace("$id", '"' + id + '"');
        return await graphqlquery(query);
    }
}
export const createConfigData = async (queryType, params) => {
    const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';
    let mutation
    switch (queryType) {
        case 'FormMutation':
            mutation = Form;
            break;
        case 'DataGridMutation':
            mutation = DataGrid;
            break;
        case 'FormSectionMutation':
            mutation = FormSection;
            break;
        case 'DataSetRelMutation':
            mutation = DataSetRel;
            break;
        case 'DataSetRelPropertyMutation':
            mutation = DataSetRelProperty;
            break;
        case 'LogicalEntityMutation':
            mutation = LogicalEntity;
            break;
        case 'PhysicalEntityMutation':
            mutation = PhysicalEntity;
            break;
        case 'LogicalColumnMutation':
            mutation = LogicalColumn;
            break;
        case 'PhysicalColumnMutation':
            mutation = PhysicalColumn;
            break;
        case 'StandardValidationMutation':
            mutation = StandardValidation;
            break;
        case 'DatabaseValidationMutation':
            mutation = DataBaseValidation;
            break;
        case 'FormDbValidationMutation':
            mutation = FormDbValidation;
            break;
        case 'CustomFormValidationMutation':
            mutation = CustomFormValidationObject;
            break;
        case 'DataSourceMutation':
            mutation = DataSource;
            break;
        case 'MenuGroupMutation':
            mutation = MenuGroup;
            break;
        case 'MenuMutation':
            mutation = Menu;
            break;
        case 'ButtonMutation':
            mutation = Button;
            break;
        case 'ButtonPanelMutation':
            mutation = ButtonPanel;
            break;
        case 'DataGridColumnMutation':
            mutation = DataGridColumn;
            break;
        case 'LogicalEntityOperationMutation':
            mutation = LogicalEntityOperation;
            break;
        case 'MenuButtonMutation':
            mutation = MenuButton;
            break;
        case 'PortalMutation':
            mutation = Portal;
            break;
        case 'SubPortalMutation':
            mutation = SubPortal;
            break;
        case 'TabPortalMutation':
            mutation = TabPortal;
            break;
        case 'TabGroupMutation':
            mutation = TabGroup;
            break;
        case 'PortalCardMutation':
            mutation = PortalCard;
            break;
        case 'PortalDataGridMutation':
            mutation = PortalDataGrid;
            break;
        case 'PortalFormMutation':
            mutation = PortalForm;
            break;
        case 'CompositeEntityNodeMutation':
            mutation = CompositeEntityNode;
            break;
        case 'CompositeEntityMutation':
            mutation = CompositeEntity;
            break;
        case 'RootCompositeEntityNodeMutation':
            mutation = RootCompositeEntityNode;
            break;
        case 'FormFieldMutation':
            mutation = FormField;
            break;
        case 'ColumnDataPreprocessorMutation':
            mutation = ColumnDataPreprocessor;
            break;
        case 'WidgetMutation':
            mutation = Widget;
            break;
        case 'ActionFlowMutation':
            mutation = ActionFlow;
            break;
        case 'MicroFlowMutation':
            mutation = MicroFlow;
            break;
        case 'RowMutation':
            mutation = Row;
            break;
        case 'ColumnMutation':
            mutation = Column;
            break;
        case 'NodeBusinessRuleMutation':
            mutation = NodeBusinessRule;
            break;
        case 'DataParserMutation':
            mutation = DataParser;
            break;
        case 'HelpItemMutation':
            mutation = HelpItem;
            break;
        case 'EmailTemplateMutation':
            mutation = EmailTemplate;
            break;
        case 'DatabaseMutation':
            mutation = Database;
            break;
    }
    if (isServerEnv) {
        const authToken = await authJwtKey();
        const jwtToken = userDetailsJwtKey();
        const client = getClient()
        return await client.mutate({
            mutation: mutation,
            variables: { input: params },
            context: {
                headers: {
                    authorization: authToken,
                    userDetails: jwtToken,
                    provider: JSON.parse(process.env.AUTH_DETAILS!).provider.type,
                }
            }
        });
    }
}
export const getConfigDataByParentIdAndRelationType = async (parentId, relationType, projectId) => {
    const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';
    let query = ConfigItemByParentIdAndRelationType;
    if (isServerEnv) {
        const authToken = await authJwtKey();
        const jwtToken = userDetailsJwtKey();
        const client = getClient();
        return await client.query({
            query: query,
            variables: { parentID: parentId, type: relationType, projectId: projectId },
            context: {
                headers: {
                    authorization: authToken,
                    userDetails: jwtToken,
                    provider: JSON.parse(process.env.AUTH_DETAILS!).provider.type,
                }
            }
        });
    }
}
export const getFormId = (node, mode) => {
    return mode === 'Edit' ?
        (node.editForm ? node.editForm.configObjectId : node.insertForm.configObjectId) :
        (node.insertForm ? node.insertForm.configObjectId : node.editForm.configObjectId)
}