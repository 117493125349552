import gql from 'graphql-tag';

export const DataBaseValidationQuery = gql`
query DatabaseValidation($id:ID!){
  DatabaseValidation(id:$id){
        name
        configObjectId
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        mode
        datasourceName
        datasourceId
        validationType
        validationExpression
        validationQid
        validationMessage
        validationExpressionKeys
        isConditionAvailable
        conditionExpression
        conditionFields
        description
       childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    }
}`;
