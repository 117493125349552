import gql from 'graphql-tag';

export const CompositeEntityNode = gql`
mutation createCompositeEntityNode($input:CompositeEntityNodeInput)
 {
    createCompositeEntityNode(input:$input) {
    configObjectId
    addToParentDisplay
    addToParentGrid
    addToParentEditForm
    addToParentInsertForm
    displayNodeName
    graphTemplate
    accessibilityRegex
    order
    expressionAvailable
    editabilityRegex
    expressionFieldString
    showGrid
    addedHidden
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;