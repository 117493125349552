import React,{useState,useEffect} from 'react';
import BasicDataTable from '../info-data-table/components/BasicDataTable';
import CardData from '../models/carddata.model';
import { CardType } from '../constants/appeng.enum';
import { setFormDatas } from '../actions/index';
import { useDispatch, useSelector } from 'react-redux';
import "../grid-layout.css";
interface IConfigPortalDatagrid{
  className?: string,
  rowHeight?: number,
  cols?: any,
  breakpoints?: any,
  setFormData ?:any,
  formData ?:any
}
const handleChange = (dispatch, formData) => {
  const cardData = new CardData("test555", CardType.FORM, { ...formData }, {});
  dispatch(setFormDatas(cardData, "test555"));
}

const ConfigPortalDatagrid: React.FC<IConfigPortalDatagrid> = (props: IConfigPortalDatagrid) => { 
  const dispatch = useDispatch();
  const cardsData = useSelector((state: any) => state.cardsData);
  const screenDetails = useSelector((state: any) => state.screenDetails);
  const [formData, setFormData] = useState({
    item: {}, property: {}, privilege: [], parentRelation: [], childRelation: [], ...cardsData.data
  }); 
  useEffect(() => {
    if(screenDetails.parentItemId!=="" && screenDetails.configItemId===""){
    setFormData({
      item: {}, property: {}, privilege: [], 
      childRelation: [], ...cardsData.data,parentRelation: [{relationType:screenDetails.relationType,
        parentItemId:screenDetails.parentItemId}]
    })
  }else{
    setFormData({
      item: {}, property: {}, privilege: [], parentRelation:[],
      childRelation: [],...cardsData.data
    })
  }

  }, [cardsData])  
  return (
   
        <div key="datagrid"><BasicDataTable setFormData={(formData)=>handleChange(dispatch,formData)} formData={formData} /></div>
  

  );
}

ConfigPortalDatagrid.defaultProps = {
  className: "layout",
  rowHeight: 25,
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },

};
export default (ConfigPortalDatagrid);
