import { restHttp, restHttpBlob } from './apiUtils';
import * as jwt from 'jsonwebtoken';
import { createStorage } from '../storage';
import config from '../config/index';
import { USER_SESSION_LOCAL_STORAGE_KEY, SECRET_KEY } from '../constants/appeng.enum';
import { cloneDeep } from 'apollo-utilities';
import {
    PublicClientApplication
} from "@azure/msal-browser";

const uuid = require('uuid/v4');
let inboundService;
let dataPreProcessor;
let outBoundService;
let excelReport
let metadataGenerator;
let outBoundQueryService;
if (process.env.REACT_APP_PLATFORM == 'desktop') {
    inboundService = require('appeng-core').inboundService
    dataPreProcessor = require('appeng-core').dataPreProcessor
    outBoundService = require('appeng-outbound').outBoundService
    outBoundQueryService = require('appeng-outbound').queryService
    excelReport = require('appeng-reports').excelReport
    metadataGenerator = require('appeng-reports').metadataGenerator
}


export const userDetailsJwtKey = () => {
    const storage = createStorage(config);
    const userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
    const userProfileMap = JSON.parse(userProfile ? userProfile : "{}");
    const usersession = cloneDeep(userProfileMap);
    delete usersession.AdditionalDetails;
    usersession.APP_CURRENT_DATE = new Date();
    usersession.sub = 'dummy';
    usersession['isConfigConfigurable'] = true;
    Object.assign(usersession, userProfileMap.AdditionalDetails);
    const jwtToken: string = jwt.sign(usersession, SECRET_KEY);
    return jwtToken;
}

const getRestConfig = (authToken: string, requestBody?: any, transactionId?: string, type?: string) => {
    const headers = {
        'Content-Type': 'application/json',
        'authorization': authToken,
        'userDetails': userDetailsJwtKey(),
        'aeTransactionId': transactionId,
        'provider': JSON.parse(process.env.AUTH_DETAILS!).provider.type
    }

    const postFetchConfig = {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: headers
    }

    const putFetchConfig = {
        method: 'PUT',
        body: JSON.stringify(requestBody),
        headers: headers
    }

    const deleteConfig = {
        method: 'DELETE',
        body: JSON.stringify(requestBody),
        headers: headers
    }

    const getFetchConfig = {
        method: 'GET',
        headers: headers
    };

    switch (type) {
        case "PUT":
            return putFetchConfig;
        case "DELETE":
            //console.log('delete is being called')
            return deleteConfig;
        default:
            return requestBody ? postFetchConfig : getFetchConfig;
    }
    //return type && type === "PUT" ? putFetchConfig : requestBody ? postFetchConfig : getFetchConfig;
}


const apiServer = {


    async getGridData(gridId: string, data: any, authToken: string) {
        const config = getRestConfig(authToken, data);
        const response = await restHttp(process.env.APPENG_OUTBOUND_URL + "gridData/" + gridId, config);
        return response;
    },
    async getEntityData(entityId: string, primaryKey: string, authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_OUTBOUND_URL + entityId + "/" + primaryKey, config);
    },
    async deployMeta(authToken: string) {
        const config = getRestConfig(authToken);
        const response = await restHttp(process.env.APPENG_META_RELOAD_URL + "reload", config);
        return response;
    },
    async getProjectOption(userId: string, authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/users/" + userId + "/applications"
            , config);
    },

    async getDefaultProjectAndRoleFromUserId(userId: string, projectId: string, authToken: string) {
        const config = getRestConfig(authToken);
        if (projectId) {
            return await restHttp(process.env.APPENG_AUTH_URL + "/defaultAppRole/" + userId + "/" + projectId
                , config);
        }
        return await restHttp(process.env.APPENG_AUTH_URL + "/defaultAppRole/" + userId
            , config);
    },

    async getProjects(authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/applications"
            , config);
    },

    async getOktaUserInfo(authToken: string) {
        const configuration = getRestConfig(authToken);
        return await restHttp(JSON.parse(process.env.AUTH_DETAILS!).provider.okta.userInfoUri!, configuration);
    },

    async getAllRoleOption(authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/roles"
            , config);
    },

    async getRoleOption(userId: string, authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/users/" + userId + "/roles"
            , config);
    },

    async getRoleOptionViaApplicationId(appId: string, authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/application/" + appId + "/roles"
            , config);
    },

    async applicationRoleMicrosoftAd() {
        const microsoftADConfig = JSON.parse(process.env.AUTH_DETAILS!).provider["microsoft-ad"];
        const pca = new PublicClientApplication(microsoftADConfig);
        const accounts = pca.getAllAccounts()
        const account = accounts[0];
        let scopes = ["openid", "profile", "User.Read"];
        const resp = await pca.acquireTokenSilent({
            scopes: scopes,
            account,
            forceRefresh: false,
        });
        const configuration = getRestConfig('Bearer ' + resp.accessToken);
        const response = await restHttp("https://graph.microsoft.com/v1.0/me/appRoleAssignments", configuration);
        return response;
    },
}

const apiDesktop = {
    async getGridData(gridId: string, data: any, authToken: string) {
        const response = await outBoundService.fetchGridDataByGridId(gridId, data, authToken,
            userDetailsJwtKey(), JSON.parse(process.env.AUTH_DETAILS!).provider.type)
        return formatResponse(response);
    },
    async getEntityData(entityId: string, primaryKey: string, authToken: string) {
        return await outBoundService.singleSelect(entityId, primaryKey, authToken, userDetailsJwtKey(),
            'infoauth');
    },
    async deployMeta(authToken: string) {
        const config = getRestConfig(authToken);
        const response = await restHttp(process.env.APPENG_META_RELOAD_URL + "reload", config);
        return response;
    },
    async getProjectOption(userId: string, authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/users/" + userId + "/applications"
            , config);
    },

    async getDefaultProjectAndRoleFromUserId(userId: string, projectId: string, authToken: string) {
        const config = getRestConfig(authToken);
        if (projectId) {
            return await restHttp(process.env.APPENG_AUTH_URL + "/defaultAppRole/" + userId + "/" + projectId
                , config);
        }
        return await restHttp(process.env.APPENG_AUTH_URL + "/defaultAppRole/" + userId
            , config);
    },

    async getProjects(authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/applications"
            , config);
    },

    async getOktaUserInfo(authToken: string) {
        const configuration = getRestConfig(authToken);
        return await restHttp(JSON.parse(process.env.AUTH_DETAILS!).provider.okta.userInfoUri, configuration);
    },

    async getAllRoleOption(authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/roles"
            , config);
    },

    async getRoleOption(userId: string, authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/users/" + userId + "/roles"
            , config);
    },

    async getRoleOptionViaApplicationId(appId: string, authToken: string) {
        const config = getRestConfig(authToken);
        return await restHttp(process.env.APPENG_AUTH_URL + "/application/" + appId + "/roles"
            , config);
    },

    async applicationRoleMicrosoftAd() {
        const microsoftADConfig = JSON.parse(process.env.AUTH_DETAILS!).provider["microsoft-ad"];
        const pca = new PublicClientApplication(microsoftADConfig);
        const accounts = pca.getAllAccounts()
        const account = accounts[0];
        let scopes = ["openid", "profile", "User.Read"];
        const resp = await pca.acquireTokenSilent({
            scopes: scopes,
            account,
            forceRefresh: false,
        });
        const configuration = getRestConfig('Bearer ' + resp.accessToken);
        const response = await restHttp("https://graph.microsoft.com/v1.0/me/appRoleAssignments", configuration);
        return response;
    },
}

const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';
const api = isServerEnv ? apiServer : apiDesktop;

const formatResponse = (response: any) => {
    if (response.data && response.data.columns && response.data.rows) {
        const columns: string[] = response.data.columns;
        const records: any[] = [];

        response.data.rows.map((row: any) => {
            const record: any = {};
            for (const [i, value] of row.entries()) {
                record[columns[i]] = value;
            }
            records.push(record);
            return null;
        });
        return records;
    }
    return null;
};
export default api;