import gql from 'graphql-tag';

export const DataSetRelProperty = gql`
mutation createDataSetRelProperty($input:DataSetRelPropertyInput)
 {
    createDataSetRelProperty(input:$input) {
     configObjectId
    propertyType
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;