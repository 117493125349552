export enum StorageEnum {
    COOKIE = 'cookie',
    MEMORY = 'memory',
    WINDOW = 'window',
    SESSION = 'session'
}

export const parseStorageEnum = (storage: string) => {
    switch (storage) {
        case StorageEnum.COOKIE:
            return StorageEnum.COOKIE;
        case StorageEnum.MEMORY:
            return StorageEnum.MEMORY;
        case StorageEnum.WINDOW:
            return StorageEnum.WINDOW;
        case StorageEnum.SESSION:
            return StorageEnum.SESSION;
        default:
            throw new Error('Invalid or Unsupported Storage type ' + storage);
    }
};

export enum LeftMenuIndicator {
    LEFTMENU = "LeftMenu",
    SIDENODETREE = "SideNodeTree"
}

export enum GridType {
    PARENT = "Parent",
    CHILD = "Child"
}

export enum NodeType {
    PARENTNODE = "ParentNode",
    CHILDNODE = "ChildNode",
    DATANODE = "DataNode"
}

export enum CardType {
    DATAGRID = "DataGrid",
    FORM = 'Form',
    WIDGET = 'Widget',
    FILTERFORM = 'FilterForm',
    PORTAL = 'Portal',
    SIDENODETREE = 'SideNodeTree'
}

export enum ChartType {
    BARCHART = 'BarChart',
    COUNTERCHART = 'CounterChart',
    AREACHART = 'AreaChart',
    LINECHART = 'LineChart',
    PIECHART = 'PieChart',
    DOUGHNUTCHART = 'DoughnutChart',
    STATIC = 'Static',
}

export enum QueryType {
    REPORT_QUERY = 'ReportFormQuery',
    MENUGROUP_QUERY = 'MenuQuery',
    COMPOSITE_ENTITY_QUERY = 'CEQuery',
    COMPOSITE_ENTITY_NODE_QUERY = 'NodeQuery',
    CHILD_NODE_IN_TREE_QUERY = 'ChildNodeInTreeQuery',
    ROOT_NODE_QUERY = 'RootNodeQuery',
}

export const PATHNAME = 'pathName';
export const LEFT_ARROW_ICON: string = 'fa fa-arrow-left';
export const RIGHT_ARROW_ICON: string = 'fa fa-arrow-right';
export const SWITCH_ICON: string = 'fa fa-bars';
export const EDIT: string = "EDIT";
export const VIEW: string = "VIEW";
export const NOPRIVILEGE: string = "NOPRIVILEGE";
export const PrivilegeEnum = {
    EDIT: 2,
    VIEW: 1,
    NOPRIVILEGE: 0
};

export enum UpsertMessage {
    SAVESUCCESSFUL = "Saved Successfully",
    UPDATESUCCESSFUL = "Updated Successfully",
    UNSUCCESSFUL = "Something Went Wrong",
    FINANCIAL_YEAR_SWITCH = "Financial Year Switched Successfully",
    LANGUAGE_SWITCH = "Language Switched Successfully",
    DELETESUCCESSFUL = 'Deleted Successfully',
    DEPLOYSUCCESSFUL = 'Deployed Successfully',
    DEPLOYUNSUCCESSFUL = 'Deploy is not done properly Please make sure everything is correct',
    INVALID_RELEASENAME='Release name is not present,Please reload the Application'
}

export const RECORD_INDEX: string = "946ea30c-caf6-11e9-8548-0ee72c6ddce6";
export const FINANCIAL_YEAR_OPTIONS_KEY: string = "financial_year";
export const USER_SESSION_LOCAL_STORAGE_KEY: string = "userDetails";
export const SECRET_KEY: string = "defaultsecret";
export const LANGUAGE_OPTIONS_KEY: string = "languages";
export const APP_LOGGED_IN_ROLE_ID: string = "APP_LOGGED_IN_ROLE_ID";
export const APP_LOGGED_IN_YEAR: string = "APP_LOGGED_IN_YEAR";
export const ROUTE_STATE_LOCAL_STORAGE_KEY: string = "routeState";
export const SELECTED_THEME_LOCAL_STORAGE_KEY: string = "selectedTheme";

export const MODE = 'Edit';
export const NODE_TYPE = 'ParentNode';
export const USER_CEID = 'fb0f5138-803e-496a-8995-c440a886f431';
export const USER_NODEID = 'ce677d27-24b1-4040-aa29-93f158f9f609';
export const USER_PARENT_FORMID = 'd895dd58-aeb3-48ab-b5de-b5fd3bac9f5d';
export const USER_PRIMARY_DBCODE = 'AE_USER_PROFILE_UUID';
export const USER_FORM_TYPE = 'Edit';
export const USER_MODAL_REQUIRED = false;

export const INSERT_MODE = 'Insert';
export const EDIT_MODE = 'Edit';
export const BUTTON_PANNEL_TOP_POSITION = 'TOP';
export const BUTTON_PANNEL_BOTTOM_POSITION = 'BOTTOM';
export const ADD_FORM = 'AddForm';
export const UPDATE_BUTTON_CLASS = 'updateFormDataButton';
export const DELETE_BUTTON_CLASS = 'deleteFormCompositeEntity';
export const SAVE_BUTTON_CLASS = 'btn singleEntityModalSave';
export const CANCEL_BUTTON_CLASS = 'cancelCompositeEntity';
export const CUSTOM_BUTTON_CLASS = 'formActionDataButton';
export const GRID_ADD_BUTTON_CLASS = 'single-entity-insert';
export const DOWNLOAD_REPORT_BUTTON_CLASS = 'download-report';