export default {
    "Widget": {
        "child": [
            {
                "Row":{
                    "child": [
                        {
                            "Column":{
                                "child": [
                                    {
                                        "FormField":{
                                            "child": [
                                                {
                                                    "ActionFlow":{
                                                        "child": [
                                                            {
                                                                "MicroFlow":null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "Button":{
                                            "child": [
                                                {
                                                    "ActionFlow":{
                                                        "child": [
                                                            {
                                                                "MicroFlow":null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "MenuButton": {
                                            "child": [
                                                {
                                                    "Button": {
                                                        "child": [
                                                            {
                                                                "ActionFlow": {
                                                                    "child": [
                                                                        {
                                                                            "MicroFlow": null
                                                                        }
                                                                    ]
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    }
}