import gql from 'graphql-tag';

export const ColumnDataPreprocessor= gql`
  query ColumnDataPreprocessor($id: ID!)
{
  ColumnDataPreprocessor(id:$id) {
    configObjectId
    name
    configObjectType
    preProcessorBean
    isMultiValue
    excecutionType
    jsCode
    order
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
