import gql from 'graphql-tag';

export const MenuButtonQuery= gql`
 query MenuButton($id:ID!)
 {
    MenuButton(id:$id){
    defaultType
    menuLabel
    position
    buttonClass
    warningMessage
    order
    accessibilityRegex
    editabilityRegex
    expressionAvailable
    transactionName
    modalRequired
    portalId
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
      childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
   
} 
 }`;