import React from 'react';
import ConfigPortalForm from '../../../container/ConfigPortalForm';
const dataModifier = (e, row, editFormId, primaryDBCode, dispatch, removeCardDataByReferralId) => {
    if (row.isExpanded) {
        //dispatch(removeCardDataByReferralId(row.original[primaryDBCode] + '_' + editFormId))
    }
    row.toggleRowExpanded();
}

const ExpandableSubComponents = (props) => {
    const row = props.row;
    const newProps = {
        formType: "Edit",
        ceId: props.ceId,
        nodeId: props.nodeId,
        referralId: props.editFormId,
        primaryDBCode: props.pkDBCode,
        insertFormId: props.insertFormId,
        editFormId: props.editFormId,
        data: row.original
    }
    return <ConfigPortalForm/>
}

export default ExpandableSubComponents;