import gql from 'graphql-tag';

export const ConfigItem = gql`
  query ConfigItemByType($type: String!,$projectId :Int!)
  {
    ConfigItemByType(type:$type,projectId:$projectId) {
        configObjectId
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
  }
  }`;
