import React, { useState, useContext } from 'react';
import {
    TextBox, RadioButton, SelectOption
} from 'info-ui-library';
import styled from 'styled-components';
import { themes } from '../theme/ThemeOptions';
import { useSelector, useDispatch } from 'react-redux';
import { getPropertyByPortalCardType } from "../utils/PropertyUtils";
import FormSelectOption from './FormSelectOption';
import GridSelectOption from './GridSelectOption';
import portalCardTypeOptions from "./mockData/portalCardTypeOptions.json";
import { setChangeFormData } from '../actions/index';
import { ThemeContext } from '../theme/ThemeContext';
const InnerContainer = styled.div`{
    flex: 1 0 21%;
    padding-right:5px;
}`;
const OuterConatiner = styled.div`
display: flex;
flex-wrap: wrap;
`;

const handlePropertyChange = (updatedValue, dbCode, type, dispatch) => {
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, type));
}

const onBlur = (updatedValue, dbCode, type, dispatch) => {
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, type));
}

const handleSelectType = (updatedValue, setType, dbCode, formData, dispatch) => {
    //console.log(updatedValue);
    let dataMap = formData;
    dataMap.property[dbCode] = updatedValue.value;
    setType(updatedValue.value);
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, "property"));

}

const getComponents = (properties, formData, errorData, dispatch, themeContext) => {
    try {
        return properties.map(property => {
            switch (property.type) {
                case "TextBox":
                    return <InnerContainer><TextBox
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        value={Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ? formData.property[property.dbCode] : property.defaultValue}
                        displayType="FloatingLabel"
                        handleChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}

                        onBlur={(updatedValue) => {
                            onBlur(updatedValue, property.dbCode, "property", dispatch)
                        }}


                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                        mandatory={property.isMandatory}
                        isButtonTextBox={false}
                    /></InnerContainer>
                case "SelectOption":
                    const valueAsAnObject = getValueObject(Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue, property.options)
                    return <InnerContainer><SelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        value={valueAsAnObject}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        onChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                    /></InnerContainer>
                case "Form":
                    const formvalues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><FormSelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={formvalues}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={handlePropertyChange}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
                case "Grid":
                    const gridValues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><GridSelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={gridValues}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={handlePropertyChange}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
            }
        })
    } catch (e) {
        return <div>{e}</div>
    }

}
const PortalCardComponent = props => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [type,
        setType] = useState(Object.keys(props.formData.property).length > 0 ?
            props.formData.property["type"] : "");
    let components = "";
    if (type !== "") {
        const property = getPropertyByPortalCardType(type);
        components = getComponents(property, props.formData, props.errorData, dispatch, themeContext);
    }
    const valueAsAnObject = getValueObject(Object.keys(props.formData.property).length > 0 && props.formData.property["type"] ?
        props.formData.property["type"] : "", portalCardTypeOptions)
    try {
        return (
            <OuterConatiner className="col-md-12" >
                <InnerContainer><SelectOption
                    ioTheme={themes[0]}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    value={valueAsAnObject}
                    label="Type"
                    configId={"Type"}
                    key="Type"
                    options={portalCardTypeOptions}
                    displayType="FloatingLabel"
                    onChange={(value) => handleSelectType(value, setType,
                        "type", props.formData, dispatch)}
                    isSearchable={true}
                    isDisabled={false}
                    isMulti={false}
                    mandatory={true}
                    errorMessage={props.errorData["type"]}
                    hasError={props.errorData["type"] ? true : false}
                /></InnerContainer>
                {components}
            </OuterConatiner>

        )
    } catch (e) {
        return <div></div>
    }
}

export const getValueObject = (initialvalue, selectedOptions) => {
    let value = null
    if (selectedOptions) {
        selectedOptions.map(object => {
            if (String(object.id) === String(initialvalue)) {
                value = object;
            }
            return null;
        })
    }
    return value
}
export default PortalCardComponent;
