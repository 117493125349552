import React, {useState, useEffect,useContext} from 'react';
import {Responsive, WidthProvider} from 'react-grid-layout';
import ChildRelationDataTable from '../info-data-table/components/ChildRelationDataTable';
import "../grid-layout.css";
import {useDispatch} from 'react-redux';
import {Button} from 'info-ui-library';
import {themes} from '../theme/ThemeOptions';
import ChildRelationModal from './ChildRelationModal';
import {ThemeContext} from '../theme/ThemeContext';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
interface IConfigPortalDatagrid {
    className?: string,
    rowHeight?: number,
    cols?: any,
    breakpoints?: any,
    setFormData:any,
    formData:any
}

const ChildRelation : React.FC < IConfigPortalDatagrid > = (props : IConfigPortalDatagrid) => {
    const dispatch = useDispatch();
    const themeContext = useContext(ThemeContext);
    const [IsModalVisible,
        SetModalVisible] = useState(false)

    let layouts = [
        {
            i: "menu",
            x: 0,
            y: 0,
            w: 12,
            h: 2
        }, {
            i: "datagrid",
            x: 0,
            y: 2,
            w: 12,
            h: 15
        }
    ]

    return (
        <div>
            <ResponsiveReactGridLayout
                className="layout"
                layouts={{
                "lg": layouts
            }}
                rowHeight={props.rowHeight}
                cols={props.cols}
                isDraggable={false}
                isResizable={false}
                margin={[5, 5]}>
                <div key="datagrid">
                    <Button
                        styleName={themeContext.styleName}
                        ioTheme={themeContext.ioTheme}
                        ioMode={themeContext.ioMode}
                        name={''}
                        onClick={() => {SetModalVisible(true)}}
                        className='fa fa-plus'/>
                    <ChildRelationDataTable setFormData={props.setFormData} formData={props.formData}/>
                    </div>
            </ResponsiveReactGridLayout>
            {IsModalVisible && <ChildRelationModal setFormData={props.setFormData} formData={props.formData} SetModalVisible={SetModalVisible}/>}
        </div>
    );
}

ChildRelation.defaultProps = {
    className: "layout",
    rowHeight: 25,
    cols: {
        lg: 12,
        md: 10,
        sm: 6,
        xs: 4,
        xxs: 2
    },
    breakpoints: {
        lg: 1200,
        md: 996,
        sm: 768,
        xs: 480,
        xxs: 0
    }
};
export default(ChildRelation);
