import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { PrivilegeEnum, NOPRIVILEGE } from '../constants/appeng.enum';

const cache = new InMemoryCache();

export const getClient = () => {
    return new ApolloClient({
        uri: process.env.APPENG_META_URL
    });
}

export function containsKey(object, key) {
    const value = Object.keys(object).find(k => k.toLowerCase() === key.toLowerCase())
    return value ? value : "";
}

export const isExpressionResolved = (expression, input) => {
    expression = manipulateExpression(expression, input);
    try {// eslint-disable-next-line
        return eval(expression);
    } catch (e) {
        return false;
    }
}

const manipulateExpression = (expression, input) => {
    // eslint-disable-next-line 
    const regexAnd = new RegExp('\\b' + "and" + '\\b', "ig");
    // eslint-disable-next-line
    const regexOr = new RegExp('\\b' + "or" + '\\b', "ig");
    if (expression.startsWith('JAVASCRIPT$$')) {
        expression = expression.substring(expression.indexOf('$') + 2);
        expression = expression.replace(regexAnd, '&&').replace(regexOr, '||');
        return expression;
    } else {
        expression = expression.replace(/#/, '').replace(/{/, '').replace(/}/, '')
            .replace(regexAnd, '&&').replace(regexOr, '||');

        Object.entries(input).forEach(
            ([key, value]) => {
                if (key !== "" && key !== undefined) {
                    const reg = new RegExp('\\b' + key + '\\b', "g");
                    const value = "input." + key;
                    expression = expression.replace(reg, value);
                }
            })
        return expression;
    }

}

export const getPrivilege = (privileges: any[], currentRole: any[]) => {
    if (!privileges) {
        privileges = ['NOPRIVILEGE'];
    }
    const hasPrivileges: any[] = [];

    if (!privileges) {
        return NOPRIVILEGE;
    }
    currentRole.forEach(function (element) {
        const selectedPrivilege = privileges.filter(privilege =>
            String(privilege["roleId"]) === String(element))
            .map(privilege => { return privilege.privilegeType })

        hasPrivileges.push(selectedPrivilege[0]);
    });
    const broaderPrivilege = getBroaderPrivilege(hasPrivileges);
    return broaderPrivilege;
}

const getBroaderPrivilege = (hasPrivileges) => {
    let smallerPriority = 0;
    let broaderPrivilege = NOPRIVILEGE;
    hasPrivileges.forEach(function (privilege) {
        if (privilege !== null && PrivilegeEnum[privilege] > smallerPriority) {
            smallerPriority = PrivilegeEnum[privilege];
            broaderPrivilege = privilege;
        } else if (privilege === null || privilege === undefined) {
            broaderPrivilege = NOPRIVILEGE;
        }
    });
    return broaderPrivilege;
}
// eslint-disable-next-line
export const executeJavascriptPreprocessor = (rule, input) => {
    // eslint-disable-next-line
    return eval('(function() {' + rule + '}())');
}

export const sortConfigObjects = (configs: any) => {
    configs.sort(function (a, b) {
        return a.order - b.order;
    })
}
