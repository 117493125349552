import React, { useContext } from 'react';
import DataGrid from "./dataGridComponents/Info_Data_Grid";
import { FormErrorContainers, Button } from 'info-ui-library';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThemeContext } from '../../theme/ThemeContext';
import ActionButton from "../components/editableGridComponents/ActionButton";
import ConfigItemMockData from '../mockdata/ConfigItemMockData.json';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { themes } from '../../theme/ThemeOptions';
import { StyledConfirModalDiv } from './FormStyle';
import { getConfigDataById } from '../../actions/index';
import { useDispatch } from 'react-redux';

const formatData = (response: any) => {
    if (response.data && response.data.columns && response.data.rows) {
        const columns: string[] = response.data.columns;
        const records: any[] = [];

        response
            .data
            .rows
            .map((row: any) => {
                const record: any = {};
                for (const [i,
                    value] of row.entries()) {
                    record[columns[i]] = value;
                }
                records.push(record);
                return null;
            });
        return records;
    }
    return null;
};
const cardDatas = {
    "cardsData": {
        "ad660b2c-9f57-4bac-9449-ed1e7b9f1a82": {
            "id": "ad660b2c-9f57-4bac-9449-ed1e7b9f1a82",
            "type": "DataGrid",
            "data": formatData(ConfigItemMockData),
            "referenceData": {},
            "errorData": {},
            "options": {},
            "datagridErrorData": {}
        }
    }
}
interface IUIBasicTableGenerator extends RouteComponentProps {
    datagrid: any;
    entityName: string;
    entityId: string;
    nodeId: string;
    gridType: string;
    gridData: any;
    primaryDBCode: string;
    insertFormId: string;
    editFormId: string;
    intl: any;
}
const handleClick = (column, rowDetails, props: any, dispatch) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <StyledConfirModalDiv ioTheme={themes[0]}
                    ioMode="light"
                    styleName="primary" className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>You want to delete this file?</p>
                    <Button ioTheme={themes[0]}
                        ioMode="light"
                        styleName="primary" onClick={onClose}
                        disabled={false}
                        name={'No'} />
                    <Button ioTheme={themes[0]}
                        ioMode="light"
                        styleName="primary"
                        disabled={false}
                        name={'Yes'}
                        onClick={() => {
                            let formData = { ...props.formData };
                            props.formData.parentRelation.map(relation => {
                                if (rowDetails.original.parentItemId === relation.parentItemId) {
                                    formData.parentRelation.splice(rowDetails.index, 1);
                                }
                            })
                            props.setFormData({ ...formData });

                            onClose();
                        }}
                    />
                </StyledConfirModalDiv>
            );
        }
    });
}

const ParentRelationDataTable: React.FC<any> = (props: any) => {
    const screenDetails = useSelector((state: any) => state.screenDetails);
    const dispatch = useDispatch();
    const cardsData = useSelector((state: any) => state.cardsData);
    const themeContext = useContext(ThemeContext);

    const handleRowClick = (rowDetails) => {
        const rowDetail = rowDetails.original;
        let rowdetails_RelationType = rowDetail.relationType.split('_');
        let rowdetails_RelationTypeIndexValue = rowdetails_RelationType[rowdetails_RelationType.length - 2];
        let parentItemValue = rowdetails_RelationTypeIndexValue.split('(');
        let parentItemType = parentItemValue[parentItemValue.length - 1];
        //console.log('*******************',rowDetail.relationType.split('_')[0])
        let screenDtls: any = {
            ...screenDetails,
            configType: parentItemType,
            screenType: screenDetails.screenType = "ParentForm",
            configItemId: rowDetail.parentItemId
        }
        dispatch(getConfigDataById(screenDtls.configItemId, screenDtls));
    }

    const columns = React.useMemo(() => [
        {
            filterEnable: "true",
            width: 230,
            Header: 'Parent Item Id',
            accessor: 'parentItemId',
            isAccessible: true
        }, {
            filterEnable: "true",
            width: 230,
            Header: 'Relation Type',
            accessor: 'relationType',
            isAccessible: true
        }, {
            width: 140,
            Header: 'Action',
            accessor: 'actionColumn',
            isAction: true,
            filterEnable: false,
            isAccessible: true,
            Cell: ActionButton,
            styleName: themeContext.styleName,
            ioTheme: themeContext.ioTheme,
            ioMode: themeContext.ioMode,
            buttons: [
                {
                    id: "1",
                    HeaderName: "",
                    icon: "fa fa-trash-o",
                    isAccessible: true,
                    onClickHandler: handleClick,
                    parentProps: props,
                    disabled: true
                }
            ]
        }

    ], [])

    let gridData: any = cardsData.data ? cardsData.data.parentRelation ? cardsData.data.parentRelation : [] : [];

    try {
        return (<DataGrid
            styleName={themeContext.styleName}
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            borderEnable={true}
            strippedEnable={true}
            hoverEnable={false}
            columns={columns}
            data={[...gridData]}
            scrollEnable={false}
            serverPaginationEnable={false}
            subComponentEnable={true}
            subComponentType="Form"
            primaryDBCode="parentItemId"
            pageIndex={0}
            cardsData={cardDatas.cardsData['ad660b2c-9f57-4bac-9449-ed1e7b9f1a82']}
            handleRowClick={handleRowClick}
        />)

    } catch {
        const error = ['Error occurred in Dynamic UI Table']
        return <FormErrorContainers
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }

};

function isPropsAreEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}
export default (React.memo((ParentRelationDataTable), isPropsAreEqual));