import React, {useState, useEffect} from 'react';
import {SelectOption, Form} from 'info-ui-library';
import {themes} from '../theme/ThemeOptions';
import {getSelectOptionDataFormat} from '../utils/SelectOptionUtils';
import {getConfigData} from '../utils/ConfigUtils';
import {getConfigDataByParentIdAndRelationType} from "../utils/ConfigUtils";
import { useDispatch, useSelector } from 'react-redux';
const handleSelectOptionComp = (data, setSelectedCompData,setFormData,formData) => {
    setSelectedCompData(data);
    let childRelations:any=formData;
    childRelations.childRelation= {...formData.childRelation,...{childItemId:data.value}};
    setFormData({ ...childRelations });
}

const FormFieldRelationForm = (props : any) => {
    const [forms,
        setForms] = useState([
        {
            label: "-select-",
            value: "-select-"
        }
    ]);
    const [selectedForm,
        setSelectedForm] = useState({
            label: "-select-",
            value: "-select-"
        });
        const [formSections,
            setFormSections] = useState([
            {
                label: "-select-",
                value: "-select-"
            }
        ]);
        const [selectedFormSection,
            setSelectedFormSection] = useState({
                label: "-select-",
                value: "-select-"
            });
    const projectId = useSelector((state: any) => state.projectId);
    useEffect(() => {
        const getForms = async() => {
            let gridData = await getConfigData('ConfigItemQuery', "Form",projectId);
            if (props.type) {
                setForms(getSelectOptionDataFormat(gridData.data.ConfigItemByType))
            }
        }
        const getFormSectionByFormId = async(selectedForm) => {
            let logicalColumnData:any = await getConfigDataByParentIdAndRelationType(selectedForm,"Form_FormSection",projectId );
            if (selectedForm.value!=="-select-") {
                setFormSections(getSelectOptionDataFormat(logicalColumnData.data.ConfigItemByTypeAndParentID))
            }
        }
        const getFormFieldByFormSectionId = async(selectedFormSection) => {
            let logicalColumnData:any = await getConfigDataByParentIdAndRelationType(selectedFormSection,"FormSection_FormField" ,projectId);
            if (selectedFormSection.value!=="-select-") {
                props.setCompData(getSelectOptionDataFormat(logicalColumnData.data.ConfigItemByTypeAndParentID))
            }
        }
        getForms();
        getFormSectionByFormId(selectedForm);
        getFormFieldByFormSectionId(selectedFormSection);
    }, [props.type,selectedForm,selectedFormSection])

    const FormComponent = <CommonComponent type={props.type}
    setSelectedForm={setSelectedForm}
        setCompData={props.setCompData}
        compData={props.compData}
        setFormData={props.setFormData} 
        formData={props.formData}
        setSelectedCompData={props.setSelectedCompData}
        selectedCompData={props.selectedCompData}
        selectedForm={selectedForm}
        forms={forms}
        formSections={formSections}
        setSelectedFormSection={setSelectedFormSection}/>
    try {
        return (
            <Form className={props.className}>
                {FormComponent}
            </Form>
        )
    } catch (e) {
        return <div></div>
    }
}
const handleSelectForm = (value,type, setSelectedForm,
     setSelectedCompData) => {
    setSelectedCompData("-select-")
    setSelectedForm(value);
}

const handleSelectFormSection = (value,type, setSelectedFormSection,
    setSelectedCompData,
   setFormData,formData,configType) => {
   setSelectedCompData("-select-")
   setSelectedFormSection(value);
   let childRelations:any=formData;
   childRelations.childRelation= {...formData.childRelation,...{relationType:configType+"_"+type}};
   setFormData({ ...childRelations });
}

const CommonComponent = (props) => {
    const screenDetails = useSelector((state: any) => state.screenDetails); 
    return <div>
            <SelectOption
                styleName='primary'
                ioTheme={themes[0]}
                ioMode='light'
                options={props.forms}
                label='Form'
                configId={'Form'}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectForm(value.value,props.type,
                     props.setSelectedForm, 
                     props.setSelectedCompData)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError=''/>
<SelectOption
                styleName='primary'
                ioTheme={themes[0]}
                ioMode='light'
                options={props.formSections}
                label='FormSection'
                configId={'FormSection'}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectFormSection(value.value,props.type,
                     props.setSelectedFormSection, 
                     props.setSelectedCompData,props.setFormData
                     ,props.formData,screenDetails.configType)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError=''/>
            <SelectOption
                styleName='primary'
                ioTheme={themes[0]}
                value={props.selectedCompData}
                ioMode='light'
                options={props.compData}
                label='FormField'
                configId={'FormField'}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectOptionComp(value,
                     props.setSelectedCompData,props.setFormData
                     ,props.formData)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError=''/>
        </div>
}

export default(FormFieldRelationForm);
