import gql from 'graphql-tag';

export const MenuQuery = gql`
query Menu ($id:ID!)
{
    Menu (id:$id){
    displayLabel
    hrefBaseUrl
    icon
    order
    objectId
    isOpenInNewTab
    componentsPerRow
    linkType
    accessibilityRegex
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
       childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    
    }
}`;