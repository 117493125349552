import gql from 'graphql-tag';

export const PortalDataGrid = gql`
mutation createPortalDataGrid($input:PortalDataGridInput)
 {
    createPortalDataGrid(input:$input) {
 configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    nodeId
    ceId
    logicalEntityId
    dataGridId
        privileges{
            privilegeType
            roleId
            isDeleted
        }
       
    }
}
`;