export default {
    "TabGroup": {
        "child": [
            {
                "TabPortal": {
                    "child": [
                        {
                            "SubPortal": {
                                "child": [
                                    {
                                        "PortalCard": {
                                            "child": [
                                                {
                                                    "ButtonPanel": {
                                                        "child": [
                                                            {
                                                                "Button": {
                                                                    "child": [
                                                                        {
                                                                            "ActionFlow":{
                                                                                "child": [
                                                                                    {
                                                                                        "MicroFlow":null
                                                                                    }
                                                                                ]
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "ButtonPanel": {
                                "child": [
                                    {
                                        "Button": {
                                            "child": [
                                                {
                                                    "ActionFlow":{
                                                        "child": [
                                                            {
                                                                "MicroFlow":null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    }
}