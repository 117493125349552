import React, { Suspense, useState } from 'react';
import InfoTable from './Info_Table';
import ListInfoTableScroll from './Info_Table_Scroll';

import 'font-awesome/css/font-awesome.min.css';

function loadingData() {
    return React.createElement("div", { className: "animated fadeIn pt-1 text-center" }, "Loading...");
}

function InfoDataGrid(props) {
    const serverData = props.serverPaginationEnable && !props.scrollEnable ? [] : props.data;
    const [data, setData] = useState(serverData);
    const [originalData, setOriginalData] = useState(data);
    React.useEffect(() => {
        setData(serverData);
        setOriginalData(serverData);
    }, [serverData]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = React.useRef(0);
    const [selectedPath, setSelectedPath] = useState(null);

    const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
        const fetchID = ++fetchIdRef.current
        setLoading(true)
        setTimeout(() => {
            if (fetchID === fetchIdRef.current) {
                const startRow = pageSize * pageIndex
                const endRow = startRow + pageSize
                setData(serverData.slice(startRow, endRow))
                setPageCount(Math.ceil(serverData.length / pageSize))
                setLoading(false);
            }
        }, 1000)
    }, []);

    //updating row data
    const updateMyData = async (rowIndex, columnID, value, path) => {
        let keyMap = {};
        keyMap["ceId"] = props.ceId;
        keyMap["logicalEntityId"] = props.logicalEntityId;
        keyMap["nodeId"] = props.nodeId;
        if (path && path.length === 1) {
            let primaryKey = "";
            setData(old =>
                old.map((row, index) => {
                    if (index === rowIndex) {
                        primaryKey = row[props.primaryDBCode];
                        return {
                            ...old[rowIndex],
                            [columnID]: value,
                        }
                    }
                    return row
                })
            )
            let data = {};
            let valueMap = {};
            valueMap[columnID] = value;
            data["valueMap"] = valueMap;
            data["rowIndex"] = rowIndex;
            data["path"] = path.toString();
            data["valueChangedFor"] = columnID;
            keyMap["primaryKey"] = primaryKey;
            await props.updateDataInline(data, keyMap, props.dispatch, props.referralId);
        } else {
            let pathCopy = [...path];
            pathCopy.shift()
            let returnData = datamodifier(columnID, value, pathCopy, data[path[0]]);
            let newdata = [...data];
            newdata[path[0]] = returnData;
            setData(newdata);
        }
        setSelectedPath(path.toString());
    }

    React.useEffect(() => {
        if (selectedPath) {
            setTimeout(() => {
                setSelectedPath(null);
            }, 3000);
        }
    }, [selectedPath]);

    const datamodifier = (columnID, value, path, parentData) => {
        if (path.length === 1) {
            parentData.subRows[path[0]][columnID] = value;
        } else {
            let pathCopy = path;
            pathCopy.shift()
            datamodifier(columnID, value, pathCopy, parentData.subRows[path[0]]);
        }
        return parentData;
    }
    return (
        <Suspense fallback={loadingData()}>
            {
                
                props.scrollEnable ?
                    <ListInfoTableScroll ceId={props.ceId} nodeId={props.nodeId}
                        pkDBCode={props.primaryDBCode}
                        insertFormId={props.insertFormId}
                        editFormId={props.editFormId} columns={props.columns}
                        data={data}
                        styleName={props.styleName}
                        ioTheme={props.ioTheme}
                        ioMode={props.ioMode}
                        borderEnable={props.borderEnable}
                        strippedEnable={props.strippedEnable}
                        hoverEnable={props.hoverEnable}
                        updateMyData={updateMyData}
                        subComponentEnable={props.subComponentEnable}
                        subComponentType={props.subComponentType}
                        selectedPath={selectedPath}
                        errorDataExtractor={props.errorDataExtractor}
                        cardWidth={props.cardWidth}
                        cardHeight={props.cardHeight}
                        referralId={props.referralId} 
                        cardsData={props.cardsData}/> :
                    <InfoTable ceId={props.ceId} nodeId={props.nodeId}
                        pkDBCode={props.primaryDBCode}
                        insertFormId={props.insertFormId}
                        editFormId={props.editFormId} columns={props.columns}
                        data={data}
                        styleName={props.styleName}
                        ioTheme={props.ioTheme}
                        ioMode={props.ioMode}
                        borderEnable={props.borderEnable}
                        strippedEnable={props.strippedEnable}
                        hoverEnable={props.hoverEnable}
                        updateMyData={updateMyData}
                        fetchData={fetchData}
                        loading={loading}
                        pageCount={pageCount}
                        serverPaginationEnable={props.serverPaginationEnable}
                        subComponentEnable={props.subComponentEnable}
                        subComponentType={props.subComponentType}
                        selectedPath={selectedPath}
                        errorDataExtractor={props.errorDataExtractor}
                        cardWidth={props.cardWidth}
                        cardHeight={props.cardHeight}
                        referralId={props.referralId}
                        pageCalculatedIndex={props.pageIndex} 
                        setGridPageIndex={props.setGridPageIndex}
                        removeCardDataByReferralId={props.removeCardDataByReferralId}
                        dispatch={props.dispatch}
                        cardsData={props.cardsData}
                        handleRowClick={props.handleRowClick}
                        />
            }
        </Suspense>
    )
}

export default InfoDataGrid;