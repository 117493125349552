import gql from 'graphql-tag';

export const PortalFormQuery = gql`
query PortalForm($id:ID!)
{
    PortalForm(id:$id){
        configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    nodeId
    ceId
    logicalEntityId
    formId
    mode
      
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
      
    }
}`;