import React from 'react';
import { DateTimePicker, Button } from 'info-ui-library';
import moment from 'moment';

function DatePickerFilter({
    column: { filterValue, preFilteredRows, setFilter, styleName, ioMode, ioTheme, dateFormat,width },
}) {
    const count = preFilteredRows ? preFilteredRows.length : 0;
    const [value, setValue] = React.useState(undefined);

    const onChange = e => {
        if (e) {
            setFilter(moment.utc(e).local().format("YYYY-MM-DD"));
            setValue(e);
        } else {
            setFilter(undefined);
        }

        setValue(e);
    }

    let utcToLocalDate = value ? moment.utc(value).local().format("YYYY-MM-DD") : null;

    return (
        <div style={{ "display": "flex" }}>
            <DateTimePicker
                styleName={styleName}
                ioTheme={ioTheme}
                ioMode={ioMode}
                label={""}
                displayType={'SingleLine'}
                showTimeSelect={false}
                showTimeSelectOnly={false}
                dateFormat={"dd-MMM-yyyy"}
                onChange={(e) => onChange(e)}
                withPortal
                widthCell={width}
                selected={utcToLocalDate ? new Date(utcToLocalDate) : null}
                isInlineGridComponent={true}
                className={"inlineGrid"} />
            {value ? <Button onClick={() => onChange(undefined)} disabled={false} styleName={styleName} ioTheme={ioTheme} ioMode={ioMode} size="sm" className="fa fa-times-circle" name={""} title={"Clear Filter"} placeHolder={`Search records...`} /> : ""}
        </div>
    )
}

export default DatePickerFilter;