import React, { useContext, useEffect } from 'react';
import { TextBox } from 'info-ui-library';
import { themes } from '../theme/ThemeOptions';
import { ThemeContext } from '../theme/ThemeContext';

const DataSharingTextBox: React.FC<any> = (props: any) => {
    const themeContext = useContext(ThemeContext);
    const handleChange = (value, index, dbCode) => {
        props.handleChange(value,
            dbCode, index);
    }
   

    const onBlur = (value, index, dbCode) => {
        props.handleChange(value,
            dbCode, index);
    }

    return (
        <TextBox
            label={props.label}
            configId={props.label}
            handleChange={(value) => handleChange(value, props.index, props.dbCode)}
            onBlur={(value) => onBlur(value, props.index, props.dbCode)}
            styleName={themeContext.styleName}
            ioTheme={themes[0]}
            ioMode={themeContext.ioMode}
            type=""
            mandatory={false}
            hasError={false}
            id='jdfburg-r578g-59462'
            errorMessage=''
            value={props.value}
        />
    )
}

export default (DataSharingTextBox);
