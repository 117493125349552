import gql from 'graphql-tag';

export const FormDbValidation = gql`
mutation createFormDbValidation($input:FormDbValidationInput)
 {
    createFormDbValidation(input:$input) {
        name
    configObjectId
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    mode
    datasourceName
    datasourceId
    validationType
    validationExpression
    validationQid
    validationMessage
    validationExpressionKeys
    fieldIds
    isConditionAvailable
    conditionExpression
    conditionFields
    description
       childRelations {
            relationType
            childItemId
         }
          parentRelations {
           relationType
           parentItemId
         }
          privileges {
           privilegeType
           roleId
         }
    }
}
`;
