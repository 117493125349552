import gql from 'graphql-tag';

export const DataGridColumn = gql`
mutation createDataGridColumn($input:DataGridColumnInput)
 {
    createDataGridColumn(input:$input) {
    headerName
    order
    isDisplayDetail
    visible
    toolTip
    actionColumn
    actionColumnType
    group
    icon
    hrefValue
    dateFormat
    hyperLink
    key
    expressionAvailable
    editabilityRegex
    width
    fixColumn
    goToLink
    accessibilityRegex
    dbCode
    filterEnable
    filterType
    isPrimaryKey
    isEllipsesEnable
    isDefaultEditable
    isShowOnlyOnEdit
    collapseIcon
    isAuditColumnJson
    auditColumnName
    columnDataAlignment
    columnHeaderAlignment
    isTimeStamp
    datasourceId
    multivalueList
    selectItemsReferenceID
    modalCss
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    columnType
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;