import React, { useState } from 'react';
import TopMenu from '../components/TopMenu';
import "../grid-layout.css";
import ReactToastComponent from '../components/ReactToastComponent'
import ConfigPortalWrapper from './ConfigPortalWrapper';
import FooterComponent from '../components/FooterComponent';

interface IConfigPortal {
  className?: string,
  rowHeight?: number,
  cols?: any,
  breakpoints?: any,
  history: any

}

const ConfigPortal: React.FC<IConfigPortal> = (props: IConfigPortal) => {
  return (
    <React.Fragment>
        <ReactToastComponent/>
        <TopMenu history={props.history} />
        <ConfigPortalWrapper />
        <FooterComponent/>
    </React.Fragment>
  );
}

ConfigPortal.defaultProps = {
  className: "layout",
  rowHeight: 25,
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },

};
export default (ConfigPortal);
