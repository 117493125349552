import gql from 'graphql-tag';

export const DataSource = gql`
mutation createDataSource($input:DataSourceInput)
 {
    createDataSource(input:$input) {
	  displayName
    datasourceType
    lookupKey
    isTransactionEntriesEnable
    applicationId
    configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;