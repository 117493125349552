export default {
    "Button": {
        "child": [
            {
                "ActionFlow":{
                    "child": [
                        {
                            "MicroFlow":null
                        }
                    ]
                }
            }
        ]
    }
}