import gql from 'graphql-tag';

export const CompositeEntityNodeQuery = gql`
  query CompositeEntityNode($id: ID!)
{
  CompositeEntityNode(id:$id) {
    configObjectId
    addToParentDisplay
    addToParentGrid
    addToParentEditForm
    addToParentInsertForm
    displayNodeName
    graphTemplate
    accessibilityRegex
    order
    expressionAvailable
    editabilityRegex
    expressionFieldString
    showGrid
    addedHidden
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
  
    childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
 