import gql from 'graphql-tag';

export const PhysicalEntity = gql`
mutation createPhysicalEntity($input:PhysicalEntityInput)
 {
    createPhysicalEntity(input:$input) {
        configObjectId
    compositeEntityId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    expressionAvailable
    accessibilityRegex
    isMultivalueMapping
    isPrimaryEntity
    order
    dbType
    dbTypeName
    schemaName
    releaseAreaSessName
    workAreaSessName
    auditDatasourceId
    datasourceId
    insertQID
    updateQID
    sequenceQID
    singleSelectQID
    multiSelectQID
    deleteQID
    isAuditRequired
        childRelations {
            relationType
            childItemId
         }
          parentRelations {
           relationType
           parentItemId
         }
          privileges {
           privilegeType
           roleId
         }
    }
}
`;
