import relations from '../components/mockData/relations.json';


export const getChildRelationsOptionByConfigType=(configType)=>{
    let options:any=[]
    let childConfigTypes = relations[configType].childConfigType.split(","); 
    options = childConfigTypes.map(childConfigType=>{
        return {"value":childConfigType,"label":childConfigType}
    })
    return options;
}


export const getParentRelationsOptionByConfigType=(configType)=>{
    let options:any=[]
    let parentConfigTypes = relations[configType].parentConfigType.split(","); 
    options = parentConfigTypes.map(parentConfigType=>{
        return {"value":parentConfigType,"label":parentConfigType}
    })
    return options;
}