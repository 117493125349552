import gql from 'graphql-tag';

export const FormFieldQuery = gql`
  query FormField($id: ID!)
  {
    FormField(id:$id) {
    label
  order
  height
  hasSupportingLabel
  hasValueInSupportingLabel
  supportingLabel
  supportingLabelFontSize
  fontSize
  type
  displayType
  editorType
  isMandatory
  isButtonTextBox
  expressionAvailable
  refreshFormOnChange
  accessibilityRegex
  editabilityRegex
  previewAccessibilityRegex
  previewEditabilityRegex
  deleteAccessibilityRegex
  deleteEditabilityRegex
  expressionFieldString
  configObjectId
  name
  configObjectType
  createdBy
  isDeleted
  itemDescription
  insert_ts
  projectId
  updatedBy
  update_ts
  deletionDate
  multivalueList
  selectItemsReferenceID
  placeHolder
  datasourceId
  defaultValue
  isMultiLingual
  formatDate
  isApplyDateFormat
  resetOnRefresh
  rowspan
  colspan
  hasToolTipIcon
  toolTipText
  isSearchBoxRequired
  isHyperlink
  gotoLink
  uploadLabel
  outDbcodeUploadFileTempId
  outDbcodeUploadFileName
  outDbcodeUploadFileType
  isRefreshFormWithUploadData
  valueType
  isReadOnly
  starCount
  starColor
  mask
  maskChar
  isSearchable
  isDisabled
  isMulti
  showYearDropdown
  showMonthDropdown
  dateFormat
  dropdownMode
  timeFormat
  showTimeSelect
  showTimeSelectOnly
  timeIntervals
  timeCaption
  entityName
  parentDBCode
  dbCode
  layout
  isAsyncOption
  isClearable
  labelColor
  mandatoryIconAccessibilityRegex
        childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
  