import gql from 'graphql-tag';

export const LogicalEntityOperation = gql`
mutation createLogicalEntityOperation($input:LogicalEntityOperationInput)
 {
    createLogicalEntityOperation(input:$input) {
     configObjectId
    name
    configObjectType
    selectId
    gridSelectId
    filteredQuery
    workAreaSessName
    datasourceId
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;