
export const restHttp = async (url: string, config: any) => {
  return await fetch(url, config)
    .then(response => response.json())
    .then(body => {
      return body;
    });
};

export const restHttpBlob = async (url: string, config: any) => {
  return await fetch(url, config)
    .then(response => response)
    .then(body => {
      return body;
    });
};
