import React, { useContext } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { SettingMenu, FormErrorContainers } from 'info-ui-library';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
} from 'reactstrap';
import { useWhyDidYouUpdate } from '../custom-hooks/useWhyDidYouUpdate';
import { useDispatch,useSelector } from 'react-redux';
import { useState } from 'react';
import styled from 'styled-components';
import { resolveColor } from '../theme/ThemeOptions';
import { createStorage } from '../storage';
import config from '../config/index';
import { SELECTED_THEME_LOCAL_STORAGE_KEY } from '../constants/appeng.enum';
import { UserContext } from '../user-context/UserContext';
import { getPrivilege } from '../utils/ClientUtils';
import { NOPRIVILEGE } from '../constants/appeng.enum';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { extractLabel } from '../utils/intlutils';

const ColorBox = styled.span`
    background: ${props => resolveColor(props.ioTheme, props.ioMode, props.styleName, 'background')};
    float: right;
    width: 15px;
    height: 15px;
    margin-top: 5px;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
transform: translate3d(-10px, 51px, 0px) !important;
width:100px !important;
`;

const UserSettingMenu = props => {
    const themeContext = useContext(ThemeContext);
    const userContext = useContext(UserContext);
    const dispatch = useDispatch();
    useWhyDidYouUpdate("SettingMenuGenerator", props);
    const switchTheme = (themeName) => {
        const storage = createStorage(config);
        storage.setItem(SELECTED_THEME_LOCAL_STORAGE_KEY, themeName);
        themeContext.toggleStyleName(themeName)
    }
    const [dropDownState, setDropState] = useState(false);
    try {
        return (
            <SettingMenu
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                styleName={themeContext.styleName}
            >
                <DropdownToggle nav>
                    <img
                        src={props.profileImg}
                        className="img-avatar"
                        alt="Setting"
                    />
                </DropdownToggle>
                <StyledDropdownMenu right style={{ right: 0 }} >
                <div style={{ paddingLeft: '10px' }}>
                        <EllipsisWithTooltip placement="bottom" className="react-table-column-flex-grow">
                            <strong>User: </strong>
                            {userContext.getUserDetail().APP_LOGGED_IN_USER_NAME}
                        </EllipsisWithTooltip>
                        <EllipsisWithTooltip placement="bottom" className="react-table-column-flex-grow">
                            <strong>Role: </strong>
                            {userContext.getUserDetail().APP_LOGGED_IN_ROLENAME}
                        </EllipsisWithTooltip>
                    </div>
                  
                    <DropdownItem header tag="div" className="text-center">
                        <strong>{"SETTINGS"}</strong>
                    </DropdownItem>
                    {/* {settingMenuItems} */}
                    {/* <DropdownItem divider /> */}
                    <Dropdown style={{ padding: '4px 20px' }} isOpen={dropDownState} toggle={() => setDropState(!dropDownState)}>
                        <DropdownToggle nav>
                            <i className="fa fa-paint-brush"></i>
                            <span style={{ paddingLeft: '11px', color: 'black' }}>{"THEMES"}</span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => switchTheme('primary')}>Default
                                <ColorBox
                                    ioTheme={themeContext.ioTheme}
                                    ioMode={themeContext.ioMode}
                                    styleName={'primary'}
                                />
                            </DropdownItem>
                            <DropdownItem onClick={() => switchTheme('primary1')}>Primary
                                <ColorBox
                                    ioTheme={themeContext.ioTheme}
                                    ioMode={themeContext.ioMode}
                                    styleName={'primary1'}
                                />
                            </DropdownItem>
                            {/* <DropdownItem onClick={() => switchTheme('success')}>Success
                        <ColorBox
                                    ioTheme={themeContext.ioTheme}
                                    ioMode={themeContext.ioMode}
                                    styleName={'success'}
                                />
                            </DropdownItem>
                            <DropdownItem onClick={() => switchTheme('info')}>Info
                        <ColorBox
                                    ioTheme={themeContext.ioTheme}
                                    ioMode={themeContext.ioMode}
                                    styleName={'info'}
                                />
                            </DropdownItem>
                            <DropdownItem onClick={() => switchTheme('warning')}>Warning
                        <ColorBox
                                    ioTheme={themeContext.ioTheme}
                                    ioMode={themeContext.ioMode}
                                    styleName={'warning'}
                                />
                            </DropdownItem> */}
                            <DropdownItem onClick={() => switchTheme('danger')}>Danger
                                <ColorBox
                                    ioTheme={themeContext.ioTheme}
                                    ioMode={themeContext.ioMode}
                                    styleName={'danger'}
                                />
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    {/* <DropdownItem divider /> */}
                    <DropdownItem
                        onClick={e => props.handleClick('/index.html')}
                    >
                        <i className="fa fa-film"></i> {"Application"}
                    </DropdownItem>
                    {/* <DropdownItem divider /> */}
                    <DropdownItem
                        onClick={e => props.handleClick('/logout')}
                    >
                        <i className="fa fa-lock"></i> {"LOGOUT"}
                    </DropdownItem>
                </StyledDropdownMenu>
            </SettingMenu>
        )
    } catch {
        const error = ['Error occurred in Setting Menu Generator']
        return <FormErrorContainers ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }
}

function isPropsAreEqual(prevProps, nextProps) {
    return prevProps.menuGroup === nextProps.menuGroup;
}

export default React.memo((UserSettingMenu), isPropsAreEqual);