import React from 'react';
import { TextBox } from 'info-ui-library';

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, styleName, ioMode, ioTheme },
}) {
    const count = preFilteredRows ? preFilteredRows.length : 0
    const onChange = e => {
        setFilter(e || undefined);
    }
    const onBlur = e => {
        setFilter(e || undefined);
    }

    
    return (
        <TextBox
         value={filterValue || ''} 
         handleChange={(e) => onChange(e)} 
         onBlur={(e) => onBlur(e)} 
         styleName={styleName} 
         ioTheme={ioTheme} ioMode={ioMode} 
         label={""} displayType={'SingleLine'} 
         placeHolder={`Search records...`} isInlineGridComponent={true}  isFilterComponent={true} />
    )
}

export default DefaultColumnFilter;