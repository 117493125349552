import React from 'react';
import { Route, Switch, HashRouter as Router, Redirect } from 'react-router-dom';
import ConfigPortal from '../container/ConfigPortal';
import { App as Auth, InfoAuthConfig } from 'info-auth-client';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../actions';
import { useFetchInfoAuth } from '../custom-hooks/useFetchInfoAuth';
// import config from '../config';
import LogOut from '../components/LogOut';

InfoAuthConfig.configure(JSON.parse(process.env.AUTH_DETAILS!));
const Routes: React.FC = () => {
    const dispatch = useDispatch();
    const userDetails = useSelector((state: any) => state.userDetails);
    const { isAuthenticated, loading, user } = useFetchInfoAuth();
    if (isAuthenticated && !userDetails) {
        dispatch(getUserProfile("", "", user));
    }
    if (loading || (isAuthenticated && !userDetails)) {
        return (<div>loading</div>);
    } else {
        if (isAuthenticated && userDetails) {
            return (
                <Router>
                    <Switch>
                        <Route
                            path="/"
                            render={(props) => <ConfigPortal history={props.history} />} />
                        <Route
                            path="/logout"
                            render={() => <LogOut />} />

                    </Switch>
                </Router>
            );
        } else {
            return (
                <Router>
                    <Switch>
                        <Route path="/" render={() => <Auth />} />
                        
                    </Switch>
                </Router>
            );
        }
    }
};

export default Routes;
