import IStorage from './storage.interface';
// eslint-disable-next-line 
let memoryCache = new Map<string, string>();

export class LocalStorage implements IStorage {
    private config: any;

    constructor(config: any) {
        this.config = config;
    }

    public setItem(key: string, value: any | null): any | null {
        if (!(value == null || value.length === 0)) {
            window.localStorage.setItem(key, value);
            return window.localStorage.getItem(key);
        }
        return null;
    }

    public getItem(key: string): any | null {
        return window.localStorage.getItem(key);
    }

    public removeItem(key: string): boolean {
        if (this.getItem(key)) {
            window.localStorage.removeItem(key);
            return true;
        } else {
            return false;
        }
    }

    public clear(): {} {
        memoryCache = new Map<string, string>();
        return {};
    }
}
