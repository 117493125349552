import gql from 'graphql-tag';

export const LogicalEntityOperationInputQuery = gql`
 query LogicalEntityOperation($id: ID!)
    {
    LogicalEntityOperation(id:$id) {
      configObjectId
      name
      configObjectType
      selectId
      gridSelectId
      filteredQuery
      workAreaSessName
      datasourceId
      createdBy
      isDeleted
      itemDescription
      insert_ts
      projectId
      updatedBy
      update_ts
      deletionDate    
          childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
          }
          parentRelations {
            relationType
            parentItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
          }
          privileges {
            privilegeType
            roleId
            insert_ts
            update_ts
            createdBy
            updatedBy
          }
          
  }
}`;
