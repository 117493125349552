import gql from 'graphql-tag';

export const DataParser = gql`
mutation createDataParser($input:DataParserInput)
 {
    createDataParser(input:$input) {
        configObjectId   
        requestParser
        responseParser
        type
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        privileges {
            privilegeType
            roleId
            isDeleted
        }
        parentRelations {
            parentItemId
            relationType
            isDeleted
          }
        childRelations {
            childItemId
            relationType
            isDeleted
          } 
    }
}
`;