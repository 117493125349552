import gql from 'graphql-tag';

export const FormSectionQuery = gql`
  query FormSection($id: ID!)
  {
    FormSection(id:$id) {
        configObjectId
    headerLabel
    order
    displayName
    accessibilityRegex
    editabilityRegex
    tabGroup
    isRenderOnRepeat
    requiredFormfields
    componentsPerRow
    expressionAvailable
    name
    configObjectType
    insert_ts
    update_ts
    createdBy
    updatedBy
    isDeleted
        childRelations {
          relationType
          childItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        parentRelations {
          relationType
          parentItemId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
        privileges {
          privilegeType
          roleId
          insert_ts
          update_ts
          createdBy
          updatedBy
        }
  }
  }`;
