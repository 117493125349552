import gql from 'graphql-tag';

export const PortalQuery = gql`
query Portal($id:ID!){
    Portal(id:$id){
        configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    portalType
    archived
    favorite
    portalDataSharing
    layoutType
    accessibilityRegex
       childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    }
    
}`;