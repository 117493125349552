import gql from 'graphql-tag';

export const CustomFormValidationObject = gql`
mutation createCustomFormValidation($input:CustomFormValidationInput)
 {
    createCustomFormValidation(input:$input) {
        configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    mode
    validationType
    validationMsg
    validatorBeanId
    isConditionAvailable
    conditionExpression
    conditionFields
    description
       childRelations {
            relationType
            childItemId
         }
          parentRelations {
           relationType
           parentItemId
         }
          privileges {
           privilegeType
           roleId
         }
    }
}
`;
