import gql from 'graphql-tag';

export const SubPortal = gql`
mutation createSubPortal($input:SubPortalInput)
 {
    createSubPortal(input:$input) {
        configObjectId
        name
        configObjectType
        isDeleted
        projectId
        portalDataSharing
        portalType
        archived
        favorite
        modalRequired
        tabGroup
        subPortalType
        order
        layoutType
        accessibilityRegex
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;