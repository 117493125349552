
export default {
    "SubPortal": {
        "child": [
            {
                "PortalCard":{
                    "child": [
                        {
                            "ButtonPanel":{
                                "child": [
                                    {
                                        "Button": {
                                            "child": [
                                                {
                                                    "ActionFlow":{
                                                        "child": [
                                                            {
                                                                "MicroFlow":null
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    }
}