export default {
    "message": null,
    "status": true,
    "successRedirect": null,
    "errorRedirect": null,
    "returnData": {
        "enableExpandButton": true,
        "enableCollapseAllButton": true,
        "enableExpandLevelButton": true,
        "sortableTreeData": [],
        "LevelCount": 4,
        "enableExpandALLButton": true,
        "enableCollapseButton": true,
        "enableCollapseLevelButton": true,
        "theme": "fileTheme",
        "borderStatus": true
    },
    "userValid": false
}