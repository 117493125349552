import gql from 'graphql-tag';

export const StandardValidationQuery = gql`
query StandardValidation($id:ID!){
    StandardValidation(id:$id){
        name
    configObjectId
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
    mode
    defaultErrorMessage
    validationType
    regex
    isConditionAvailable
    conditionExpression
    conditionFields
    description
       childRelations {
            relationType
            childItemId
            insert_ts
            update_ts
            createdBy
            updatedBy
         }
          parentRelations {
           relationType
           parentItemId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
          privileges {
           privilegeType
           roleId
           insert_ts
           update_ts
           createdBy
           updatedBy
         }
    }
}`;
