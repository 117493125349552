
import React from 'react';
import { Col } from '@bootstrap-styled/v4';
import PortalCardSelectOption from './PortalCardSelectOption';
import DataSharingTextBox from './DataSharingTextBox';

const DataSharingColumnComponent: React.FC<any> = (props: any) => {
    let componentToDisplay;
    switch (props.dbCode) {
        case 'SOURCE_PORTAL_CARD_ID': {
            componentToDisplay = (
                <Col lg={3} md={3} sm={12} xs={12}>
                    <PortalCardSelectOption
                        dbCode={'SOURCE_PORTAL_CARD_ID'}
                        onChange={props.handleChange}
                        label='Soucre Card'
                        index={props.rowIndex}
                        id='source-card'
                        value={props.rowData[props.dbCode]}
                    />
                </Col>
            )
            break;
        }
        case 'SOURCE_PORTAL_CARD_DBCODE': {
            componentToDisplay = (
                <Col lg={3} md={3} sm={12} xs={12}>
                    <DataSharingTextBox
                        dbCode={'SOURCE_PORTAL_CARD_DBCODE'}
                        label="Source DBCode"
                        handleChange={props.handleChange}
                        index={props.rowIndex}
                        id='jdfburg-r578g-59462'
                        value={props.rowData[props.dbCode]}
                    />
                </Col>
            )
            break;
        }
        case 'TARGET_PORTAL_CARD_ID': {
            componentToDisplay = (
                <Col lg={3} md={3} sm={12} xs={12}>
                    <PortalCardSelectOption
                        dbCode={'TARGET_PORTAL_CARD_ID'}
                        onChange={props.handleChange}
                        label='Target Card'
                        id='target-card'
                        index={props.rowIndex}
                        value={props.rowData[props.dbCode] ?
                            props.rowData[props.dbCode] : props.cardId}
                    />
                </Col>
            )
            break;
        }
        case 'TARGET_PORTAL_CARD_DBCODE': {
            componentToDisplay = (
                <Col lg={3} md={3} sm={12} xs={12}>
                    <DataSharingTextBox
                        dbCode={'TARGET_PORTAL_CARD_DBCODE'}
                        label="Target DBCode"
                        handleChange={props.handleChange}
                        index={props.rowIndex}
                        id='jdfburg-r578g-59462'
                        value={props.rowData[props.dbCode]}
                    />
                </Col>
            )
            break;
        }
    }
    return (
        <React.Fragment>
            {
                componentToDisplay
            }
        </React.Fragment>
    )

}

export default (DataSharingColumnComponent);