
export const getSelectOptionDataFormat = (formData) => {
    var optionList:any=[];
    optionList.push({label:"-select-",value:"-select-"})
    if(formData){ 
    formData.map((item)=>{
        var data:any={};
        data['label']=item.name;
        data['id']=item.configObjectId;
        data['value']=item.configObjectId;
        optionList.push(data);
    })
}
    return optionList;
}