import gql from 'graphql-tag';

export const EmailTemplate = gql`
mutation createEmailTemplate($input:EmailTemplateInput)
 {
    createEmailTemplate(input:$input) {
        to
        cc
        bcc
        subject
        content
        attachmentKey
	configObjectId
    name
    configObjectType
    createdBy
    isDeleted
    itemDescription
    insert_ts
    projectId
    updatedBy
    update_ts
    deletionDate
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations {
            parentItemId
            relationType
            isDeleted
          }
        childRelations {
            childItemId
            relationType
            isDeleted
          } 
    }
}
`;

