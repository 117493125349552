import gql from 'graphql-tag';

export const Portal = gql`
mutation createPortal($input:PortalInput)
 {
    createPortal(input:$input) {
        configObjectId
        name
        configObjectType
        isDeleted
        projectId
        portalDataSharing
        portalType
        archived
        favorite
        layoutType
        accessibilityRegex
        privileges{
            privilegeType
            roleId
            isDeleted
        }
        parentRelations
            {
              parentItemId
              relationType
              isDeleted
            }
          
        childRelations
            {
              childItemId
              relationType
              isDeleted
            }
          
    }
}
`;