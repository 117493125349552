import React, { useState, useEffect, useContext } from 'react';
import { SelectOption, Form } from 'info-ui-library';
import { themes } from '../theme/ThemeOptions';
import { Row, Col } from '@bootstrap-styled/v4';
import { getSelectOptionDataFormat } from '../utils/SelectOptionUtils';
import { getConfigData } from '../utils/ConfigUtils';
import { ThemeContext } from '../theme/ThemeContext';
import LogicalColumnRelationForm from "./LogicalColumnRelationForm";
import PhysicalEntityRelationForm from "./PhysicalEntityRelationForm";
import FormFieldRelationForm from './FormFieldRelationForm';
import { getChildRelationsOptionByConfigType } from '../utils/Relation.utils';
import { useDispatch, useSelector } from 'react-redux';
const handleSelectOptionType = (value, setType, setCompData,
    setSelectedCompData, setFormData, formData, configType) => {
    setSelectedCompData("-select-")
    setType(value);
    let childRelations: any = formData;
    childRelations.childRelation = { ...formData.childRelation, ...{ relationType: configType + "_" + value } };
    setFormData({ ...childRelations });
}

const handleSelectOptionComp = (data, setSelectedCompData, setFormData, formData) => {
    setSelectedCompData(data);
    let childRelations: any = formData;
    childRelations.childRelation = { ...formData.childRelation, ...{ childItemId: data.value } };
    setFormData({ ...childRelations });
}

const ChildRelationForm = (props: any) => {
    const [type,setType] = useState('');
    const [compData,setCompData] = useState([
            {
                label: "-select-",
                value: "-select-"
            }
        ]);
    const [selectedCompData,setSelectedCompData] = useState({
            label: "-select-",
            value: "-select-"
        });
    const projectId = useSelector((state: any) => state.projectId);
    useEffect(() => {
        const getGridData = async (configType) => {
            let currentConfigType = configType;
            if (['InsertForm', 'EditForm'].includes(configType)) {
                currentConfigType = 'Form';
            }
            let gridData = await getConfigData('ConfigItemQuery', currentConfigType,projectId);
            if (type) {
                setCompData(getSelectOptionDataFormat(gridData.data.ConfigItemByType))
            }
        }
        getGridData(type);
    }, [type])

    const FormComponent = <CommonComponent
        type={type}
        setType={setType}
        setCompData={setCompData}
        compData={compData}
        setFormData={props.setFormData}
        formData={props.formData}
        setSelectedCompData={setSelectedCompData}
        selectedCompData={selectedCompData}
        errorData={props.errorData}
        setErrorData={props.setErrorData} />
    try {
        return (
            <Form className={props.className}>
                {FormComponent}
            </Form>
        )
    } catch (e) {
        return <div></div>
    }
}
const getDropDownsAccordingToConfigType = (props) => {
    switch (props.type) {
        case "LogicalColumn":
        case "LogicalColumn_Child":
            return <LogicalColumnRelationForm type={props.type}
                compData={props.compData} setCompData={props.setCompData}
                setSelectedCompData={props.setSelectedCompData}
                selectedCompData={props.selectedCompData} setFormData={props.setFormData}
                formData={props.formData} errorData={props.errorData} setErrorData={props.setErrorData}/>
        case "FormField":
                return <FormFieldRelationForm type={props.type}
                compData={props.compData} setCompData={props.setCompData}
                setSelectedCompData={props.setSelectedCompData}
                selectedCompData={props.selectedCompData} setFormData={props.setFormData}
                formData={props.formData} errorData={props.errorData} setErrorData={props.setErrorData}/> 
        case "PhysicalEntity":
            return <PhysicalEntityRelationForm type={props.type}
                compData={props.compData} setCompData={props.setCompData}
                setSelectedCompData={props.setSelectedCompData}
                selectedCompData={props.selectedCompData} setFormData={props.setFormData}
                formData={props.formData} errorData={props.errorData} setErrorData={props.setErrorData}/>
        default:
            return <SelectOption
                styleName='primary'
                ioTheme={themes[0]}
                value={props.selectedCompData}
                ioMode='light'
                options={props.compData}
                label='Component'
                configId={"component"}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectOptionComp(value,
                    props.setSelectedCompData, props.setFormData
                    , props.formData)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError={props.errorData ?true:false}
                errorMessage={props.errorData?"Is Required":null} />
    }
}
const CommonComponent = (props) => {
    const screenDetails = useSelector((state: any) => state.screenDetails);
    const options: any = getChildRelationsOptionByConfigType(screenDetails.configType);
    let dropDownsAccordingToConfigType = getDropDownsAccordingToConfigType(props);
    const themeContext = useContext(ThemeContext);
    return <Row>
        <Col sm='6'>
            <SelectOption
                styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                options={options}
                label='Type'
                configId={"Type"}
                key='1'
                displayType="FloatingLabel"
                onChange={(value) => handleSelectOptionType(value.value,
                    props.setType, props.setCompData,
                    props.setSelectedCompData, props.setFormData
                    , props.formData, screenDetails.configType)}
                isSearchable={true}
                isDisabled={false}
                isMulti={false}
                mandatory={true}
                hasError={props.errorData && props.type ==="" ?true:false}
                errorMessage={props.errorData?"Is Required":null} />

        </Col>
        <Col sm="6">
            {dropDownsAccordingToConfigType}
        </Col>
    </Row>
}

export default (ChildRelationForm);
