import React, { useState, useContext,useEffect } from 'react';
import {
    TextBox, RadioButton, SelectOption
} from 'info-ui-library';
import { getSelectOptionDataFormat } from '../utils/SelectOptionUtils';
import { getConfigData } from '../utils/ConfigUtils';
import styled from 'styled-components';
import { themes } from '../theme/ThemeOptions';
import { useSelector, useDispatch } from 'react-redux';
import { getPropertyByPortalCardType } from "../utils/PropertyUtils";
import RootCompositeEntityNodeSelectOption from './RootCompositeEntityNodeSelectOption';
import { ThemeContext } from '../theme/ThemeContext';
import { setChangeFormData } from '../actions/index';
const InnerContainer = styled.div`{
    flex: 1 0 21%;
    padding-right:5px;
}`;
const OuterConatiner = styled.div`
display: flex;
flex-wrap: wrap;
`;

const handlePropertyChange = (updatedValue, dbCode, type, dispatch) => {
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, type));
}


const onBlur = (updatedValue, dbCode, type, dispatch) => {
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, type));
}

const getComponents = (properties, formData, setFormData, errorData, dispatch, 
    themeContext,compData) => {
    try {
        return properties.map(property => {
            switch (property.type) {
                case "TextBox":
                    return <InnerContainer><TextBox
                    ioTheme={themes[0]}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    label={property.label}
                    configId={property.label}
                    key={property.label}
                    value={Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ? formData.property[property.dbCode] : property.defaultValue}
                    displayType="FloatingLabel"
                    handleChange={(updatedValue) => {
                        handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                    }}

                    onBlur={(updatedValue) => {
                        onBlur(updatedValue, property.dbCode, "property", dispatch)
                    }}
                    errorMessage={errorData[property.dbCode]}
                    hasError={errorData[property.dbCode] ? true : false}
                    mandatory={property.isMandatory}
                    isButtonTextBox={false}
                    /></InnerContainer>

                case "SelectOption":
                const options=property.options?property.options:compData;
                 const valueAsAnObject = getValueObject(Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue,
                        options)
                    return <InnerContainer><SelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        value={valueAsAnObject}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        options={options}
                        displayType="FloatingLabel"
                        onChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, 
                                "property", dispatch)
                        }}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                    /></InnerContainer>
                case "RadioButton":
                    return <InnerContainer> <RadioButton
                    ioTheme={themes[0]}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        options={property.options}
                        value={Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ? formData.property[property.dbCode] : property.defaultValue}
                        onChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        displayType="FloatingLabel"
                        mandatory={property.isMandatory}
                        disabled={false}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                    /></InnerContainer>
                    case "RootCompositeEntityNode":
                    const compositeEntityNodevalues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><RootCompositeEntityNodeSelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={compositeEntityNodevalues}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
            }
        })
    } catch (e) {
        return <div>{e}</div>
    }

}

const PortalCardPropertyByCardType = props => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [compData,setCompData]=useState([]);
    let components = "";
    const projectId = useSelector((state: any) => state.projectId);
    useEffect(() => {
        const getComponents = async (configType) => {
            let cType= configType;
            if(cType==="SideNodeNavigation"){
                cType="RootCompositeEntityNode";
            }
            let gridData = await getConfigData('ConfigItemQuery', configType,projectId);
           if ( props.formData.property["type"]) {
                setCompData(getSelectOptionDataFormat(gridData.data.ConfigItemByType))
            }
        }
        if(props.formData.property["initialComponent"]){
            let initialComponent= props.formData.property["initialComponent"]==="Form"?
                                        "PortalForm":"PortalDataGrid";
            getComponents(initialComponent);
        }else{
            getComponents( props.formData.property["type"]);
        }
    }, [ props.formData.property["type"],props.formData.property["initialComponent"]])
    if ( props.formData.property["type"] !== "") {
        const properties = getPropertyByPortalCardType( props.formData.property["type"]);
        components = getComponents(properties, props.formData, props.setFormData,
             props.errorData, dispatch, themeContext,compData);
        }
        return <OuterConatiner className="col-md-12">{components}</OuterConatiner>
}

export const getValueObject = (initialvalue, selectedOptions) => {
    let value = null
    if (selectedOptions) {
        selectedOptions.map(object => {
            if (String(object.id) === String(initialvalue)) {
                value = object;
            }
            return null;
        })
    }
    return value
}
export default PortalCardPropertyByCardType;