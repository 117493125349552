import React, { useState, useContext } from 'react';
import {
    TextBox, RadioButton, SelectOption, TextArea, AceEditor, DraftEditor
} from 'info-ui-library';
import styled from 'styled-components';
import { themes } from '../theme/ThemeOptions';
import { useSelector, useDispatch } from 'react-redux';
import { getPropertyByConfigType } from "../utils/PropertyUtils";
import DataSourceSelectOption from './DataSourceSelectOption';
import PortalCardComponent from './PortalCardComponent';
import FormSelectOption from './FormSelectOption';
import GridSelectOption from './GridSelectOption';
import RootCompositeEntityNodeSelectOption from './RootCompositeEntityNodeSelectOption';
import FormFieldPropertComponent from "./FormFieldPropertyComponent";
import PortalSelectOption from './PortalSelectOption';
import CompositeEntitySelectOption from './CompositeEntitySelectOption';
import DataSharingComponentCore from './DataSharingComponentCore';
import { setChangeFormData } from '../actions/index';
import { ThemeContext } from '../theme/ThemeContext';
import PortalCardPropertyByCardType from "./PortalCardPropertyByCardType";
const InnerContainer = styled.div`{
    flex: 1 0 21%;
    padding-right:5px;
}`;
const OuterConatiner = styled.div`
display: flex;
flex-wrap: wrap;
`;

const handlePropertyChange = (updatedValue, dbCode, type, dispatch) => {
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, type));
}

const onBlur = (updatedValue, dbCode, type, dispatch) => {
    dispatch(setChangeFormData("test555", updatedValue && updatedValue.value ? updatedValue.value : updatedValue, dbCode, type));
}

const getComponents = (properties, formData, setFormData,
    errorData, dispatch, themeContext, screenDetails) => {
    try {
        return properties.map(property => {
            switch (property.type) {
                case "TextBox":
                    return <InnerContainer><TextBox
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        value={Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ? formData.property[property.dbCode] : property.defaultValue}
                        displayType="FloatingLabel"
                        handleChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        onBlur={(updatedValue) => {
                            onBlur(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                        mandatory={property.isMandatory}
                        isButtonTextBox={false}
                    /></InnerContainer>
                case "TextArea":
                    return <InnerContainer><TextArea
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        value={Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ? formData.property[property.dbCode] : property.defaultValue}
                        displayType="FloatingLabel"
                        handleChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        onBlur={(updatedValue) => {
                            onBlur(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                        mandatory={property.isMandatory}
                        isButtonTextBox={false}
                    /></InnerContainer>
                case "SelectOption":
                    const valueAsAnObject = getValueObject(Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue, property.options)
                    return <InnerContainer><SelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        value={valueAsAnObject}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        onChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                    /></InnerContainer>
                case "RadioButton":
                    return <InnerContainer> <RadioButton
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        options={property.options}
                        value={Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ? formData.property[property.dbCode] : property.defaultValue}
                        onChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        displayType="FloatingLabel"
                        mandatory={property.isMandatory}
                        disabled={false}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                    /></InnerContainer>
                case "RootCompositeEntityNode":
                    const compositeEntityNodevalues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><RootCompositeEntityNodeSelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={compositeEntityNodevalues}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
                case "DataSource":
                    const values = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><DataSourceSelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={values}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={handlePropertyChange}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
                case "Portal":
                    const portalvalues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><PortalSelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={portalvalues}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
                case "CompositeEntity":
                    const compositeEntityvalues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><CompositeEntitySelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={compositeEntityvalues}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
                case "DataSharingComponent":
                    const dataSharingValue = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return (
                        <InnerContainer>
                            <DataSharingComponentCore errorData={errorData}
                                configProps={property}
                                cardId={screenDetails.configItemId}
                                dataSharingValue={dataSharingValue}
                                handlePropertyChange={(updatedValue) => {
                                    handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                                }} />
                        </InnerContainer>)
                case "Form":
                    const formvalues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><FormSelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={formvalues}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={handlePropertyChange}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
                case "Grid":
                    const gridValues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer><GridSelectOption
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        selectedValue={gridValues}
                        label={property.label}
                        key={property.label}
                        options={property.options}
                        displayType="FloatingLabel"
                        handleSelectOption={handlePropertyChange}
                        isSearchable={true}
                        isDisabled={false}
                        isMulti={false}
                        property={property}
                        mandatory={property.isMandatory}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false} /></InnerContainer>
                case "CardType":
                    return <InnerContainer><PortalCardComponent
                        formData={formData} setFormData={setFormData} errorData={errorData} /></InnerContainer>
                case "CodeEditor":
                    const editorValues = Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ?
                        formData.property[property.dbCode] : property.defaultValue;
                    return <InnerContainer>
                        <AceEditor
                            mode={"javascript"}
                            collapseEnable={false}
                            id={property.label}
                            label={property.label}
                            configId={property.label}
                            ioTheme={themes[0]}
                            ioMode={themeContext.ioMode}
                            styleName={themeContext.styleName}
                            mandatory={property.isMandatory}
                            editorTheme="solarized_dark"
                            editorMode={property.editorMode}
                            handleChange={(updatedValue) => {
                                handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                            }}
                            value={editorValues}
                            fontSize={14}
                            showGutter={false}
                            enableBasicAutocompletion={true}
                            enableLiveAutocompletion={true}
                            showLineNumbers={true}
                            errorMessage={errorData[property.dbCode]}
                            hasError={errorData[property.dbCode] ? true : false}
                        />
                    </InnerContainer>

                case "CkEditor":
                    return <InnerContainer><DraftEditor
                        ioTheme={themes[0]}
                        ioMode={themeContext.ioMode}
                        styleName={themeContext.styleName}
                        label={property.label}
                        configId={property.label}
                        key={property.label}
                        value={Object.keys(formData.property).length > 0 && formData.property[property.dbCode] ? formData.property[property.dbCode] : property.defaultValue}
                        displayType="FloatingLabel"
                        handleChange={(updatedValue) => {
                            handlePropertyChange(updatedValue, property.dbCode, "property", dispatch)
                        }}
                        errorMessage={errorData[property.dbCode]}
                        hasError={errorData[property.dbCode] ? true : false}
                        mandatory={property.isMandatory}
                        toolbarHidden={false}
                        readOnly={property.readonly}
                    /></InnerContainer>

            }
        })
    } catch (e) {
        return <div>{ }</div>
    }

}
const ConfigItemPropertyComponent = props => {
    const screenDetails = useSelector((state: any) => state.screenDetails);
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    switch (screenDetails.configType) {
        case "FormField":
            return <OuterConatiner className="col-md-12" >
                <FormFieldPropertComponent
                    formData={props.formData} setFormData={props.setFormData} errorData={props.errorData} />
            </OuterConatiner>
        case "Menu":
            const menuProperty = getPropertyByConfigType(screenDetails.configType);
            let menucomponents = getComponents(menuProperty,
                props.formData, props.setFormData, props.errorData,
                dispatch, themeContext, screenDetails);
            let componentsAccordingToLinkType: any = getComponentByLinkType(props, themeContext, dispatch);
            return <OuterConatiner className="col-md-12" >
                {menucomponents}
                {componentsAccordingToLinkType}
            </OuterConatiner>
        case "PortalCard":
            const portalCardProperty = getPropertyByConfigType(screenDetails.configType);
            let portalCardComponents = getComponents(portalCardProperty,
                props.formData,
                props.setFormData, props.errorData, dispatch,
                themeContext, screenDetails);
            let componentsAccordingToCardType: any = "";
            if (props.formData.property.type) {
                componentsAccordingToCardType = <PortalCardPropertyByCardType {...props} />
            }
            return <OuterConatiner className="col-md-12" >
                {portalCardComponents}
                {componentsAccordingToCardType}
            </OuterConatiner>
        default:
            const property = getPropertyByConfigType(screenDetails.configType);
            let components = getComponents(property, props.formData,
                props.setFormData, props.errorData, dispatch, themeContext, screenDetails);
            try {
                return (
                    <OuterConatiner className="col-md-12" >
                        {components}
                    </OuterConatiner>

                )
            } catch (e) {
                return <div></div>
            }
    }
}

export const getValueObject = (initialvalue, selectedOptions) => {
    let value = null
    if (selectedOptions) {
        selectedOptions.map(object => {
            if (String(object.id) === String(initialvalue)) {
                value = object;
            }
            return null;
        })
    }
    return value
}
export default ConfigItemPropertyComponent;

function getComponentByLinkType(props: any, themeContext: any, dispatch: any) {
    if (props.formData.property.linkType) {
        switch (props.formData.property.linkType) {
            case "CUSTOM":
                return <InnerContainer><TextBox ioTheme={themes[0]} ioMode={themeContext.ioMode} styleName={themeContext.styleName} label="Href" key="hrefBaseUrl" value={Object.keys(props.formData.property).length > 0 &&
                    props.formData.property.hrefBaseUrl ?
                    props.formData.property.hrefBaseUrl : ""} displayType="FloatingLabel" handleChange={(updatedValue) => {
                        handlePropertyChange(updatedValue, "hrefBaseUrl", "property", dispatch);
                    }} errorMessage={props.errorData["hrefBaseUrl"]} hasError={props.errorData["hrefBaseUrl"] ? true : false} mandatory={true} isButtonTextBox={false} onBlur={(updatedValue) => {
                        onBlur(updatedValue, "hrefBaseUrl", "property", dispatch)
                    }}/></InnerContainer>;
                return <InnerContainer><TextBox ioTheme={themes[0]} ioMode={themeContext.ioMode} styleName={themeContext.styleName} label="Href" configId={"Href"} key="hrefBaseUrl" value={Object.keys(props.formData.property).length > 0 &&
                    props.formData.property.hrefBaseUrl ?
                    props.formData.property.hrefBaseUrl : ""} displayType="FloatingLabel" handleChange={(updatedValue) => {
                        handlePropertyChange(updatedValue, "hrefBaseUrl", "property", dispatch);
                    }} errorMessage={props.errorData["hrefBaseUrl"]} hasError={props.errorData["hrefBaseUrl"] ? true : false} mandatory={true} isButtonTextBox={false} onBlur={(updatedValue) => {
                        onBlur(updatedValue, "hrefBaseUrl", "property", dispatch)
                    }}/></InnerContainer>;

            case "PORTAL":
                const values = Object.keys(props.formData.property).length > 0 &&
                    props.formData.property["hrefBaseUrl"] ?
                    props.formData.property["hrefBaseUrl"] : "";
                return <InnerContainer><PortalSelectOption
                    ioTheme={themes[0]}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    selectedValue={values}
                    label="Portal"
                    key="hrefBaseUrl"
                    dbCode="hrefBaseUrl"
                    displayType="FloatingLabel"
                    handleSelectOption={handlePropertyChange}
                    isSearchable={true}
                    isDisabled={false}
                    isMulti={false}
                    mandatory={true}
                    errorMessage={props.errorData["hrefBaseUrl"]}
                    hasError={props.errorData["hrefBaseUrl"] ? true : false} />
                </InnerContainer>;

            case "CompositeEntity":
                const objvalues = Object.keys(props.formData.property).length > 0 &&
                    props.formData.property["hrefBaseUrl"] ?
                    props.formData.property["hrefBaseUrl"] : "";
                return <InnerContainer><CompositeEntitySelectOption
                    ioTheme={themes[0]}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    selectedValue={objvalues}
                    label="CompositeEntity"
                    key="hrefBaseUrl"
                    dbCode="hrefBaseUrl"
                    displayType="FloatingLabel"
                    handleSelectOption={handlePropertyChange}
                    isSearchable={true}
                    isDisabled={false}
                    isMulti={false}
                    mandatory={true}
                    errorMessage={props.errorData["hrefBaseUrl"]}
                    hasError={props.errorData["hrefBaseUrl"] ? true : false} />
                </InnerContainer>;

            case "RootCompositeEntityNode":
                const cenvalues = Object.keys(props.formData.property).length > 0 &&
                    props.formData.property["hrefBaseUrl"] ?
                    props.formData.property["hrefBaseUrl"] : "";
                return <InnerContainer><RootCompositeEntityNodeSelectOption
                    ioTheme={themes[0]}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    selectedValue={cenvalues}
                    label="Portal"
                    key="hrefBaseUrl"
                    dbCode="hrefBaseUrl"
                    displayType="FloatingLabel"
                    handleSelectOption={handlePropertyChange}
                    isSearchable={true}
                    isDisabled={false}
                    isMulti={false}
                    mandatory={true}
                    errorMessage={props.errorData["hrefBaseUrl"]}
                    hasError={props.errorData["hrefBaseUrl"] ? true : false} />
                </InnerContainer>;



            default:
                return ""
        }
    }
}
