import gql from 'graphql-tag';

export const Menu = gql`
mutation createMenu($input:MenuInput)
 {
    createMenu(input:$input) {displayLabel
        hrefBaseUrl
        icon
        menuClass
        header
        order
        objectId
        isOpenInNewTab
        submenuContainerClass
        report
        customUrl
        componentsPerRow
        linkType
        accessibilityRegex
        configObjectId
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
           childRelations {
                relationType
                childItemId
                isDeleted
             }
              parentRelations {
               relationType
               parentItemId
               isDeleted
             }
              privileges {
               privilegeType
               roleId
               isDeleted
             }
        
        }
    }`;
